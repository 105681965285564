export function getTableNameFromTemplate(t: any): string | null {
  if (!t || t.type !== 'ref' || t.ref !== 'Table' || !t.layout || t.layout.type !== 'native') {
    return null;
  }

  return t.layout.table || null;
}

export function joinTemplateWithTableName(template: any, newTableName: string | null) {
  const prevTableName = getTableNameFromTemplate(template);

  if (prevTableName) {
    if (!newTableName) {
      return template.layout.template;
    }

    return {
      ...template,
      layout: {
        ...template.layout,
        table: newTableName,
      },
    };
  }

  return {
    type: 'ref',
    ref: 'Table',
    layout: {
      type: 'native',
      table: newTableName,
      template: {
        type: 'object',
        layout: {
          type: 'horizontal',
        },
        properties: {
          stream: template,
        },
      },
    },
  };
}

export const TemplatePositionLens = {
  toEditor: (position?: string[]) => position ? position.join('.') : '',
  toTemplate: (position: string = '') => position?.trim() ? position.split('.') : undefined,
};
