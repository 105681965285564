import React from "react";
import { Image, Space, Typography } from "antd";
import type { NativeReactRenderer } from "../../Base/NativeReactRenderer/types";

const Footer: NativeReactRenderer = () => {
  return (
    <a
      href="https://thorsystems.ru/"
      target="_blank"
      rel="noreferrer"
    >
      <Space size={10} style={{
        width: '80%',
        marginBottom: '10px',
        marginLeft: '20px',
        justifyContent: 'left',
      }}>
        <Image src="/favicon.svg" height={35} preview={false} />
        <Typography.Text>
          Thor&#xA0;Systems<br />
          © 2023
        </Typography.Text>
      </Space>
    </a>
  );
}

export default Footer;
