import { useCallback, useEffect, useMemo, useState } from "react";
import useValueRef from "../Elements/useValueRef";
import type { AsObj, Query } from "./types";

type Event = 'create' | 'update' | 'delete';

type Listener = {
  v: boolean,
  rerender: (event: Event) => void,
};

export function useUpdateEvent<T>(stream: Stream<T>, events: Event[], _t: string) {
  const [state, setState] = useState(false);
  const eventsSet = useMemo(() => new Set(events), [events]);

  const effectFunc = useCallback(() => {
    const rerender = (event: Event) => {
      if (eventsSet.has(event)) {
        setState(listener.v = !listener.v);
      } else {
        console.log('no such events', eventsSet, event);
      }
    }

    const listener = {rerender, v: false};
    stream.listeners.add(listener);

    return () => {
      stream.listeners.delete(listener);
    };
  }, [stream, eventsSet]);

  const effectFuncRef = useValueRef(effectFunc);

  useEffect(
    () => effectFuncRef.current(),
    [effectFuncRef, stream]
  );

  return state;
}

export abstract class Stream<T> {
  /** Номер страницы (с нуля) */
  private frame: number;
  /** Обработчики изменений стрима */
  public listeners: Set<Listener>;
  /** Уникальное число */
  private uniqueNumber: number;

  constructor(
    /** Ключ для дозагрузки других страниц */
    protected key: string,
  ) {
    this.frame = 0;
    this.listeners = new Set();
    this.uniqueNumber = Math.random();
  }

  // TODO: перегрузить сериализацию в JSON (должен быть {} или null)

  toString() {
    return `<Stream: ${this.uniqueNumber}>`;
  }

  /** 
   * Вызов обработчиков поданного события
   */
  public rerender(event: Event) {
    this.listeners.forEach(lst => lst.rerender(event));
  }

  /** 
   * Используется для подгрузки данных с одной страницы
   */
  async getFrame(frame: number = this.frame, query: Query<AsObj<T>> = {}) {
    const result = await this.getData(this.key, frame, query);
    this.frame = frame;
    return result;
  }

  protected abstract getData(key: string, frame: number, query: Query<AsObj<T>>): Promise<T[] | null>;
  abstract update(id: number, element?: T): Promise<T[] | null>;
  abstract delete(id: number, element?: T): Promise<T[] | null>;
  abstract create(element?: T): Promise<T[] | null>;
  abstract find(query: Query<AsObj<T>>): Promise<T[] | null>;
}

export class LensStream<T, U> extends Stream<T> {
  constructor(
    // Стрим, который оборачиваем линзой
    private base: Stream<U>,
    // Направвление в обернутый стрим
    private inp: (v: T) => U | null,
    // Направление из обернутого стрима
    private outp: (v: U) => T,
    // Накладывание фильтров (необязательно)
    // NOTE: По умолчанию фильтры не меняются
    private query: (q: Query<AsObj<T>>) => Query<AsObj<U>> = v => v as Query<AsObj<U>>,
  ) {
    super('');
    this.listeners = this.base.listeners;
  }
  protected async getData(key: string, frame: number, query: Query<AsObj<T>>): Promise<T[] | null> {
    throw new Error('Not implemented');
  }
  async getFrame(frame?: number, query: Query<AsObj<T>> = {}) {
    const result = await this.base.getFrame(frame, this.query(query));
    return result?.map(this.outp) ?? null;
  }
  async update(id: number, element?: T) {
    if (!element) {
      return null;
    }
    const el = this.inp(element);
    if (!el) {
      return null;
    }
    const result = await this.base.update(id, el);
    return result?.map(this.outp) ?? null;
  }
  async delete(id: number, element?: T) {
    if (!element) {
      return null;
    }
    const el = this.inp(element);
    if (!el) {
      return null;
    }
    const result = await this.base.delete(id, el);
    return result?.map(this.outp) ?? null;
  }
  async create(element?: T) {
    if (!element) {
      return null;
    }
    const el = this.inp(element);
    if (!el) {
      return null;
    }
    const result = await this.base.create(el);
    return result?.map(this.outp) ?? null;
  }
  async find(query: Query<AsObj<T>>) {
    const result = await this.base.find(this.query(query));
    return result?.map(this.outp) ?? null;
  }
}

export class ArrayStream<T> extends Stream<T> {
  constructor(private data: T[] = []) {
    super('');
  }
  protected async getData(key: string, frame: number, query: Query<AsObj<T>>) {
    return this.find(query);
  }
  async update(id: number, element: T) {
    const result = [...this.data];
    result[id] = element;
    this.data = result;
    this.rerender('update');
    return result;
  }
  async delete(id: number, element: T) {
    const result = [...this.data];
    result.splice(id, 1);
    this.data = result;
    this.rerender('delete');
    return result;
  }
  async create(element: T) {
    const result = [...this.data, element];
    this.data = result;
    this.rerender('create');
    return result;
  }
  async find(query: Query<AsObj<T>>) {
    const filters = query.filters;
    if (!filters) {
      return this.data;
    }
    let result = this.data as AsObj<T>[];
    Object.keys(filters).forEach(keyRaw => {
      // NOTE: Object.keys не проставляет тип
      const key = keyRaw as keyof typeof filters;
      const queryItem = filters[key]!;
      Object.keys(queryItem).forEach(kind => {
        if (kind === 'eq') {
          result = result.filter(el => el[key] === queryItem[kind]);
        }
        if (kind === 'like') {
          const likeExprRaw = queryItem[kind];
          if (typeof likeExprRaw === 'string') {
            result = result.filter(el => {
              const value = el[key];
              if (typeof value !== 'string') {
                return false;
              }
              return value.indexOf(likeExprRaw) >= 0;
            });
          }
        }
      });
    });
    return result;
  }
}

export type ArrayLike<T> = Stream<T> | T[];
export type AALL<T> = ArrayLike<T>;

export function toStream<T>(arr?: ArrayLike<T>): Stream<T> | ArrayStream<T> {
  if (arr instanceof Stream) {
    return arr;
  }
  if (arr?.length) {
    return new ArrayStream(arr);
  }
  return new ArrayStream([] as T[]);
}

export function useStream<T>(arr?: ArrayLike<T>) {
  return useMemo(
    () => toStream(arr),
    [arr]
  );
}
