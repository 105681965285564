import React, { useCallback } from "react";
import { Button as AntButton, Typography } from "antd";
import { BaseType } from "antd/es/typography/Base";
import type { ButtonType } from "antd/es/button";
import type {BaseTemplate, BuilderItem } from "../../Builder/types";

type Layout = {
  content: unknown;
  buttonType: ButtonType | 'none';
  labelType?: BaseType;
  label?: string;
  width?: string;
}

type Value = {
  clicked?: boolean;
}

type Item = BuilderItem<Value, 'layout', 'button', Layout, BaseTemplate<'layout', 'button', Layout>>;

const Renderer: Item['renderer'] = ({value, template, onChange, Fabric}) => {
  const onClick = useCallback(() => {
    onChange({clicked: true});
  }, [onChange]);

  const content = template.layout.label ? (
    <Typography.Text
      type={template.layout.labelType}
    >{template.layout.label}</Typography.Text>
  ) : (
    <Fabric
      value={value}
      template={template.layout.content}
      onChange={() => void 0}
      Fabric={Fabric}
    />
  );

  if (template.layout.buttonType === 'none') {
    return (
      <div onClick={onClick} style={{cursor: 'pointer', width: template.layout.width}}>
        {content}
      </div>
    );
  }

  return (
    <AntButton style={{width: template.layout.width}} type={template.layout.buttonType} onClick={onClick}>
      {content}
    </AntButton>
  );
}

const Button: Item = {
  type: 'layout',
  layout: 'button',
  renderer: Renderer,
};

export default Button;
