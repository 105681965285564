import React, { useCallback, useMemo } from "react";
import { EditOutlined, PlusOutlined, DeleteOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Typography } from "antd";
import type { BaseType } from "antd/es/typography/Base";
import type { SizeType } from "antd/es/config-provider/SizeContext";
import type { ButtonType } from "antd/es/button";
import type { BuilderItem } from "../../Builder/types";

type Icons = 'edit' | 'add' | 'trash' | 'up' | 'down';

type Layout = {
  icon: Icons;
  buttonType: ButtonType | 'none';
  buttonSize?: SizeType;
  labelType?: BaseType;
  confirm?: string;
  label?: string;
}

type Value = {
  clicked?: boolean;
}

type Item = BuilderItem<Value, 'layout', 'icon', Layout>;

const Renderer: Item['renderer'] = ({value, template, onChange, Fabric}) => {
  const Icon = useMemo(() => {
    switch (template.layout.icon) {
      case 'edit': return EditOutlined;
      case 'add': return PlusOutlined;
      case 'trash': return DeleteOutlined;
      case 'up': return UpOutlined;
      case 'down': return DownOutlined;
      default: console.warn(`Unknown ${template.layout.icon} icon`); return EditOutlined;
    }
  }, [template.layout.icon]);

  const onClick = useCallback(() => {
    onChange({clicked: true});
  }, [onChange]);

  const confirmMessage = template.layout.confirm;

  const content = template.layout.buttonType !== 'none' ? (
    <Button
      type={template.layout.buttonType}
      size={template.layout.buttonSize}
      onClick={confirmMessage ? undefined : onClick}
      icon={<Icon />}
    >
      {template.layout.label}
    </Button>
  ) : (
    <Typography.Text type={template.layout.labelType} onClick={onClick} style={{cursor: 'pointer'}}>
      {template.layout.label}
      <Icon style={{marginLeft: '5px'}}/>
    </Typography.Text>
  );

  if (confirmMessage) {
    return (
      <Popconfirm
        title={confirmMessage}
        onConfirm={onClick}
        placement="left"
      >
        {content}
      </Popconfirm>
    );
  }

  return (
  <>{content}</>
  );
}

const Icon: Item = {
  type: 'layout',
  layout: 'icon',
  renderer: Renderer,
};

export default Icon;
