import AntComponents from "./Ant";
import NativeReactRenderer from "./Base/NativeReactRenderer";
import EditorComponents from "./Editor";
import LayoutComponents from "./Layout";
import ViewComponents from "./TableRef/View";

export default function AllComponents() {
  return LayoutComponents()
    .add(NativeReactRenderer)
    .join(EditorComponents().getAll())
    .join(AntComponents().getAll())
    .join(ViewComponents().getAll());
}
