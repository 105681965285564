import React, { useCallback, useMemo } from "react";
import { Breadcrumb, Grid, Layout, theme } from "antd";
import Sider from "antd/es/layout/Sider";
import type { ComponentBase } from "../Base/types";
import useValueRef from "../../Elements/useValueRef";

const { Content, Footer } = Layout;

interface Value {
  leftPanel: unknown;
  topLeftPanel?: unknown;
  bottomLeftPanel?: unknown;
  breadcrumb?: string[];
  content: unknown;
  footer?: unknown;
}

interface Template {
  leftPanel: unknown;
  topLeftPanel?: unknown;
  bottomLeftPanel?: unknown;
  content: unknown;
  footer?: unknown;
}

type LeftPanel = ComponentBase<'LeftPanel', Value, Template>;

const Renderer: LeftPanel['renderer'] = ({value, template, onChange, Fabric}) => {
  const valueRef = useValueRef(value);

  const onLeftPanelChange = useCallback(
    (newValue: unknown) => onChange({...valueRef.current, leftPanel: newValue}),
    [valueRef, onChange]
  );

  const onContentChange = useCallback(
    (newValue: unknown) => onChange({...valueRef.current, content: newValue}),
    [valueRef, onChange]
  );

  const onLeftPanelTopChange = useCallback(
    (newValue: unknown) => onChange({...valueRef.current, topLeftPanel: newValue}),
    [valueRef, onChange]
  );

  const onLeftPanelBottomChange = useCallback(
    (newValue: unknown) => onChange({...valueRef.current, bottomLeftPanel: newValue}),
    [valueRef, onChange]
  );

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const bp = Grid.useBreakpoint();

  const isMobile = !bp.md;

  const siderStyles = useMemo(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100%',
    overflow: 'auto',
  } as const), []);

  return (
    <Layout style={{height: '100%'}}>
      <Sider width={200} collapsible={isMobile} style={{
        backgroundColor: colorBgContainer,
      }}>
        <div style={siderStyles}>
          <div>
            {template.layout.topLeftPanel ? (
              <Fabric
                value={value?.topLeftPanel}
                template={template.layout.topLeftPanel}
                onChange={onLeftPanelTopChange}
                Fabric={Fabric}
              />
            ) : null}
            <Fabric
              value={value?.leftPanel}
              template={template.layout.leftPanel}
              onChange={onLeftPanelChange}
              Fabric={Fabric}
            />
          </div>
          <div>
            {template.layout.bottomLeftPanel ? (
              <Fabric
                value={value?.bottomLeftPanel}
                template={template.layout.bottomLeftPanel}
                onChange={onLeftPanelBottomChange}
                Fabric={Fabric}
              />
            ) : null}
          </div>
        </div>
      </Sider>
      <Layout style={{ padding: '0 24px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          {value?.breadcrumb?.map(
            (title, id) => <Breadcrumb.Item key={id}>{title}</Breadcrumb.Item>
          )}
        </Breadcrumb>
        <Content
          style={{
            padding: 0,
            margin: 0,
            overflow: 'auto',
          }}
        >
          <Fabric
            value={value?.content}
            template={template.layout.content}
            onChange={onContentChange}
            Fabric={Fabric}
          />
        </Content>
        {template.layout.footer ? <Footer style={{ padding: '12px 0 0 0' }}>
          <Fabric
            value={value?.footer}
            template={template.layout.footer}
            onChange={() => void 0}
            Fabric={Fabric}
          />
        </Footer> : null}
      </Layout>
    </Layout>
  );
}

const LeftPanelComponent: LeftPanel = {
  name: 'LeftPanel',
  renderer: Renderer,
};

export default LeftPanelComponent;
