import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Spin } from 'antd';
import type { ThemeConfig } from 'antd/es/config-provider/context';
import { useParams, useSearchParams } from 'react-router-dom';

import { useStream, useUpdateEvent } from '../../../Builder/array';
import { YamlTemplate } from '../../../utils/yaml';
import type { ComponentBase } from '../../Base/types';
import type { PageValue } from '../types';
import { Template } from '../../StreamRef/types';

type TemplatePage = ComponentBase<'TemplatePage', PageValue<any, any>, {}>;

// Шаблон дочернего представления
const childTemplate = YamlTemplate.toTemplate(`
type: ref  # Стили ант-дизайна
ref: StyleConfig
layout:
  type: native
  content:
    type: layout  # Далее берем шаблон из значения
    layout:
      type: preview
`);

type ChildValue = {
  // Стили ант-дизайна
  config?: ThemeConfig,
  isDark: boolean,
  content: {
    // Далее берем шаблон из значения
    template: unknown,
    value: unknown,
  },
};

const Renderer: TemplatePage['renderer'] = ({value: initialValue, onChange: onParentChange, Fabric}) => {
  // Используем CRUD шаблонов
  const templatesStream = useStream(initialValue.templates);
  // Для асинхронной подгрузки
  const [template, setTemplate] = useState<Template<string> | null>(null);

  const {templateRoute, tableId, itemId} = useParams();

  // Переход после заполнения
  const [params] = useSearchParams();
  const nextUrl = params.get('next');

  // Меняется, когда меняются настройки шаблона
  const mr = useUpdateEvent(templatesStream, ['update'], 'TemplatePage');

  useEffect(() => {
    // Загружаем шаблон, когда нужно
    (async () => {
      if (!templateRoute) {
        return;
      }

      const result = await templatesStream.find({filters: {route: {eq: templateRoute}}});

      if (!result || result.length < 1) {
        return;
      }

      setTemplate(result[0]);
    })();
  }, [mr, templatesStream, templateRoute]);

  const contentTemplate = useMemo(() => ({
    type: 'ref', // Используем еще один компонент для перехвата CRUD ...
    ref: 'TemplatePage.Content',
    layout: {
      type: 'native',
      // ... и передаем в него шаблон
      template: YamlTemplate.toTemplate(template?.template ?? ''),
      templateRoute,
      tableId,
      itemId,
    },
  }), [template?.template, templateRoute, tableId, itemId]);

  const tableTemplate = useMemo(() => {
    if (!tableId) {
      return contentTemplate;
    }

    return {
      // Подгрузка CRUD указанной в URL таблицы
      type: 'ref',
      ref: 'Table',
      layout: {
        type: 'native',
        tableId,
        template: contentTemplate,
      },
    }
  }, [contentTemplate, tableId]);

  const childValue = useMemo(() => ({
    // Стили ант-дизайна
    config: undefined,
    isDark: true,
    content: {
      // Далее используем полученный шаблон
      template: tableTemplate,
      // Значение этого всего не используется
      value: null,
    },
  }), [tableTemplate]);

  console.log('TemplatePage >', childValue, childTemplate);

  const onChange = useCallback(async (newValue: ChildValue) => {
    // TODO: добавить редирект
    console.log(nextUrl);
  }, [nextUrl]);

  if (!template) {
    return <Spin />;
  }

  return (
    <Fabric
      value={childValue}
      onChange={onChange}
      Fabric={Fabric}
      template={childTemplate}
    />
  );
}

const TemplatePageComponent: TemplatePage = {
  name: 'TemplatePage',
  renderer: Renderer,
};

export default TemplatePageComponent;
