import type { BaseComponents, ComponentBase } from "./types";

export function ComponentFabricBuilder<Components extends BaseComponents<any>>(components: Components) {
  return {
    add<N extends string, V, Layout>(component: ComponentBase<N, V, Layout>) {
      return ComponentFabricBuilder<Components & {[key in N]: typeof component}>({
        ...components,
        [component.name]: component,
      });
    },
    getAll() {
      return components;
    },
    get<N extends keyof Components>(name: N) {
      return components[name];
    },
    join<OtherComponents extends BaseComponents<any>>(otherComponents: OtherComponents) {
      return ComponentFabricBuilder<Components & OtherComponents>({
        ...components,
        ...otherComponents,
      });
    },
  };
}

export function makeComponentBuilder() {
  return ComponentFabricBuilder({});
}
