import { YamlTemplate } from "../../../utils/yaml";
import LensComponentCreator from "../../Base/LensComponentCreator";
import type { ComponentBase } from "../../Base/types";
import type { WebFormEntity } from "../types";
import { makeFormUrl } from "../utils";

const template = YamlTemplate.toTemplate(`
type: object
layout:
  type: vertical
  order:
    - name
    - content
properties:
  name:
    type: string
    title: Название WEB-формы
    layout:
      type: input
  content:
    type: object
    layout:
      type: horizontal
      order:
        - template
        - buttons
      mobileOrder:
        - buttons
    properties:

      template:
        type: string
        layout:
          type: code
          width: 400px
          lang: yaml

      buttons:
        type: object
        layout:
          type: vertical
        properties:
          previewLabel:
            type: string
            layout:
              type: label
              text: Предпросмотр
          previewLink:
            type: string
            layout:
              showQr: true
              type: link
          delim1:
            type: layout
            layout:
              type: delim
          previewContent:
            type: layout
            layout:
              type: preview
          delim2:
            type: layout
            layout:
              type: delim
          additionalFields:
            type: object
            layout:
              type: vertical
            properties:
              table:
                type: ref
                ref: Table
                layout:
                  table: _
                  type: native
                  template:
                    type: layout
                    title: Связанная таблица
                    layout:
                      search: name
                      pkey: alias
                      type: selector
              route:
                type: string
                title: Конец ссылки
                layout:
                  type: input
          edit:
            type: layout
            layout:
              type: routerConsumer
              key: yamlEditor
              content:
                type: layout
                layout:
                  type: button
                  label: Редактировать
`);

type WebFormEditor = ComponentBase<'Editor.WebFormEditor', WebFormEntity, {}>;

type ChildValue = {
  name: string,
  content: {
    template: string,
    buttons: {
      previewLink: string,
      previewContent: {
        template: unknown,
        value: unknown,
      },
      additionalFields: WebFormEntity,
      edit: WebFormEntity,
    },
  },
};

const WebFormEditorComponent = LensComponentCreator<WebFormEditor, ComponentBase<'', ChildValue, {}>>(
  'Editor.WebFormEditor',
  () => template,
  webForm => ({
    name: webForm?.name,
    content: {
      template: webForm?.template,
      buttons: {
        previewLink: makeFormUrl(webForm?.route ?? ''),
        previewContent: {
          template: YamlTemplate.toTemplate(webForm?.template),
          value: null,
        },
        additionalFields: webForm,
        edit: webForm,
      },
    },
  }),
  (prevValue, childValue) => {
    if (prevValue?.name !== childValue.name) {
      return {...prevValue, name: childValue.name};
    }

    if (prevValue?.template !== childValue.content.template) {
      return {...prevValue, template: childValue.content.template};
    }

    if (prevValue !== childValue.content.buttons.additionalFields) {
      return childValue.content.buttons.additionalFields;
    }

    return childValue.content.buttons.edit;
  }
);

export default WebFormEditorComponent;
