import React from "react";
import {Col, Row} from "antd";
import useObjectAsList, {ObjectItem} from "./useObjectAsList";

interface Layout {
  gutter: number[];
}

const Renderer: ObjectItem<'horizontalGrid', Layout>['renderer'] = ({value, template, onChange, Fabric}) => {
  const properties = useObjectAsList(value, onChange, template.properties, template.layout);

  return (
    <Row>
      {properties.map((property, id) => (
        <Col
          key={property.name}
          span={template.layout.gutter?.[id]}
        >
          <Fabric
            value={property.value}
            template={property.template}
            onChange={property.onChange}
            Fabric={Fabric}
          />
        </Col>
      ))}
    </Row>
  )
}

const HorizontalGrid: ObjectItem<'horizontalGrid', Layout> = {
  type: 'object',
  layout: 'horizontalGrid',
  renderer: Renderer,
};

export default HorizontalGrid;
