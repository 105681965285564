import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import type { BaseTemplate, BuilderItem } from "../../../Builder/types";
import useValueRef from "../../useValueRef";
import RouterContext from "./RouterContext";

interface Layout {
  content: {[key: string]: unknown};
}

interface Value {
  selected: string;
  history?: string[];
  content: {[key: string]: unknown};
}

type Item = BuilderItem<Value, 'layout', 'routerProvider', Layout, BaseTemplate<'layout', 'routerProvider', Layout>>;

type CacheItem = {
  key: string;
};

const Renderer: Item['renderer'] = ({value, template, onChange, Fabric}) => {
  // Дублируем для локального роутинга
  const [actualValue, setActualValue] = useState(value);
  const [actualOnChange, setActualOnChange] = useState(() => onChange);

  // Для снижения количества рендеров
  const actualValueRef = useValueRef(actualValue);
  const valueRef = useValueRef(value);

  // История
  const history = useRef<CacheItem[]>([{
    key: value?.selected,
  }]);

  // Текущий ключ для роута
  const currentKey = actualValue?.selected;

  // Обновление от родителя в приоритете
  useEffect(() => {
    // Необходимо подавать history для управления
    if (!value?.history) {
      return;
    }

    // Если локальный роут, ничего не делаем
    if (value.history.length > 1) {
      return;
    }

    // Обновляем текущее значение
    setActualValue(valueRef.current);
  }, [valueRef, value?.selected, value?.history, value?.content]);

  // Обновляем калбэк, если изменился
  useEffect(() => {
    setActualOnChange(() => onChange);
  }, [onChange]);

  // Сообщаем родителю, что локальный роут поменялся
  useEffect(() => {
    const result = history.current.filter(h => !!h.key).map(h => h.key);

    // Если управлялись родителем, ничего не сообщаем
    if (result.join(',') === valueRef.current.history?.join(',')) {
      return;
    }

    onChange({...valueRef.current, history: result});
  }, [onChange, valueRef, history.current.length]);

  // Асинхронная функция роута
  const routeFunction = useCallback(
    async (key: string, newValue: unknown, isLocal: boolean) => await new Promise((resolve, reject) => {
      // Обновляем значение в объекте
      const updatedValue = {
        selected: key,
        content: {
          ...actualValueRef.current?.content,
          [key]: newValue ?? actualValueRef.current?.content?.[key],
        },
      };

      // Если роут нелокальный
      if (!isLocal) {
        // Значит вызываем текущий калбэк
        actualOnChange(updatedValue);
        return;
      }

      // Иначе сохраняем предыдущий роут (значение, калбэк и позицию истории)
      const prevValue = actualValueRef.current;
      const prevOnChange = actualOnChange;
      const lastComponent = history.current.slice(-1)[0];

      // Разделитель в истории на случай открытия роута из самого себя же
      history.current.push({
        key: '',
      });

      // Обновляем значение локально с учетом новых данных
      setActualValue(updatedValue);

      // Обновляем калбэк для перехвата нового значения
      setActualOnChange(() => (newValue: Value) => {
        // Убираем все компоненты из кэша после нашего
        const id = (history.current
          .map((cmp, id) => [cmp, id])
          .filter(([cmp, _]) => cmp === lastComponent)
          .slice(-1)[0]?.[1] ?? -1) as number;
        
        history.current.splice(id + 1);

        // Вызываем калбэк для реакции на изменения
        resolve(newValue.content[key]);

        // Мы должны запустить рендер после реакции на изменения
        setTimeout(() => {
          // Возвращаем предыдущее
          setActualValue(prevValue);
          setActualOnChange(() => prevOnChange);
        });
      });
    }),
    [actualValueRef, history, actualOnChange, setActualValue, setActualOnChange]
  );

  // Формируем пропсы исходя из текущего роута
  const currentValue = useCallback(
    (key?: string) => actualValue?.content?.[key ?? ''],
    [actualValue?.content]
  );

  const currentTemplate = useCallback(
    (key?: string) => template.layout.content?.[key ?? ''] ?? {
      type: 'layout',
      layout: {
        type: 'preview',
      },
    },
    [template.layout.content]
  );

  const currentOnChange = useCallback(
    (key?: string) => (newValue: unknown) => {
      actualOnChange({
        selected: key ?? '',
        content: {
          ...actualValueRef.current?.content,
          [key ?? '']: newValue,
        },
      });
    },
    [actualOnChange, actualValueRef]
  );

  (() => {
    // Последний компонент в кэше
    const cachedComponent = history.current.slice(-1)[0];

    // Если ключ не изменился, то кэш не обновляем
    if (cachedComponent?.key === currentKey) {
      return;
    }

    // Если нелокальный роут, очищаем историю
    if (value === actualValue) {
      history.current.splice(0);
    }

    // Если ключ другой, то в кэше новый элемент
    history.current.push({
      key: currentKey,
    });
  })();

  const result = useMemo(() => history.current.flatMap((cmp, id) => cmp.key ? [
    <div key={id} style={{
      visibility: id + 1 === history.current.length ? 'visible' : 'hidden',
      height: id + 1 === history.current.length ? undefined : '0',
      width: id + 1 === history.current.length ? undefined : '0',
      overflow: id + 1 === history.current.length ? undefined : 'hidden',
    }}>
      <Fabric
        value={currentValue(cmp.key)}
        template={currentTemplate(cmp.key)}
        onChange={currentOnChange(cmp.key)}
        Fabric={Fabric}
      />
    </div>
   ] : []), [history, currentValue, currentTemplate, currentOnChange, Fabric]);

  return (
    <RouterContext value={routeFunction}>
      <div>
        {result}
      </div>
    </RouterContext>
  );
}

const RouterProvider: Item = {
  type: 'layout',
  layout: 'routerProvider',
  renderer: Renderer,
};

export default RouterProvider;
