import { YamlTemplate } from "../../../utils/yaml";
import LensComponentCreator from "../../Base/LensComponentCreator";
import type { ComponentBase } from "../../Base/types";
import type { StreamTable } from "../../TableRef/types";

const template = YamlTemplate.toTemplate(`
type: object
layout:
  type: vertical
  order:
    - name
    - content
properties:
  name:
    type: string
    title: Название таблицы
    layout:
      type: input
  content:
    type: object
    layout:
      type: horizontal
      order:
        - template
        - buttons
      mobileOrder:
        - buttons
    properties:

      template:
        type: string
        layout:
          type: code
          width: 400px
          lang: yaml

      buttons:
        type: object
        layout:
          type: vertical
        properties:
          # webForms:
          #   type: layout
          #   layout:
          #     type: button
          #     label: WEB-Формы
          views:
            type: layout
            layout:
              type: routerConsumer
              key: databaseFormsEditor
              content:
                type: layout
                layout:
                  type: button
                  label: Настройки
          # API:
          #   type: layout
          #   layout:
          #     type: button
          #     label: API
          edit:
            type: layout
            layout:
              type: routerConsumer
              key: yamlEditor
              content:
                type: layout
                layout:
                  type: button
                  label: Расширить редактор
          id:
            type: object
            layout:
              type: horizontal
            properties:
              id:
                type: string
                layout:
                  type: label
`);

type DatabaseEditor = ComponentBase<'Editor.DatabaseEditor', StreamTable<string, unknown>, {}>;

type ChildValue = {
  name: string,
  content: {
    template: string,
    buttons: {
      views: StreamTable<string, unknown>,
      edit: StreamTable<string, unknown>,
      id: StreamTable<string, unknown>,
    },
  },
};

const DatabaseEditorComponent = LensComponentCreator<DatabaseEditor, ComponentBase<'', ChildValue, {}>>(
  'Editor.DatabaseEditor',
  () => template,
  table => ({
    name: table?.name,
    content: {
      template: table?.template,
      buttons: {
        views: table,
        edit: table,
        id: table,
      },
    },
  }),
  (prevValue, childValue) => {
    if (prevValue?.name !== childValue.name) {
      return {...prevValue, name: childValue.name};
    }

    if (prevValue?.template !== childValue.content.template) {
      return {...prevValue, template: childValue.content.template};
    }

    if (prevValue !== childValue.content.buttons.views) {
      return childValue.content.buttons.views;
    }

    return childValue.content.buttons.edit;
  }
);

export default DatabaseEditorComponent;
