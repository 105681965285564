import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid } from 'antd';
import type { ThemeConfig } from 'antd/es/config-provider/context';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useStream, useUpdateEvent } from '../../../Builder/array';
import examples from '../examples';
import useValueRef from '../../../Elements/useValueRef';
import Footer from '../Native/Footer';
import UserBlock from '../Native/UserBlock';
import EditorPageTemplate from './EditorPageTemplate';
import { isDevMode } from '../../../utils/env';

import type { ComponentBase } from '../../Base/types';
import type { Template } from '../../StreamRef/types';
import type { PageValue, User } from '../types';
import type { MenuItem } from '../../Ant/MenuComponent';
import type { NativeReactRendererValue } from '../../Base/NativeReactRenderer/types';
import type { UserBlockProps } from '../Native/UserBlock';

type EditorPage = ComponentBase<'EditorPage', PageValue<any, any>, {}>;

const viewOpenerPageKey = 'viewOpener';
const CAN_GUEST_SEE_EDITOR: boolean = false;

function getLeftPanelItems(customLeftPanelItems: MenuItem[]) {
  return [
    {
      key: 'workspace',
      label: 'Рабочая область',
      isGroup: true,
      children: customLeftPanelItems,
    },
    {
      key: 'constructor',
      label: 'Конструктор',
      isGroup: true,
      children: [
        {
          key: 'Modules',
          label: 'Модули',
          children: [
            {
              key: 'M_webForms',
              label: 'Web-формы',
            },
          ],
        },
        {
          key: 'database',
          label: 'База данных',
        },
        {
          key: 'view',
          label: 'Представления',
        },
        {
          key: 'data',
          label: 'Данные',
        },
      ],
    },
    {
      key: 'administration',
      label: 'Администрирование',
      isGroup: true,
      isShown: true,
      children: [
        {
          key: 'rule',
          label: 'Правила',
        },
        {
          key: 'user',
          label: 'Пользователи',
        },
      ],
    },
    {
      key: 'components',
      label: 'Компоненты',
      isGroup: true,
      isShown: isDevMode(),
      children: [
        {
          key: 'yamlEditor',
          label: 'YAML Редактор',
        },
        {
          key: 'databaseEditor',
          label: 'Редактор модели БД',
        },
        {
          key: 'webFormEditor',
          label: 'Редактор WEB-формы',
        },
        {
          key: 'yamlDocumentation',
          label: 'Документация',
        },
        {
          key: 'databaseFormsEditor',
          label: 'Настройки',
        },
        {
          key: 'viewEditor',
          label: 'Редактирование представления',
        },
        {
          key: 'ruleEditor',
          label: 'Редактирование правила',
        },
        {
          key: 'userEditor',
          label: 'Редактирование пользователя',
        },
        {
          key: 'dataEditor',
          label: 'Список данных',
        },
      ],
    },
  ];
}

type ChildValue = {
  config?: ThemeConfig,
  isDark: boolean,
  content: {
    leftPanel: {
      selected?: string,
      items: unknown,
    },
    topLeftPanel: NativeReactRendererValue<UserBlockProps>,
    bottomLeftPanel: NativeReactRendererValue,
    breadcrumb: string[],
    // NOTE: не используется
    logoSrc: string,
    content: {
      selected: string,
      history?: string[],
      content: {
        database: PageValue<unknown, unknown>['tables'],
        data: PageValue<unknown, unknown>['tables'],
        view: PageValue<unknown, unknown>['templates'],
        rule: PageValue<unknown, unknown>['rules'],
        user: PageValue<unknown, unknown>['users'],
        M_webForms: PageValue<unknown, unknown>['webForms'],
      },
    },
  },
};

function getMenuTitles(menu: MenuItem[], pageSelected: string): string[] | null {
  for (let menuItem of menu) {
    if (menuItem.children) {
      const subTitles = getMenuTitles(menuItem.children, pageSelected);

      if (!subTitles) {
        continue;
      }

      return [menuItem.label ?? menuItem.key, ...subTitles];
    }

    if (menuItem.key === pageSelected) {
      return [menuItem.label ?? menuItem.key];
    }
  }

  return null;
}

function getBreadcrumbTitles(menu: MenuItem[], breadcrumb: string[], pageSelected: string) {
  const base = getMenuTitles(menu, pageSelected);
  const path = breadcrumb.map(key => (getMenuTitles(menu, key) ?? [key]).slice(-1)[0]).slice(1);
  return [...(base ?? []), ...(path ?? [])];
}

function getCustomLeftPanelItems(templates: Template<unknown>[] | null) {
  if (!templates) {
    return [];
  }

  function addToMenu(position: string[], menu: MenuItem[], uniqueKey: string, template: Template<unknown>) {
    const currentUniqueKey = `${uniqueKey}_${position[0]}`;
    let menuItem = menu.find(m => m.key === currentUniqueKey);

    if (!menuItem) {
      menuItem = {
        key: currentUniqueKey,
        label: position[0],
      };

      menu.push(menuItem);
    }

    if (position.length > 1) {
      menuItem.children ??= [];

      addToMenu(position.slice(1), menuItem.children, currentUniqueKey, template);
    } else {
      if (template.alias) {
        menuItem.key = `${viewOpenerPageKey}_${template.alias}`;
      } else {
        console.warn(`Не указан алиас для представления <${template.name}>`);
        menuItem.key = currentUniqueKey;
      }
    }
  }

  const menu: MenuItem[] = [];

  for (let template of templates) {
    if (!template.position) {
      continue;
    }

    addToMenu(template.position, menu, 'customMenu', template);
  }

  return menu;
}

function getCustomView(pageSelected: string) {
  if (pageSelected.indexOf(viewOpenerPageKey) !== 0) {
    return {};
  }

  return {
    [pageSelected]: {
      value: null,
      template: {
        type: 'ref',
        ref: pageSelected.split(viewOpenerPageKey)[1].slice(1),
        layout: {
          type: 'stream',
        },
      },
    },
  };
}

const Renderer: EditorPage['renderer'] = ({value: initialValue, Fabric}) => {
  const [pageSelected, setPageSelected] = useState('M_webForms');
  const [breadcrumb, setBreadcrumb] = useState(['M_webForms']);
  const [customLeftPanelItems, setCustomLeftPanelItems] = useState<MenuItem[]>([]);
  const navigate = useNavigate();

  const templatesStream = useStream(initialValue?.templates ?? examples.templates);
  const mr = useUpdateEvent(templatesStream, ['create', 'update', 'delete'], 'EditorPage');

  useEffect(() => {
    templatesStream.getFrame().then(
      templates => setCustomLeftPanelItems(getCustomLeftPanelItems(templates))
    );
  }, [mr, templatesStream]);

  const leftPanelItems = useMemo(
    () => getLeftPanelItems(customLeftPanelItems),
    [customLeftPanelItems]
  );

  const breadcrumbTitles = useMemo(
    () => getBreadcrumbTitles(leftPanelItems, breadcrumb, pageSelected),
    [leftPanelItems, breadcrumb, pageSelected]
  );

  // NOTE: находится отдельно от value для избежания рендера при изменении breadcrumb
  const routerContent = useMemo(() => ({
    database: initialValue?.tables ?? examples.tables,
    data: initialValue?.tables ?? examples.tables,
    view: templatesStream,
    rule: initialValue?.rules,
    user: initialValue?.users,
    M_webForms: initialValue?.webForms,
    ...getCustomView(pageSelected),
  }), [
    pageSelected, templatesStream,
    initialValue?.tables, initialValue?.webForms,
    initialValue?.rules, initialValue?.users,
  ]);

  const contentValue = useMemo(() => ({
    selected: pageSelected,
    history: breadcrumb,
    content: routerContent,
  }), [pageSelected, breadcrumb, routerContent]);

  const [isDark, setIsDark] = useState(true);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const br = Grid.useBreakpoint();
  const usersStream = useStream(initialValue?.users);
  const api = initialValue?.api ?? null;
  const token = api?.data.token;
  const logoSrc = br.md ? '/main_logo.svg' : '/favicon.svg';
  const currentUserRef = useValueRef(currentUser);
  const [params] = useSearchParams();

  const updateIsDark = useCallback(async (newIsDark: boolean) => {
    setIsDark(newIsDark);

    if (!currentUserRef.current) {
      return;
    }

    await usersStream.update(-1, {
      ...currentUserRef.current,
      uiProps: {
        ...currentUserRef.current?.uiProps,
        isDark: newIsDark,
      },
    });
  }, [usersStream, currentUserRef])

  const value = useMemo<ChildValue>(() => ({
    config: undefined,
    isDark,
    content: {
      leftPanel: {
        selected: pageSelected,
        items: leftPanelItems,
      },
      topLeftPanel: {
        renderer: UserBlock,
        props: {
          user: currentUser,
          isDark,
          setDark: updateIsDark,
        },
      },
      bottomLeftPanel: {renderer: Footer},
      logoSrc,
      breadcrumb: breadcrumbTitles,
      content: contentValue,
    },
  }), [updateIsDark, contentValue, breadcrumbTitles, leftPanelItems, pageSelected, isDark, currentUser, logoSrc]);

  const valueRef = useValueRef(value);

  useEffect(() => {
    async function onPageLoaded() {
      if (!token) {
        if (!CAN_GUEST_SEE_EDITOR) {
          navigate(`/signin?${params.toString()}`);
          window.location.reload();
        }
        return;
      }
      const result = await usersStream.find({filters: {token: {eq: token}}});
      if (!result?.length) {
        api.authorization(null);
        await onPageLoaded();
        return;
      }
      setCurrentUser(result[0]);
      if ('isDark' in (result[0].uiProps ?? {})) {
        setIsDark(result[0].uiProps?.isDark!);
      }
    }
    onPageLoaded();
  }, [token, usersStream, navigate, params, api]);

  const onChildChange = useCallback((newValue: ChildValue) => {
    const newBreadcrumb = newValue.content.content.history;
    const newPageSelected = newValue.content.leftPanel.selected;
    const oldBreadcrumb = valueRef.current.content.content.history;
    // const oldPageSelected = valueRef.current.content.content.selected;

    if (newBreadcrumb && newBreadcrumb.join(',') !== oldBreadcrumb?.join(',')) {
      setBreadcrumb(newBreadcrumb);
      return;
    }

    if (!newPageSelected) {
      return;
    }

    setPageSelected(newPageSelected);
    setBreadcrumb([newPageSelected]);
  }, [valueRef]);

  return (
    <Fabric
      value={value}
      onChange={onChildChange}
      Fabric={Fabric}
      template={EditorPageTemplate}
    />
  );
}

const EditorPageComponent: EditorPage = {
  name: 'EditorPage',
  renderer: Renderer,
};

export default EditorPageComponent;
