import React, { useCallback, useMemo } from "react";
import { useStream } from "../../Builder/array";
import useValueRef from "../useValueRef";
import type {BuilderItem } from "../../Builder/types";
import type { ValueAndStream } from "../array/types";

interface Layout {
  keys: string[];
  values?: string[];
  content: unknown;
}

interface Element {
  key: string;
  value: string;
}

type Item = BuilderItem<unknown, 'layout', 'enum', Layout>;

const Renderer: Item['renderer'] = ({value, template, onChange, Fabric}) => {
  const streamArray = useMemo<Element[]>(
    () => template.layout.keys?.map((key, id) => ({
      key,
      value: template.layout.values?.[id] ?? key,
    })) ?? [],
    [template.layout]
  );

  const stream = useStream(streamArray);
  const valueRef = useValueRef(value);

  const childValue = useMemo<ValueAndStream<Element>>(() => ({
    value,
    stream,
  }), [value, stream]);

  const onChildChange = useCallback((newValue: ValueAndStream<unknown>) => {
    if (valueRef.current !== newValue.value) {
      onChange(newValue.value);
    }

    // NOTE: нам нужно здесь апдейтить стрим?
    // Только если поменять шаблон (enum же!)
    // В такой ситуации изменится streamArray => stream => childValue
    // Это перерендерит контент, но не изменит калбэк
  }, [onChange, valueRef]);

  return (
    <Fabric
      value={childValue}
      template={template.layout.content}
      onChange={onChildChange}
      Fabric={Fabric}
    />
  );
}

const Enum: Item = {
  type: 'layout',
  layout: 'enum',
  renderer: Renderer,
};

export default Enum;
