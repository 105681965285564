import { useCallback, useMemo } from "react";
import type { ArrayLike } from "../../../Builder/array";
// import { inlineTemplateParser } from "../../../utils/inlineTemplateParser";
import type { ComponentBase } from "../../Base/types";
import type { StreamTable } from "../../TableRef/types";

type Table<TN, V> = StreamTable<TN, V> & {
  viewTemplate: string,
  editorTemplate: string,
};

type ChildValue = {
  name: string;
  table?: ArrayLike<unknown>;
}

type TableEditor = ComponentBase<'Editor.TableEditor', Table<string, unknown>, {}>;

const Renderer: TableEditor['renderer'] = ({value: templateValue, onChange: onChangeTemplateValue, Fabric}) => {
  const template = useMemo(() => ({
    type: 'layout',
    layout: {
      type: 'view',
      mode: 'list',
      table: templateValue?.alias,
      editView: templateValue?.editorTemplate ?? null,
      template: {
        '_*': null,
      },
    },
  }), [templateValue?.alias, templateValue?.editorTemplate]);

  // const viewTemplate = useMemo(() => {
  //   const inlineTemplate = templateValue?.viewTemplate;
  //   const template = inlineTemplateParser(inlineTemplate);
  //   return template;
  // }, [templateValue?.viewTemplate]);

  // const template = useMemo(() => ({
  //   type: 'object',
  //   layout: {
  //     type: 'vertical',
  //   },
  //   properties: {
  //     name: {
  //       type: 'string',
  //       layout: {
  //         type: 'label',
  //       },
  //     },
  //     table: {
  //       type: 'array',
  //       layout: {
  //         type: 'editor',
  //         title: viewTemplate,
  //       },
  //       items: {
  //         type: 'ref',
  //         layout: {
  //           type: 'stream',
  //         },
  //         ref: templateValue?.editorTemplate,
  //       },
  //     },
  //   },
  // }), [viewTemplate, templateValue?.editorTemplate]);

  const value: ChildValue = useMemo(() => ({
    name: templateValue?.name,
    table: templateValue?.stream,
  }), [templateValue]);

  const onChange = useCallback((newValue: ChildValue) => {
    if (newValue.table === templateValue?.stream) {
      return;
    }

    onChangeTemplateValue({
      ...templateValue,
      stream: newValue.table,
    });
  }, [onChangeTemplateValue, templateValue]);

  return (
    <Fabric
      value={value}
      template={template}
      onChange={onChange}
      Fabric={Fabric}
    />
  );
}

const TableEditorComponent: TableEditor = {
  name: 'Editor.TableEditor',
  renderer: Renderer,
};

export default TableEditorComponent;
