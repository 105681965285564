import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { useNavigate } from 'react-router-dom';

import type { ComponentBase } from '../../Base/types';
import type { PageValue } from '../types';

type SignoutPage = ComponentBase<'SignoutPage', PageValue<any, any>, {}>;

const Renderer: SignoutPage['renderer'] = ({value}) => {
  // Навигация на главную
  const navigate = useNavigate();

  // По загрузке ...
  useEffect(() => {
    // ... удаляем токен ...
    value?.api?.authorization(null);
    // ... и переходим на главную
    navigate('/');
    window.location.reload();
  }, [navigate, value?.api]);
  
  return (
    <Spin />
  );
}

const SignoutPageComponent: SignoutPage = {
  name: 'SignoutPage',
  renderer: Renderer,
};

export default SignoutPageComponent;
