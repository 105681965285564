import React, { useCallback, useMemo } from "react";
import { ConfigProvider, theme } from "antd";
import type { ThemeConfig } from "antd/es/config-provider/context";
import type { ComponentBase } from "../Base/types";
import ru_RU from "antd/lib/locale/ru_RU";

interface Value {
  config: ThemeConfig;
  isDark?: boolean;
  content: unknown;
}

interface Template {
  content: unknown;
}

type StyleConfig = ComponentBase<'StyleConfig', Value, Template>;

const Renderer: StyleConfig['renderer'] = ({value, template, onChange, Fabric}) => {
  const { defaultAlgorithm, darkAlgorithm } = theme;

  const config: ThemeConfig = useMemo(() => ({
    algorithm: (value?.isDark ?? true) ? darkAlgorithm : defaultAlgorithm,
    ...value?.config,
    token: {
      ...value?.config?.token,
      padding: 8,
    }
  }), [darkAlgorithm, defaultAlgorithm, value?.isDark, value?.config]);

  const onContentChange = useCallback(
    (content: unknown) => onChange({config: value?.config, content}),
    [value?.config, onChange]
  );

  return (
    <ConfigProvider theme={config} locale={ru_RU}>
      <Fabric
        value={value?.content}
        template={template.layout.content}
        onChange={onContentChange}
        Fabric={Fabric}
      />
    </ConfigProvider>
  );
}

const StyleConfigComponent: StyleConfig = {
  name: 'StyleConfig',
  renderer: Renderer,
};

export default StyleConfigComponent;
