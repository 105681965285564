import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Spin } from "antd";

import { useStream } from "../../../Builder/array";
import { YamlTemplate } from "../../../utils/yaml";
import { getListTemplate, joinTemplates } from "./recursive";
import type { ContentComponent, TableTemplate, ViewLayout } from "./types";
import { isObject, isString } from "lodash";

const Content: ContentComponent = ({value: {props}, stream}) => {
  const tableStream = useStream(stream);
  const tableName = props.template.layout.table;
  const briefTemplate = props.template.layout.template;

  const {value: givenValue, onChange, Fabric} = props;
  const [resultTemplate, setResultTemplate] = useState<unknown>(null);

  const viewLayout = useMemo<ViewLayout>(() => ({
    mode: props.template.layout.mode ?? 'read',
    direction: props.template.layout.direction ?? 'vertical',
  }), [props.template.layout.mode, props.template.layout.direction]);

  const listLayout = useMemo(() => {
    if (viewLayout.mode !== 'list') {
      // Если не списком, то ссылки на другие шаблоны не используются
      return null;
    }

    return {
      read: props.template.layout.readView,
      edit: props.template.layout.editView,
    };
  }, [viewLayout.mode, props.template.layout.readView, props.template.layout.editView]);

  const load = useCallback(async () => {
    if (!isString(tableName)) {
      console.warn('Укажите имя таблицы');
      return;
    }

    if (!isObject(briefTemplate)) {
      console.warn('Укажите шаблон');
      return;
    }

    // NOTE: если использовать, будет полный ререндер
    // setResultTemplate(null);

    const tableFound = await tableStream.find(
      {filters: {alias: {eq: tableName}}}
    );

    if (!tableFound?.length) {
      console.warn('Таблица не найдена', tableName);
      return;
    }

    if (tableFound.length > 1) {
      console.warn('Несколько таблиц имеют одинаковое имя', tableName);
      return;
    }

    const table = tableFound[0];
    const tableTemplate: TableTemplate = YamlTemplate.toTemplate(table.template);

    // Извлекаем стрим для шаблонов, не будет работать оффлайн
    // const templatesStream = (
    //   tableStream as ApiTableStream
    // ).getSysStream?.<'Template', Template<string>>('Template');

    // async function getTemplate<T extends string>(name: T) {
    //   if (!templatesStream) {
    //     return null;
    //   }

    //   const templates = await templatesStream.find(
    //     {filters: {name: {eq: name}}}
    //   );

    //   if (!templates?.length) {
    //     return null;
    //   }

    //   return templates[0] as Template<T>;
    // }

    // const stream = toStream(table.stream);

    if (listLayout) {
      try {
        const result = getListTemplate(
          briefTemplate,
          tableTemplate,
          tableFound[0].name,
          listLayout,
        );
        setResultTemplate(result);

      } catch (error) {
        // TODO: использовать только для отладки
        // console.warn(error);
      }

      return;
    }

    try {
      const result = joinTemplates(briefTemplate, tableTemplate.properties, viewLayout);
      setResultTemplate(result);

    } catch (error) {
      // TODO: использовать только для отладки
      // console.warn(error);
    }
  }, [tableStream, briefTemplate, tableName, viewLayout, listLayout]);

  useEffect(() => {
    load();
  }, [load]);

  if (!resultTemplate) {
    return <Spin />;
  }

  return (
    <Fabric
      template={resultTemplate}
      value={givenValue}
      onChange={onChange}
      Fabric={Fabric}
    />
  );
}

export default Content;
