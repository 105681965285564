import React from "react";
import { InputNumber } from "antd";
import type { BuilderItem } from "../../Builder/types";

type Item = BuilderItem<number, 'number', 'input', {}>;

const renderer: Item['renderer'] = ({value, template, onChange}) => {
  return (
    <InputNumber
      defaultValue={value}
      placeholder={template.title}
      value={value}
      onChange={n => n !== null && onChange(n)}
    />
  );
}

const NumberInput: Item = {
  type: 'number',
  layout: 'input',
  renderer,
};

export default NumberInput;
