import { makeComponentBuilder } from "../../Base/builder";
import DatabaseEditorComponent from "./DatabaseEditor";
import ExamplesComponent from "./Examples";
import TableEditorComponent from "./TableEditor";
import TemplateEditor from "./TemplateEditor";
import TemplateFormComponent from "./TemplateForm";
import WebFormEditorComponent from "./WebFormEditor";
import YamlEditorComponent from "./YamlEditor";

export default function Dialogs() {
  return makeComponentBuilder()
    .add(TemplateFormComponent)
    .add(TableEditorComponent)
    .add(YamlEditorComponent)
    .add(DatabaseEditorComponent)
    .add(WebFormEditorComponent)
    .add(ExamplesComponent)
    .add(TemplateEditor);
}
