import { isEqual, isString } from "lodash";

type BracketPair = readonly [string, string];
type Brackets = BracketPair[];
type BracketSearch = [number, number, number];

type StaticBlock = {
  type: 'static',
  content: string,
}

type BracketBlock = {
  type: 'bracket',
  bracketId: number,
  children: Block[],
}

type Block = StaticBlock | BracketBlock;

export function bracketParser(base: unknown, brackets: Brackets, ignore: string) {
  if (!isString(base)) {
    return [];
  }
  function near(str: string, eqs: string[]): BracketSearch | null {
    let min: BracketSearch | null = null;
    eqs.forEach((eq, id) => {
      let currentStr = str;
      let sumPos = 0;
      while (true) {
        const pos = currentStr.indexOf(eq);
        if (pos < 0) {
          return;
        }
        if (pos > 0 && str[pos-1] === ignore) {
          currentStr = currentStr.slice(pos + eq.length);
          sumPos += pos + eq.length;
          continue;
        }
        sumPos += pos;
        if (min === null || sumPos < min[0]) {
          min = [sumPos, id, eq.length];
        }
        return;
      }
    })
    return min;
  }
  let currentBase = base;
  // Открывающие скобки
  const opens = brackets.map(pair => pair[0]);
  // Закрывающие скобки
  const closes = brackets.map(pair => pair[1]);
  // Стек для скобок
  const result: Block[] = [];
  const stack: Block[][] = [result];
  // Близжайшая открывающая
  let openPlace = near(currentBase, opens);
  // Близжайшая закрывающая
  let closePlace = near(currentBase, closes);
  function rightMove() {
    // Открываем скобку
    const children: Block[] = [];
    stack.slice(-1)[0].push({
      type: 'bracket',
      bracketId: openPlace![1],
      children,
    });
    stack.push(children);
  }
  function leftMove() {
    // Закрываем скобку
    stack.pop();
  }
  function pushStatic(pos: BracketSearch) {
    // Добавляем промежуточный текст
    const content = currentBase.slice(0, pos[0]);
    currentBase = currentBase.slice(pos[0] + pos[2]);
    if (!content) {
      return;
    }
    stack.slice(-1)[0].push({
      type: 'static',
      content,
    });
  }
  while (true) {
    if (openPlace && closePlace) {
      if (openPlace[0] < closePlace[0]) {
        pushStatic(openPlace);
        rightMove();
      } else {
        pushStatic(closePlace);
        leftMove();
      }
    } else if (openPlace) {
      pushStatic(openPlace);
      rightMove();
    } else if (closePlace) {
      pushStatic(closePlace);
      leftMove();
    } else {
      pushStatic([currentBase.length, -1, 0]);
      break;
    }
    openPlace = near(currentBase, opens);
    closePlace = near(currentBase, closes);
  }
  return result;
}

export function tests() {
  console.assert(isEqual(
    bracketParser('asd{qwe', [['{', '}']], '/'),
    [
      {
        type: 'static',
        content: 'asd',
      },
      {
        type: 'bracket',
        bracketId: 0,
        children: [
          {
            type: 'static',
            content: 'qwe',
          },
        ],
      }
    ]
  ));
  console.assert(isEqual(
    bracketParser('asd{qw/[e[45]hello}world', [['{', '}'], ['[', ']']], '/'),
    [
      {
        type: 'static',
        content: 'asd',
      },
      {
        type: 'bracket',
        bracketId: 0,
        children: [
          {
            type: 'static',
            content: 'qw/[e',
          },
          {
            type: 'bracket',
            bracketId: 1,
            children: [
              {
                type: 'static',
                content: '45',
              },
            ],
          },
          {
            type: 'static',
            content: 'hello',
          },
        ],
      },
      {
        type: 'static',
        content: 'world',
      },
    ]
  ));
}
