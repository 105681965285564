// NOTE:
//       Do NOT EDIT this file!
// Look README.md for more info

export type ApiError = {
  id: string;
  message: string;
  ctx: {[key: string]: string};
};

type StreamBase = {
  /** Stream key */
  key: string;
  /** Frame number */
  frame: number;
};

export type DataBase = {[key: string]: string};

export type Query = {[key: string]: string};

type Stream = StreamBase & {
  data: DataBase[];
};

type ActionCRUDBase = {
  /** Table.id or native table name */
  model: string;
};

type ActionCreate = ActionCRUDBase & {
  action: 'create';
  data: DataBase;
};

type ActionReadMany = ActionCRUDBase & {
  action: 'readMany';
  data: Query;
};

type ActionUpdate = ActionCRUDBase & {
  action: 'update';
  data: DataBase;
};

type ActionDelete = ActionCRUDBase & {
  action: 'delete';
  /** Entity ID */
  data: string;
};

export type ActionCRUD = ActionCreate | ActionReadMany | ActionUpdate | ActionDelete;

type ResultCRUDBase = {
  /** Entity name or id of custom table */
  model: string;
};

type ResultCreate = ResultCRUDBase & {
  /** Id of created entity */
  data: string;
  action: 'create';
};

type ResultReadMany = ResultCRUDBase & {
  data: Stream;
  action: 'readMany';
};

type ResultUpdate = ResultCRUDBase & {
  data: null;
  action: 'update';
};

type ResultDelete = ResultCRUDBase & {
  data: null;
  action: 'delete';
};

export type ResultCRUD = ResultCreate | ResultReadMany | ResultUpdate | ResultDelete;

export interface Handlers {
  /** CRUD result */
  CRUDResult_gateway?: (payload: ResultCRUD) => void;
  /** Error */
  error?: (payload: {
  /** Api error */
  error: ApiError;
}) => void;
}

abstract class Api {
  protected constructor(
    protected readonly ws: WebSocket,
    protected readonly eventHandlers: Handlers,
    private readonly logging: boolean = false,
  ) {
    ws.onmessage = (event) => {
      let eventData: {
        action: keyof Handlers,
        payload: Exclude<Handlers[keyof Handlers], undefined> extends ((payload: infer T) => void) ? T : never,
      } | undefined;
      try {
        eventData = JSON.parse(event.data);
        this.logging && console.log('WS recv >', eventData);
      } catch (error) {
        this.logging && console.log('WS parse >', error);
        return;
      }

      if (!eventData) {
        console.error('empty event provided');
        return;
      }

      eventHandlers[eventData.action]?.(eventData.payload);
    }
  }

  private sendAction = (action: string, payload: object) => {
    this.logging && console.log('WS send >', action, payload);

    this.ws.send(JSON.stringify({
      action, payload,
    }));
  };

  /** CRUD request */
  public CRUDRequest(payload: ActionCRUD) {
    this.sendAction('CRUDRequest', payload);
  };

  /** Auth token */
  public Authorization(payload: {
  token: string;
}) {
    this.sendAction('Authorization', payload);
  };
}

export default Api;
