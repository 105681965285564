import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, ConfigProvider, Form, message, Typography } from 'antd';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import ru_RU from 'antd/lib/locale/ru_RU';
import { ProConfigProvider, ProFormText } from '@ant-design/pro-components';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { Stream, useStream } from '../../../Builder/array';
import type { AuthLabels, AuthPage, AuthStrategy, OnFinish } from './types';
import { User } from '../types';

const CONFIRM_USERNAME: boolean = true;
const LOCAL_STORAGE_CONFIRMING_USERNAME_KEY = 'usernameConfirming';

// https://procomponents.ant.design/

const contentStyle: CSSProperties = {
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const subContentStyle: CSSProperties = {
  margin: 'auto',
  width: '400px',
};

const headerStyle: CSSProperties = {
  textAlign: 'center',
};

const subHeaderStyle: CSSProperties = {
  marginBottom: '20px',
  display: 'block',
  textAlign: 'center',
};

const submitButtonStyle: CSSProperties = {
  width: '100%',
};

const linksStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '10px',
};

function getLabels(strategy: AuthStrategy, hasResetToken: boolean): AuthLabels {
  if (strategy === 'signin') {
    return {
      mainButton: 'Войти',
      leftLink: 'Регистрация',
      leftUrl: 'signup',
      rightLink: 'Забыли пароль',
      rightUrl: 'changePassword',
    };
  }

  if (strategy === 'signup') {
    return {
      mainButton: 'Регистрация',
      leftLink: 'Войти',
      leftUrl: 'signin',
      rightLink: 'Забыли пароль',
      rightUrl: 'changePassword',
    };
  }

  if (strategy === 'changePassword' && !hasResetToken) {
    return {
      mainButton: 'Отправить подтверждение на почту',
      leftLink: 'Регистрация',
      leftUrl: 'signup',
      rightLink: 'Войти',
      rightUrl: 'signin',
    };
  }

  if (strategy === 'changePassword' && hasResetToken) {
    return {
      mainButton: 'Сменить пароль',
      leftLink: 'Регистрация',
      leftUrl: 'signup',
      rightLink: 'Войти',
      rightUrl: 'signin',
    };
  }

  return {
    mainButton: 'Сохранить и войти',
    leftLink: 'Войти',
    leftUrl: 'signin',
    rightLink: 'Забыли пароль',
    rightUrl: 'changePassword',
  };
}

const Renderer: AuthPage['renderer'] = ({template, value}) => {
  // Переход после заполнения
  const [params] = useSearchParams();
  const {resetToken} = useParams();
  const navigate = useNavigate();
  const nextUrl = params.get('next');
  const workspace = params.get('workspace');
  const usersStream = useStream(value?.users);
  const usersPartialStream = usersStream as Stream<Partial<User>>;
  const strategy = template.layout.strategy ?? 'signin';
  const [loading, setLoading] = useState(false);
  const hasResetToken = !!resetToken;
  const token = value?.api.data.token;

  useEffect(() => {
    if (token) {
      navigate(`/?${params.toString()}`);
      return;
    }
  }, [token, params, navigate]);

  const labels = useMemo(
    () => getLabels(strategy, hasResetToken),
    [strategy, hasResetToken]
  );

  const showPasswordField = (
    strategy === 'signin' ||
    (strategy === 'signup' && !CONFIRM_USERNAME) ||
    (strategy === 'signupConfirm') ||
    (strategy === 'changePassword' && hasResetToken)
  );

  const showConfirmPasswordField = (
    (strategy === 'signup' && !CONFIRM_USERNAME) ||
    (strategy === 'signupConfirm') ||
    (strategy === 'changePassword' && hasResetToken)
  );

  const isEmail = strategy !== 'signupConfirm';

  const onFinishSignin = useCallback<OnFinish>(async (values) => {
    setLoading(true);

    const users = await usersStream.find({filters: {
      username: {eq: values.username},
      password: {eq: values.password},
    }});

    setLoading(false);

    if (!users?.length) {
      message.error('Проверьте данные');
      return;
    }

    const [{token}] = users;

    if (!token) {
      message.error('Отсутствует токен');
      return;
    }

    // Отправляем токен ...
    value?.api?.authorization(token);
    // ... и переходим на главную
    navigate(nextUrl || '/');
  }, [usersStream, navigate, value?.api, nextUrl]);

  const onFinishSignup = useCallback<OnFinish>(async (values) => {
    if (values.password !== values.confirmPassword) {
      message.warning('Пароли не совпадают');
      return;
    }

    setLoading(true);

    await usersPartialStream.create({
      name: values.username,
      workspace: workspace ?? undefined,
      username: values.username,
      password: CONFIRM_USERNAME ? '' : values.password,
    });

    setLoading(false);

    if (CONFIRM_USERNAME) {
      localStorage.setItem(LOCAL_STORAGE_CONFIRMING_USERNAME_KEY, values.username);
      navigate('/unconfirm');
    } else {
      navigate(`/signin?${params.toString()}`);
    }
  }, [navigate, params, usersPartialStream, workspace]);

  const onFinishSendResetToken = useCallback<OnFinish>(async (values) => {
    setLoading(true);

    await usersPartialStream.update(-1, {
      username: values.username,
      password: '',
    });

    setLoading(false);

    message.info('Ссылка на восстановление пароля отправлена на почту');
  }, [usersPartialStream]);

  const onFinishConfirmEmail = useCallback<OnFinish>(async (values) => {
    if (values.password !== values.confirmPassword) {
      message.warning('Пароли не совпадают');
      return;
    }

    const username = localStorage.getItem(LOCAL_STORAGE_CONFIRMING_USERNAME_KEY);

    if (!username) {
      message.warning('Подтверждение было отправлено с другого браузера');
      return;
    }

    setLoading(true);

    await usersPartialStream.update(-1, {
      username,
      name: values.name,
      password: values.password,
      resetToken: resetToken,
    });

    setLoading(false);
    navigate(`/signin?${params.toString()}`);
  }, [navigate, params, resetToken, usersPartialStream]);

  const onFinishChangePassword = useCallback<OnFinish>(async (values) => {
    if (values.password !== values.confirmPassword) {
      message.warning('Пароли не совпадают');
      return;
    }

    setLoading(true);

    await usersPartialStream.update(-1, {
      username: values.username,
      password: values.password,
      resetToken: resetToken,
    });

    setLoading(false);
    navigate(`/signin?${params.toString()}`);
  }, [navigate, params, resetToken, usersPartialStream]);

  const onFinish = useMemo(() => {
    if (strategy === 'signin') {
      return onFinishSignin;
    }

    if (strategy === 'signup') {
      return onFinishSignup;
    }

    if (strategy === 'changePassword' && !hasResetToken) {
      return onFinishSendResetToken;
    }

    if (strategy === 'signupConfirm') {
      return onFinishConfirmEmail;
    }

    return onFinishChangePassword;
  }, [
    strategy,
    hasResetToken,
    onFinishSignin,
    onFinishSignup,
    onFinishSendResetToken,
    onFinishConfirmEmail,
    onFinishChangePassword
  ]);

  const onLeftLink = useCallback(() => {
    navigate(`/${labels.leftUrl}?${params.toString()}`);
  }, [navigate, labels.leftUrl, params]);

  const onRightLink = useCallback(() => {
    navigate(`/${labels.rightUrl}?${params.toString()}`);
  }, [navigate, labels.rightUrl, params]);

  return (
    <ConfigProvider locale={ru_RU}>
      <ProConfigProvider hashed={false}>
        <div style={contentStyle}><div style={subContentStyle}>
          <Typography.Title level={2} style={headerStyle}>
            Chekio
          </Typography.Title>

          <Typography.Text style={subHeaderStyle} type="secondary">
            Сервис для контроля и обучения линейного персонала
          </Typography.Text>

          <Form
            onFinish={onFinish}
          >
            {isEmail && (
              <ProFormText
                name="username"
                fieldProps={{
                  size: 'large',
                  prefix: <UserOutlined className={'prefixIcon'} />,
                }}
                placeholder={'Электронная почта'}
                rules={[
                  {
                    required: true,
                    message: 'Введите электронную почту',
                  },
                ]}
              />
            )}

            {!isEmail && (
              <ProFormText
                name="name"
                fieldProps={{
                  size: 'large',
                  prefix: <UserOutlined className={'prefixIcon'} />,
                }}
                placeholder={'Имя пользователя'}
                rules={[
                  {
                    required: true,
                    message: 'Введите Ваше имя',
                  },
                ]}
              />
            )}

            {showPasswordField && <ProFormText.Password
              name="password"
              fieldProps={{
                size: 'large',
                prefix: <LockOutlined className={'prefixIcon'} />,
              }}
              placeholder={'Пароль'}
              rules={[
                {
                  required: true,
                  message: 'Введите пароль',
                },
              ]}
            />}

            {showConfirmPasswordField && <ProFormText.Password
              name="confirmPassword"
              fieldProps={{
                size: 'large',
                prefix: <LockOutlined className={'prefixIcon'} />,
              }}
              placeholder={'Повторите пароль'}
              rules={[
                {
                  required: true,
                  message: 'Повторите пароль',
                },
              ]}
            />}

            <Button
              type="primary"
              htmlType="submit"
              style={submitButtonStyle}
              loading={loading}
            >{labels.mainButton}</Button>

            <div style={linksStyle}>
              <Button type="link" onClick={onLeftLink}>{
                labels.leftLink
              }</Button>

              <Button type="link" onClick={onRightLink}>{
                labels.rightLink
              }</Button>
            </div>
          </Form>
        </div></div>
      </ProConfigProvider>
    </ConfigProvider>
  );
}

const LoginPageComponent: AuthPage = {
  name: 'AuthPage',
  renderer: Renderer,
};

export default LoginPageComponent;
