import { getBaseFabric } from './fabric';
import type { BuilderItem, NextStore } from './types';

function Builder() {
  function makeReturn<BI>(Store: BuilderItem<any, any, any, any, any>[]) {
    const self = {
      add<V, T extends string, L extends string, Layout, Template>(builderItem: BuilderItem<V, T, L, Layout, Template>) {
        const NewStore = [...Store, builderItem];
        return makeReturn<NextStore<T, L, BuilderItem<V, T, L, Layout, Template>, BI>>(NewStore);
      },
      get<T extends string, L extends string>(type: T, layout: L) {
        type Item = BI extends NextStore<T, L, infer Item, {}> ? Item : BuilderItem<unknown, T, L, unknown, unknown>;
        const items = Store.filter(item => item.type === type && item.layout === layout);
        const item = items[0] as Item | undefined;
        if (!item){
          // console.error(items);
          return null;
        }
        return item;
      },
      getFabric() {
        return getBaseFabric(self.get as any);
      },
    };

    return self;
  }

  return makeReturn<{}>([]);
}

export default Builder;
