import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';

import type { ComponentBase } from '../../Base/types';
import type { PageValue } from '../types';

type TokenAuthPage = ComponentBase<'TokenAuthPage', PageValue<any, any>, {}>;

const Renderer: TokenAuthPage['renderer'] = ({value}) => {
  // Используем токен из URL
  const {token} = useParams();
  // Переход после заполнения
  const [params] = useSearchParams();
  const nextUrl = params.get('next');
  // Навигация на главную
  const navigate = useNavigate();
  // По загрузке ...
  useEffect(() => {
    if (token) {
      // ... отправляем токен ...
      value?.api?.authorization(token);
    }

    // ... и переходим на главную
    navigate(nextUrl || '/');
  }, [token, navigate, value?.api, nextUrl]);
  
  return (
    <Spin />
  );
}

const TokenAuthPageComponent: TokenAuthPage = {
  name: 'TokenAuthPage',
  renderer: Renderer,
};

export default TokenAuthPageComponent;
