import React, { FC, useMemo } from "react";
import { Space, Typography } from "antd";
import { WarningOutlined } from '@ant-design/icons';
import type { BuilderFabric, BuilderItem } from "./types";

const Unknown: BuilderFabric = ({value, template}) => (
  <Typography.Paragraph
    type="danger"
    style={{border: '1px solid', borderRadius: '5px', padding: '5px'}}
  >
    <Space size={0} direction="vertical">
      <Space size={10}>
        <WarningOutlined />
        <span>Unknown fragment</span>
      </Space>
      <Typography.Paragraph type='warning' code>Value: {JSON.stringify(value, null, 2)}</Typography.Paragraph>
      <Typography.Paragraph type='warning' code>Template: {JSON.stringify(template, null, 2)}</Typography.Paragraph>
    </Space>
  </Typography.Paragraph>
);

type GetterBase<T, L, Item> = (type: T, layout: L) => Item | null;

export function getBaseFabric<
  Getter extends GetterBase<any, any, BuilderItem<any, any, any, any>>
>(getNext: Getter) {
  const Fabric: BuilderFabric = (props) => {
    const typedProps = props as Getter extends GetterBase<any, any, {renderer: FC<infer Props>}> ? Props : never;

    const Component = useMemo(
      () => getNext(typedProps.template?.type ?? '', typedProps.template?.layout?.type ?? '')?.renderer,
      [typedProps.template?.type, typedProps.template?.layout?.type]
    );

    if (!typedProps.template) {
      return null;
    }

    if (!Component) {
      return <Unknown {...props} />;
    }

    return <Component {...typedProps} />;
  }

  return Fabric;
}
