import ApiStream from "./ApiStream";
import type { Query } from "../Builder/types";
import type { StreamTable } from "../Components/TableRef/types";
import type { Entity } from "../utils/types";

type Table = StreamTable<unknown, Entity>;

class ApiTableStream extends ApiStream<Table> {
  private cache?: Map<string, ApiStream<Entity>>;

  private fillStream(table: Table) {
    this.cache ??= new Map();
    let stream = this.cache.get(table.id);
    if (!stream) {
      stream = new ApiStream<any>(this.api, table.id);
      this.cache.set(table.id, stream);
    }
    table.stream = stream;
    return table;
  }

  private fillStreams(data: Table[] | null) {
    if (!data) {
      return null;
    }

    return data.map(table => this.fillStream(table));
  }

  async getData(key: string, frame: number, query: Query<Table>) {
    return this.fillStreams(await super.getData(key, frame, query));
  }

  async find(query: Query<Table>) {
    return this.fillStreams(await super.find(query));
  }

  /**
   * 
   * @param tableName имя системной таблицы
   * @returns Стрим для работы с системной таблицей
   */
  getSysStream<T extends string, Element extends Entity>(tableName: T) {
    return new ApiStream<Element>(this.api, tableName);
  }
}


export default ApiTableStream;
