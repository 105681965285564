import React, { useCallback, useMemo } from "react";
import {DatePicker, DatePickerProps, Space, Typography} from "antd";
import type { BuilderItem } from "../../Builder/types";
import dayjs from "dayjs";

type OnInputChange = Exclude<DatePickerProps['onChange'], undefined>;
type PickerType = Exclude<DatePickerProps['picker'], undefined>;

interface Layout {
  disabled?: boolean;
  picker?: PickerType;
  defaultToday?: boolean;
  format?: string;
  placeholder?: string;
}

type Item = BuilderItem<string, 'string', 'datePicker', Layout>;

const Renderer: Item['renderer'] = ({value, template, onChange}) => {
  const dateFormat = useMemo(
    () => template.layout.format || 'DD/MM/YYYY',
    [template.layout.format]
  );

  const onInputChange = useCallback<OnInputChange>((newDate) => {
    if (!newDate) {
      return;
    }

    onChange(newDate.format(dateFormat));
  }, [onChange, dateFormat]);

  const defaultDate = useMemo(
    () => template.layout.defaultToday ? dayjs() : undefined,
    [template.layout.defaultToday]
  );

  const date = useMemo(() => {
    if (!value) {
      return defaultDate;
    }

    const currentDate = dayjs(value, dateFormat);

    if (!currentDate.isValid()) {
      return defaultDate;
    }

    return currentDate;
  }, [value, defaultDate, dateFormat]);

  const datePicker = (
    <DatePicker
        className="w-full"
        placeholder={template.layout.placeholder}
        format={dateFormat}
        disabled={template.layout.disabled}
        picker={template.layout.picker}
        value={date}
        onChange={onInputChange}
    />
  );

  if (!template.title) {
    return datePicker;
  }

  return (
    <Space direction="vertical" className="w-full" size={4}>
      <Typography.Text strong>{template.title}</Typography.Text>
      {datePicker}
    </Space>
  );
}

const StringInput: Item = {
  type: 'string',
  layout: 'datePicker',
  renderer: Renderer,
};

export default StringInput;
