import React, { useCallback, useMemo, useRef } from "react";
import type { ComponentBase, Props } from "./types";

export default function LensComponentCreator<
  Component extends ComponentBase<any, any, any>,
  ChildComponent extends ComponentBase<any, any, any>
>(
  name: Component['name'],
  templateMaker: (template: Props<Component['renderer']>['template']) => Props<ChildComponent['renderer']>['template'],
  valueMaker: (value: Props<Component['renderer']>['value']) => Props<ChildComponent['renderer']>['value'],
  onChange: (value: Props<Component['renderer']>['value'], newValue: Props<ChildComponent['renderer']>['value']) => Props<Component['renderer']>['value']
): Component {
  const Renderer: Component['renderer'] = ({value, template, onChange: parentOnChange, Fabric}) => {
    const childTemplate = useMemo(
      () => templateMaker(template),
      [template]
    );

    const valueRef = useRef(value);
    valueRef.current = value;

    const childValue = useMemo(
      () => valueMaker(value),
      [value]
    );

    const childOnChange = useCallback(
      (newValue: Props<ChildComponent['renderer']>['value']) => parentOnChange(onChange(valueRef.current, newValue)),
      [valueRef, parentOnChange]
    );

    return (
      <Fabric
        value={childValue}
        template={childTemplate}
        onChange={childOnChange}
        Fabric={Fabric}
      />
    );
  }

  return {
    name,
    renderer: Renderer,
  } as Component;
}
