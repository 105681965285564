import React from "react";
import type { NativeReactRendererComponent } from "./types";

const Renderer: NativeReactRendererComponent['renderer'] = ({value: givenValue}) => {
  const value = givenValue?.value ? givenValue.value : givenValue;
  const stream = givenValue?.value ? givenValue.stream : undefined;
  const Renderer = value?.renderer;

  if (!Renderer) {
    return null;
  }

  return <Renderer value={value} stream={stream} />;
}

const NativeReactRenderer: NativeReactRendererComponent = {
  name: 'NativeReactRenderer',
  renderer: Renderer,
};

export default NativeReactRenderer;
