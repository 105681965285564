import React, { useContext } from 'react';

type RouteFunction<T> = (key: string, value: T, isLocal: boolean) => Promise<T>;

const RouterContext = React.createContext<RouteFunction<unknown> | null>(null);

export function useRouterContext<T>() {
  return useContext(RouterContext) as RouteFunction<T> | null;
}

export default RouterContext.Provider;
