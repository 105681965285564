import React from "react";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-yaml";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-text";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-solarized_dark"
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-yaml";
import type { BuilderItem } from "../../Builder/types";

type CodeTypes = 'yaml' | 'json' | 'python' | 'text';

interface Layout {
  lang: CodeTypes,
  width?: number,
  height?: number,
  default?: string,
}

type Item = BuilderItem<string, 'string', 'code', Layout>;

const Renderer: Item['renderer'] = ({value, template, onChange}) => {
  return (
    <AceEditor
      setOptions={{ useWorker: false }}
      defaultValue={template.layout.default}
      tabSize={2}
      style={{
        width: template.layout.width ?? '100%',
        ...(template.layout.height ? {height: template.layout.height} : {})}}
      value={value}
      mode={template.layout.lang || undefined}
      // theme="github"
      theme="solarized_dark"
      onChange={onChange}
      name="DIV_EDITOR"
      editorProps={{ $blockScrolling: true }}
    />
  );
}

const Code: Item = {
  type: 'string',
  layout: 'code',
  renderer: Renderer,
};

export default Code;
