import React, { useCallback, useMemo, useState } from "react";

import { ArrayLike, LensStream, useStream } from "../../Builder/array";
import getAddItemButtonTemplate from "./getAddItemButtonTemplate";
import useValueRef from "../useValueRef";
import getGutter from "../../utils/getGutter";
import type { QuerySort } from "../../Builder/types";
import type { Obj } from "../../utils/types";
import type { ArrayItem } from "./types";

type ArrayViewItem = ArrayItem<'view', {
  // Шаблон для заголовка
  title?: string;
  // Ключи для колонок
  keys: string[];
  // Надписи (по умолчанию ключи)
  titles?: string[];
  // Кастомизация отображения строки
  row?: unknown;
  // Подпись на кнопке "Создать"
  createTitle?: string;
  routeKey?: string;
}, Obj>;

type Values<Element extends Obj = Obj> = {
  title: {
    add?: Element,
  },
  header: {
    header?: {
      [key in keyof Element]: {
        clicked?: boolean,
      }
    },
  },
  body: ArrayLike<Element>,
};

function getHeaderTemplate(keys: string[], titles?: string[], sort?: QuerySort) {
  const properties = keys.map((key, id) => {
    const title = titles?.[id] ?? key;
    const fieldSort = sort?.field === key ? sort.direction : null;

    switch (fieldSort) {
      case 'asc':
        return {
          type: 'layout',
          layout: {
            labelType: 'secondary',
            type: 'icon',
            icon: 'up',
            buttonType: 'none',
            label: title,
          },
        } as const;
      case 'desc':
        return {
          type: 'layout',
          layout: {
            labelType: 'secondary',
            type: 'icon',
            icon: 'down',
            buttonType: 'none',
            label: title,
          },
        } as const;
      case null:
      default:
        return {
          type: 'layout',
          layout: {
            labelType: 'secondary',
            type: 'button',
            buttonType: 'none',
            label: title,
          },
        } as const;
    }
  });

  const fieldsTemplate: {
    [key in string]: typeof properties[number]
  } = {};

  properties.forEach((prop, id) => {
    fieldsTemplate[keys[id]] = prop;
  });

  return {
    type: 'object',
    layout: {
      type: 'horizontalGrid',
      gutter: getGutter(keys),
    },
    properties: fieldsTemplate,
  } as const;
}

function getRowTemplate(keys: string[]) {
  const properties = keys.map((key, id) => {
    return {
      type: 'string',
      layout: {
        type: 'label',
        // Подсвечиваем первый столбец
        strong: id === 0,
      },
    } as const;
  });

  const fieldsTemplate: {[key in string]: typeof properties[number]} = {};

  properties.forEach((prop, id) => {
    fieldsTemplate[keys[id]] = prop;
  });

  return {
    type: 'object',
    layout: {
      type: 'horizontalGrid',
      gutter: getGutter(keys),
    },
    properties: fieldsTemplate,
  } as const;
}

function getTitleTemplate<T>(
  title: string,
  editTemplate: T,
  routeKey?: string,
  createTitle?: string,
) {
  return {
    type: 'object',
    layout: {
      type: 'horizontalGrid',
      gutter: [6, 18],
      order: ['title', 'add'],
    },
    properties: {
      title: {
        type: 'string',
        layout: {
          type: 'label',
          level: 3,
          text: title,
        },
      },
      add: getAddItemButtonTemplate(false, routeKey, editTemplate, createTitle),
    },
  } as const;
}

function getTemplate<T>(
  editTemplate: T,
  headerTemplate: ReturnType<typeof getHeaderTemplate>,
  rowTemplate: ReturnType<typeof getRowTemplate> | unknown,
  titleTemplate?: ReturnType<typeof getTitleTemplate>,
  routeKey?: string,
  createTitle?: string,
) {
  const title = titleTemplate ? {title: titleTemplate} : {};

  return {
    type: 'object',
    layout: {
      type: 'vertical',
      order: ['title', 'header', 'body'],
    },
    properties: {
      ...title,
      header: {
        type: 'object',
        layout: {
          type: 'horizontalFixed',
          widths: ['100%', '80px'],
          order: ['header', 'buttons'],
        },
        properties: {
          header: headerTemplate,
          // TODO: добавить две кнопки
          buttons: null,
        },
      },
      body: {
        type: 'array',
        layout: {
          type: 'editor',
          header: false,
          title: rowTemplate,
          createTitle,
          routeKey,
        },
        items: editTemplate,
      },
    },
  } as const;
}

const Renderer: ArrayViewItem['renderer'] = ({value, template, onChange, Fabric}) => {
  const stream = useStream(value);
  const [sort, setSort] = useState<QuerySort | undefined>();
  const sortRef = useValueRef(sort);

  const formStream = useMemo(
    () => new LensStream<Obj, Obj>(
      stream,
      v => v,
      v => v,
      () => ({
        // TODO: пока что полностью заменяем
        sort: sortRef.current,
      }),
    ),
    [stream, sortRef]
  );

  const formValue = useMemo<Values>(() => ({
    title: {},
    header: {},
    body: formStream,
  }), [formStream]);

  const headerTemplate = useMemo(
    () => getHeaderTemplate(
      template.layout.keys,
      template.layout.titles,
      sort,
    ),
    [
      template.layout.keys,
      template.layout.titles,
      sort,
    ]
  );

  const rowTemplate = useMemo(
    () => template.layout.row ?? getRowTemplate(
      template.layout.keys,
    ),
    [
      template.layout.row,
      template.layout.keys,
    ]
  );

  const titleTemplate = useMemo(
    () => template.layout.title ? getTitleTemplate(
      template.layout.title,
      template.items,
      template.layout.routeKey,
      template.layout.createTitle,
    ) : undefined,
    [
      template.layout.title,
      template.items,
      template.layout.routeKey,
      template.layout.createTitle,
    ]
  )

  const formTemplate = useMemo(
    () => getTemplate(
      template.items,
      headerTemplate,
      rowTemplate,
      titleTemplate,
      template.layout.routeKey,
      template.layout.createTitle,
    ),
    [
      template.items,
      headerTemplate,
      rowTemplate,
      titleTemplate,
      template.layout.routeKey,
      template.layout.createTitle,
    ]
  );

  const onFormChange = useCallback(async (newValue: Values) => {
    if (newValue.title.add) {
      // Нажали на кнопку создания в шапке
      await stream.create(newValue.title.add);
      return;
    }

    const headerClicked = newValue.header.header;

    if (!headerClicked) {
      // TODO: обработать
      return;
    }

    const [sortKey] = Object.keys(headerClicked);

    setSort(prevSort => {
      if (prevSort?.field === sortKey) {
        return {
          field: sortKey,
          direction: prevSort.direction === 'asc' ? 'desc' : 'asc',
        };
      }

      return {
        field: sortKey,
        // TODO: какой дефолт?
        direction: 'asc',
      };
    });

    stream.rerender('update');
  }, [stream]);

  return (
    <Fabric
      value={formValue}
      template={formTemplate}
      onChange={onFormChange}
      Fabric={Fabric}
    />
  );
}

const ArrayView: ArrayViewItem = {
  type: 'array',
  layout: 'view',
  renderer: Renderer,
};

export default ArrayView;
