import React, { useMemo } from "react";
import { YamlTemplate } from "../../../utils/yaml";
import Content from "./Content";
import { ChildValue, ViewItem } from "./types";

// Шаблон дочернего представления
const childTemplate = YamlTemplate.toTemplate(`
type: ref
ref: Table
layout:
  type: native
  table: _
  template:
    type: ref
    ref: NativeReactRenderer
    layout:
      type: native
`);

const Renderer: ViewItem['renderer'] = (props) => {
  const {Fabric} = props;

  const childValue = useMemo<ChildValue>(() => ({
    renderer: Content,
    props,
  }), [props]);

  return (
    <Fabric
      value={childValue}
      template={childTemplate}
      onChange={a => console.log('View.onChange', a)}
      Fabric={Fabric}
    />
  );
}

const View: ViewItem = {
  type: 'layout',
  layout: 'view',
  renderer: Renderer,
};

export default View;
