import React, { useCallback } from "react";
import type {BuilderItem } from "../../Builder/types";
import useValueRef from "../useValueRef";

type Item<T = any, V = any> = BuilderItem<{
  template: T,
  value: V,
}, 'layout', 'preview', {}>;

const Renderer: Item['renderer'] = ({value = {template: {}, value: {}}, template, onChange, Fabric}) => {
  const valueRef = useValueRef(value);

  const onChangePreview = useCallback((newValue: any) => onChange({
    ...valueRef.current,
    value: newValue,
  }), [valueRef, onChange]);

  return (
    <Fabric
      value={value?.value}
      template={value?.template}
      onChange={onChangePreview}
      Fabric={Fabric}
    />
  )
}

const Preview: Item = {
  type: 'layout',
  layout: 'preview',
  renderer: Renderer,
};

export default Preview;
