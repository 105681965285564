import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Divider, Empty, Skeleton, Space } from "antd";
import { ArrayStream, useStream, useUpdateEvent } from "../../Builder/array";
import useValueRef from "../useValueRef";
import type { ArrayItem } from "./types";

type BaseArrayItem = ArrayItem<'base', {}>;

const Renderer: BaseArrayItem['renderer'] = ({value, template, onChange, Fabric}) => {
  const [values, setValues] = useState<unknown[] | null>(null);
  const stream = useStream(value);
  const mr = useUpdateEvent(stream, ['create', 'update', 'delete'], 'BaseArray');

  const loadArray = useCallback(async () => {
    setValues(null);
    const data = await stream.getFrame();
    if (!data) {
      return;
    }
    setValues(data);
  }, [stream]);

  useEffect(() => {
    loadArray();
  }, [loadArray, mr]);

  const valuesRef = useValueRef(values);

  const childChanges = useMemo(
    () => values?.length ? Array.from({length: values.length}).map(
      (_, id) => async (newValue: unknown) => {
        const result = await stream.update(id, newValue);
        const valuesResult = result ? result : await (new ArrayStream(valuesRef.current!)).update(id, newValue);
        // NOTE: Не сочетается с линзой при имитировании
        // Stream => LensStream
        // upValue < value
        // Верным будет value => upValue => value для консистентности
        result && setValues(result);
        onChange(valuesResult);
  }) : [], [onChange, stream, values?.length, valuesRef]);

  const children = useMemo(() => values?.map((value, id) => (
    <Fabric
      key={id}
      value={value}
      template={template?.items}
      onChange={childChanges[id]}
      Fabric={Fabric}
    />
  )), [Fabric, childChanges, template?.items, values]);

  const childrenAndPlaceholder = useMemo(() => {
    if (!children) {
      return <Skeleton paragraph={{ rows: 10 }} active />;
    }

    if (children.length === 0) {
      return <Empty style={{}} />;
    }

    return children;
  }, [children]);

  return (
    <Space direction="vertical" style={{width: '100%'}} split={<Divider style={{margin: 0}} />}>
      {childrenAndPlaceholder}
    </Space>
  );
}

const BaseArray: BaseArrayItem = {
  type: 'array',
  layout: 'base',
  renderer: Renderer,
};

export default BaseArray;
