function getGutter(fields: string[]): number[] {
  const columnsCount = fields.length;
  const maxGutter = 24;
  if (columnsCount === 0) {
    return [];
  }
  const oneColumn = Math.floor(maxGutter / columnsCount);
  let rest = maxGutter - oneColumn * columnsCount;
  return fields.map((field, id) => {
    if (rest > 0) {
      rest--;
      return oneColumn;
    }

    return oneColumn;
  });
}

export default getGutter;
