import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Spin } from "antd";
import { ArrayLike, useStream } from "../../Builder/array";
import { YamlTemplate } from "../../utils/yaml";
import type { StreamComponentsChildValue, StreamComponentsItem, StreamRefItem, Template } from "./types";
import useValueRef from "../../Elements/useValueRef";

function getErrorTemplate(title: string) {
  return {
    id: 'errorTemplate',
    name: 'Error template',
    alias: 'ErrorTemplate' as any,
    template: YamlTemplate.toYaml({
      type: 'string',
      layout: {
        type: 'label',
        italic: true,
        text: title,
      },
    }),
  } as const;
}

function StreamRefFabric<T>(templatesStream?: ArrayLike<Template<T>>) {
  const StreamRefRenderer: StreamRefItem<T>['renderer'] = (props) => {
    const {Fabric} = props;
    const stream = useStream(templatesStream);
    const [template, setTemplate] = useState<Template<T> | null>(null);

    useEffect(() => {
      (async () => {
        if (!props.template.ref) {
          setTemplate(getErrorTemplate('Не указано представление'));
          return;
        }
        const templates = await stream.find({filters: {alias: {eq: props.template.ref}}});
        if (!templates?.length) {
          setTemplate(getErrorTemplate('Представление не найдено'));
          return;
        }
        if (templates.length > 1) {
          setTemplate(getErrorTemplate('Найдено несколько представлений'));
          return;
        }
        setTemplate(templates[0]);
      })();
    }, [stream, setTemplate, props.template.ref]);

    const templateObj = useMemo(
      () => template?.template && YamlTemplate.toTemplate(template.template),
      [template?.template]
    );

    if (!template) {
      return <Spin />;
    }

    return (
      <Fabric
        {...props}
        template={templateObj}
      />
    );
  };

  const StreamComponentsRenderer: StreamComponentsItem<T>['renderer'] = ({value, template, onChange, Fabric}) => {
    const stream = useStream(templatesStream);
    const valueRef = useValueRef(value);

    const onChildChange = useCallback((newValue: StreamComponentsChildValue<typeof stream>) => {
      if (valueRef.current !== newValue.value) {
        onChange(newValue.value);
      }

      // NOTE: нам нужно здесь апдейтить стрим?
      // Полный объект таблицы нужен только для этого, так что пока не апдейтится
    }, [onChange, valueRef]);

    const childValue: StreamComponentsChildValue<typeof stream> = useMemo(
      () => ({stream, value}),
      [stream, value]
    );

    if (!childValue) {
      return <Spin />;
    }

    return (
      <Fabric
        value={childValue}
        template={template.layout.template}
        onChange={onChildChange}
        Fabric={Fabric}
      />
    );
  };

  const StreamRef: StreamRefItem<T> = {
    type: 'ref',
    layout: 'stream',
    renderer: StreamRefRenderer,
  };

  const StreamComponents: StreamComponentsItem<T> = {
    type: 'ref',
    layout: 'streamComponents',
    renderer: StreamComponentsRenderer,
  };

  return {StreamRef, StreamComponents};
}

export default StreamRefFabric;
