import { LensStream, Stream } from "../../../../Builder/array";
import { YamlTemplate } from "../../../../utils/yaml";

export interface Example<V> {
  name: string;
  template: string;
  value: V;
  description: string;
}

export interface Value<E extends Example<unknown>> {
  template: unknown;
  value: {
    editor: E['template'],
    result: {
      name: E['name'],
      preview: {
        template: unknown,
        value: E['value'],
      },
      value: string,
      description: E['description'],
    },
  },
}

function getValue<T extends Example<unknown>>(example: T) {
  return {
    template: {
      type: 'object',
      layout: {
        type: 'horizontal',
      },
      properties: {
        editor: {
          type: 'string',
          layout: {
            type: 'code',
            lang: 'yaml',
            width: '300px',
          },
        },
        result: {
          type: 'object',
          layout: {
            type: 'vertical',
          },
          properties: {
            name: {
              type: 'string',
              layout: {
                type: 'label',
              },
            },
            preview: {
              type: 'layout',
              layout: {
                type: 'preview',
              },
            },
            value: {
              type: 'string',
              layout: {
                type: 'code',
                lang: 'json',
                height: '300px',
                width: '300px',
              },
              height: 300,
            },
            description: {
              type: 'string',
              layout: {
                type: 'label',
              },
            }
          }
        }
      }
    },
    value: {
      editor: example.template,
      result: {
        name: example.name,
        preview: {
          template: YamlTemplate.toTemplate(example.template),
          value: example.value,
        },
        value: JSON.stringify(example.value, null, 2),
        description: example.description,
      },
    },
  };
};

export function getExample<T extends Value<Example<unknown>>>(value: T) {
  return {
    name: value.value.result.name,
    template: value.value.editor,
    value: value.value.result.preview.value,
    description: value.value.result.description,
  };
}

export function getExampleLensStream<T extends Example<unknown>>(stream: Stream<T>) {
  return new LensStream<Value<T>, T>(
    stream,
    v => getExample(v) as T,
    v => getValue(v),
  );
}
