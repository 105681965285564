import React, { FC, useMemo } from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import getFabric from "./getFabric";
import routes from "./routes";

const Router: FC = () => {
  const {Fabric, streams} = useMemo(() => getFabric(), []);

  const router = useMemo(() => createBrowserRouter(routes.map(route => ({
    path: route.path,
    element: (
      <Fabric
        value={streams}
        template={route.template}
        onChange={() => void 0}
        Fabric={Fabric}
      />
    )
  }))), [Fabric, streams]);

  return (
    <RouterProvider router={router} />
  );
}

export default Router;
