import Builder from "../Builder";
import BooleanInput from "./boolean/BooleanInput";
import HorisontalObject from "./object/HorisontalObject";
import VerticalObject from "./object/VerticalObject";
import Code from "./string/Code";
import StringInput from "./string/StringInput";
import Preview from "./layout/Preview";
import TabsObject from "./object/TabsObject";
import BaseArray from "./array/BaseArray";
import Button from "./layout/Button";
import Dialog from "./layout/Dialog";
import Icon from "./layout/Icon";
import StringLabel from "./string/StringLabel";
import ArrayEditor from "./array/ArrayEditor";
import HorizontalFixedObject from "./object/HorizontalFixedObject";
import RouterProvider from "./layout/Router/RouterProvider";
import RouterConsumer from "./layout/Router/RouterConsumer";
import ConfirmBlock from "./layout/ConfirmBlock";
import Delim from "./layout/Delim";
import Selector from "./layout/Selector";
import StringLink from "./string/StringLink";
import TagEditor from "./array/TagEditor";
import NumberInput from "./number/NumberInput";
import CollapseItem from "./layout/Collapse";
import AudioRec from "./string/AudioRec";
import Enum from "./array/Enum";
import Photo from "./layout/Photo";
import DatePicker from "./string/DatePicker";
import HorizontalGrid from "./object/HorizontalGrid";
import View from "./layout/View";
import ArrayView from "./array/ArrayView";

function AllElements() {
  const builder = Builder()
    .add(BooleanInput)
    .add(StringInput)
    .add(Code)
    .add(VerticalObject)
    .add(HorisontalObject)
    .add(Preview)
    .add(TabsObject)
    .add(BaseArray)
    .add(Button)
    .add(Dialog)
    .add(Icon)
    .add(StringLabel)
    .add(ArrayEditor)
    .add(HorizontalFixedObject)
    .add(RouterProvider)
    .add(RouterConsumer)
    .add(ConfirmBlock)
    .add(Delim)
    .add(Selector)
    .add(StringLink)
    .add(TagEditor)
    .add(NumberInput)
    .add(CollapseItem)
    .add(AudioRec)
    .add(Enum)
    .add(Photo)
    .add(DatePicker)
    .add(HorizontalGrid)
    .add(View)
    .add(ArrayView);
  
  return builder;
}

export default AllElements;
