import React, { useCallback, useEffect, useState } from "react";
import { Grid, Modal } from "antd";
import type {BaseTemplate, BuilderItem } from "../../Builder/types";

type Layout = {
  button: unknown;
  header: string;
  body: unknown;
}

type Item<T = unknown> = BuilderItem<T, 'layout', 'dialog', Layout, BaseTemplate<'layout', 'dialog', Layout>>;

const Renderer: Item['renderer'] = ({value, template, onChange, Fabric}) => {
  const [isOpen, setOpen] = useState(false);
  const [state, setState] = useState<unknown>(value);

  useEffect(() => {
    setState(value);
  }, [setState, value]);

  const onClick = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const onOk = useCallback(() => {
    setOpen(false);
    onChange(state);
  }, [setOpen, onChange, state]);

  const onCancel = useCallback(() => {
    setOpen(false);
    setState(value);
  }, [setOpen, value]);

  const bp = Grid.useBreakpoint();

  return (
    <>
      <Fabric
        value={value}
        template={template.layout.button}
        onChange={onClick}
        Fabric={Fabric}
      />
      <Modal
        open={isOpen}
        destroyOnClose
        title={template.layout.header}
        okText='OK'
        cancelText='Отмена'
        onOk={onOk}
        onCancel={onCancel}
        width={bp.md ? '70%' : '100%'}
        bodyStyle={{
          overflow: 'hidden',
        }}
      >
        <Fabric
          value={isOpen ? state : null}
          template={template.layout.body}
          onChange={setState}
          Fabric={Fabric}
        />
      </Modal>
    </>
  );
}

const Dialog: Item = {
  type: 'layout',
  layout: 'dialog',
  renderer: Renderer,
};

export default Dialog;
