// import { message } from "antd";
import AbstractApi, { ResultCRUD, Handlers as IHandlers, ActionCRUD, ApiError } from "./api.interface";

export type Listener = (result: ResultCRUD) => boolean;
const AuthStorageKey = 'Authorization';

class Handlers implements IHandlers {
  private readonly listeners: {[model: string]: Set<Listener>};

  constructor() {
    this.listeners = {};
  }

  addListener(cb: Listener, model: string) {
    const listener: Listener = (result) => {
      if (cb(result)) {
        this.listeners[model].delete(listener);
        return true;
      }
      return false;
    }
    this.listeners[model] ??= new Set();
    this.listeners[model].add(listener);
    return listener;
  }

  CRUDResult_gateway(payload: ResultCRUD) {
    this.listeners[payload.model]?.forEach(l => l(payload));
  }

  error({error}: {error: ApiError}) {
    // TODO: обработать это в другом месте
    console.warn(error.message);
    // message.warning(error.message);
  }
}

interface ApiData {
  token?: string;
}

export class Api extends AbstractApi {
  readonly data: ApiData;

  constructor(
    ws: WebSocket,
    protected readonly eventHandlers: Handlers
  ) {
    super(ws, eventHandlers, true);
    this.data = {};
    this.ws.addEventListener('close', function callback() {
      window.location.reload();
    });
  }

  addListener(cb: Listener, model: string) {
    return this.eventHandlers.addListener(cb, model);
  }

  private afterOpen(cb: () => void) {
    // NOTE: чтобы не отправить сообщение раньше TCP подключения
    // Актуально при первом рендере страницы
    if (this.ws.readyState === this.ws.OPEN) {
      cb();
      return;
    }

    const self = this;

    this.ws.addEventListener('open', function callback() {
      cb();
      self.ws.removeEventListener('open', callback);
    });
  }

  action(action: ActionCRUD) {
    this.afterOpen(() => {
      this.CRUDRequest(action);
    });
  }

  authorization(token: string | null) {
    if (!token) {
      localStorage.removeItem(AuthStorageKey);
      this.data.token = undefined;
      return;
    }

    localStorage.setItem(AuthStorageKey, token);
    this.data.token = token;

    this.afterOpen(() => {
      this.Authorization({token});
    });
  }

  setup() {
    const token = localStorage.getItem(AuthStorageKey);
    if (token) {
      this.authorization(token);
      this.data.token = token;
    }
  }
}

function ApiFabric(wsUrl: string) {
  const api = new Api(new WebSocket(wsUrl), new Handlers());
  api.setup();
  return api;
}

export default ApiFabric;
