import React, { useCallback, useMemo, useState } from "react";
import { YamlTemplate } from "../../../utils/yaml";
import type { ComponentBase } from "../../Base/types";
import type { Template } from "../../StreamRef/types";

const template = YamlTemplate.toTemplate(`
type: object
layout:
  type: horizontal
  order:
    - left
    - right
properties:
  left:
    type: object
    layout:
      type: vertical
    properties:
      name:
        type: string
        layout:
          type: input
      template:
        type: string
        layout:
          type: code
          width: 300px
          lang: yaml
  right:
    type: object
    layout:
      type: vertical
    properties:
      preview:
        type: layout
        layout:
          type: preview
      result:
        type: string
        layout:
          type: code
          lang: json
          height: 300
`);

type Value = {
  left: Template<any>,
  right: {
    preview: {
      template: any,
      value: any,
    },
    result: string,
  },
};

type TemplateForm = ComponentBase<'Editor.TemplateForm', Template<any>, {}>;

const Renderer: TemplateForm['renderer'] = ({value: templateValue, onChange: onChangeTemplateValue, Fabric}) => {
  const [previewValue, setPreviewValue] = useState<unknown>(null);

  const value: Value = useMemo(() => ({
    left: templateValue,
    right: {
      preview: {
        template: YamlTemplate.toTemplate(templateValue?.template ?? ''),
        value: previewValue,
      },
      result: JSON.stringify(previewValue, null, 2),
    },
  }), [templateValue, previewValue]);

  const onChange = useCallback((newValue: Value) => {
    if (newValue.left !== value.left) {
      onChangeTemplateValue(newValue.left);
    }
    if (newValue.right.preview.value !== value.right.preview.value) {
      setPreviewValue(newValue.right.preview.value);
    }
  }, [setPreviewValue, onChangeTemplateValue, value]);

  return (
    <Fabric
      value={value}
      template={template}
      onChange={onChange}
      Fabric={Fabric}
    />
  );
}

const TemplateFormComponent: TemplateForm = {
  name: 'Editor.TemplateForm',
  renderer: Renderer,
};

export default TemplateFormComponent;
