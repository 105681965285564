import React from "react";
import { Divider } from "antd";
import type { BuilderItem } from "../../Builder/types";

type Item = BuilderItem<unknown, 'layout', 'delim', {}>;

const Renderer: Item['renderer'] = ({value, template, onChange, Fabric}) => {
  return (
    <Divider style={{margin: '5px', minWidth: 'calc(100% - 5px)', width: 'calc(100% - 5px)'}} />
  );
}

const Delim: Item = {
  type: 'layout',
  layout: 'delim',
  renderer: Renderer,
};

export default Delim;
