import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Webcam from "react-webcam";
import {Button, Grid, Image, Space, Typography} from "antd";
import {
  CameraOutlined,
  CloseOutlined,
  DeleteOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";

import type { BuilderItem } from "../../Builder/types";
import useValueRef from "../useValueRef";
import {isFunction} from "lodash";
import uploadFile from "../../utils/uploadFile";
import {useSearchParams} from "react-router-dom";
import stopPropagation from "../../utils/stopPropagation";

interface Layout {}

type Item = BuilderItem<string[], 'layout', 'Photo', Layout>;

const CAMERA_KEY = 'camera';
const CAMERA_VALUE = 'true';
const CameraShot = './cameraShot.svg';

const fixedHeight = {
  height: '75px',
} as const;

const buttonContainer = {
  position: "fixed",
  bottom: "40px",
  left: "50%",
  transform: "translateX(-50%)",
} as const;

const cancelButton = {
  position: "fixed",
  right: "10px",
  top: "10px",
  height: "80px"
} as const;

const cssProps = {
  position: 'fixed',
  top: '0px',
  left: '0px',
  bottom: '0px',
  right: '0px',
  backgroundColor: 'black',
  zIndex: 10000,
  display: 'block',
  height: "100%",
  overflow: "scroll",
  justifyContent: "center",
} as const;

const roundedBorder = {
  borderRadius: '10px',
};

const mimeType = 'image/jpeg';

const Renderer: Item['renderer'] = ({value, template, onChange, Fabric}) => {
  const webcamRef = useRef<Webcam | null>(null);
  const [photos, setStatePhotos] = useState<string[]>(value ?? []);
  const [currentPhoto, setCurrentPhoto] = useState<number>(-1);
  const [currentDevice, setCurrentDevice] = useState<number>(-1);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const devicesRef = useValueRef(devices);
  const currentPhotoRef = useValueRef(currentPhoto);
  const bp = Grid.useBreakpoint();
  const [params, setParams] = useSearchParams();
  // setParams мутабится вместе с params
  const setParamsRef = useValueRef(setParams);

  const showFullCamera = params.get(CAMERA_KEY) === CAMERA_VALUE;

  const setPhotos = useCallback<typeof setStatePhotos>(value => setStatePhotos(prevValue => {
    const newValue = isFunction(value) ? value(prevValue) : value;
    onChange(newValue);
    return newValue;
  }), [onChange]);

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(
      devices => setDevices(devices.filter(device => device.kind === 'videoinput'))
    );

    setParamsRef.current(prev => {
      // При первом рендере всегда скрываем камеру
      prev.delete(CAMERA_KEY);
      return prev;
    });
  }, [setParamsRef]);

  const capture = useCallback(async () => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (!imageSrc) {
      return;
    }

    let prevPhotos: string[] = [];

    setStatePhotos((statePrevPhotos) => [
      imageSrc,
      ...(prevPhotos = statePrevPhotos),
    ]);

    setCurrentPhoto(0);

    const blobResponse = await fetch(imageSrc);
    const imageBlob = await blobResponse.blob();
    const uploader = uploadFile(imageBlob);

    // Урлу мы знаем заранее
    const url = (await uploader.next()).value;

    const newPhotos = [
      url,
      ...prevPhotos,
    ];

    // Сообщаем родителю заранее
    onChange(newPhotos);
    // Ждем загрузки
    await uploader.next();
    // Обновляем синхронно после загрузки фото
    setPhotos(newPhotos);
  }, [webcamRef, setPhotos, onChange]);

  const prev = useCallback(
    () => setCurrentPhoto(p => p === -1 ? -1 : p - 1),
    []
  );

  const next = useCallback(
    () => setCurrentPhoto(p => p + 1),
    []
  );

  const remove = useCallback(() => {
    setPhotos(
        prevPhotos => prevPhotos.filter(
            (_, i) => i !== currentPhotoRef.current
        )
    )
      setCurrentPhoto(currentPhotoRef.current-1);
    }, [currentPhotoRef, setPhotos]);

  const switchDevice = useCallback(
    () => setCurrentDevice(prevDevice => {
      if (prevDevice === devicesRef.current.length - 1) {
        prevDevice = 0;
      } else {
        prevDevice++;
      }
      return prevDevice;
    }),
    [devicesRef]
  );

  const showCamera = useCallback(
    () => setParamsRef.current(prev => {
      prev.set(CAMERA_KEY, CAMERA_VALUE);
      return prev;
    }),
    [setParamsRef]
  );

  const hideCamera = useCallback(
    () => setParamsRef.current(prev => {
      prev.delete(CAMERA_KEY);
      return prev;
    }),
    [setParamsRef]
  );

  const videoConstraintsSize = useMemo(() => {
    if (bp.md) {
      return {
        width: 1280,
        height: 720,
      };
    }

    return {
      width: 720,
      height: 1280,
    };
  }, [bp.md]);

  const videoConstraints = useMemo(() => {
    if (currentDevice < 0 || currentDevice >= devices.length - 1) {
      return {
        ...videoConstraintsSize,
        facingMode: 'environment',
      } as const;
    }

    return {
      ...videoConstraintsSize,
      deviceId: devices[currentDevice].deviceId,
    } as const;
  }, [currentDevice, devices, videoConstraintsSize]);

  const iconProps = useMemo(() => {
      return {
        opacity: "50%",
        fontSize: bp.md ? "200%" : "150%",
        margin: bp.md ? "20px" : "5px",
      } as const;
  }, [bp.md]);

  const fullCameraContent = showFullCamera && (
    <div style={cssProps} onClick={stopPropagation}>
      {currentPhoto === -1 && (
          <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat={mimeType}
              width="100%"
              videoConstraints={videoConstraints}
              style={roundedBorder}
              onClick={switchDevice}
          />
      )}

      {currentPhoto >= 0 && currentPhoto < photos.length && (
          <Image
              width="100%"
              src={photos[currentPhoto]}
              style={roundedBorder}
          />
      )}

      <Button
          style={cancelButton}
          shape="round"
          size="small"
          icon={<CloseOutlined style={iconProps} />}
          type="text"
          onClick={hideCamera}
      />

      {(photos.length > 0 || currentPhoto === -1) && (
          <div style={buttonContainer}>
            <Button
                style={fixedHeight}
                shape="round"
                size="small"
                icon={<LeftOutlined style={iconProps} />}
                type="text"
                disabled={currentPhoto === -1}
                onMouseDown={prev}
            />

            {currentPhoto !== -1 && (<>
              <Button
                  style={fixedHeight}
                  shape="round"
                  size="small"
                  icon={<DeleteOutlined style={iconProps} />}
                  type="text"
                  disabled={currentPhoto === -1}
                  onClick={remove}
              />
            </>)}

            {currentPhoto === -1 && (<>
              <Button
                  style={{...fixedHeight, bottom: '4px', width: "57px"}}
                  shape="round"
                  size="small"
                  icon={<img src={CameraShot} style={{opacity: "20%"}} alt=""/>}
                  type="text"
                  onClick={capture}
              />
            </>)}

            <Button
                style={fixedHeight}
                shape="round"
                size="small"
                icon={<RightOutlined style={iconProps} />}
                type="text"
                disabled={currentPhoto === photos.length - 1}
                onMouseDown={next}
            />
          </div>
      )}
    </div>
  );

  return (
      <Space direction="vertical" className="w-full" size={10} onClick={stopPropagation}>
        <Typography.Text strong>{template.title}</Typography.Text>
        <Button
            style={{
              height: '75px',
              borderRadius: '7px',
            }}
            shape="default"
            size="small"
            icon={<CameraOutlined />}
            type="dashed"
            onClick={showCamera}
        >
          <div>Сделать фото</div>
        </Button>
        {fullCameraContent}
      </Space>
  );
}

const Photo: Item = {
  type: 'layout',
  layout: 'Photo',
  renderer: Renderer,
};

export default Photo;
