import React, { useCallback, useEffect, useState } from "react";
import { Space } from "antd";
import type {BaseTemplate, BuilderItem } from "../../Builder/types";
import useValueRef from "../useValueRef";

type Layout = {
  buttonOk?: any;
  buttonCancel?: any;
  body: unknown;
}

type Item<T = unknown> = BuilderItem<T, 'layout', 'confirmBlock', Layout, BaseTemplate<'layout', 'confirmBlock', Layout>>;

const Renderer: Item['renderer'] = ({value, template, onChange, Fabric}) => {
  const [state, setState] = useState<unknown>(value);

  useEffect(() => {
    setState(value);
  }, [setState, value]);

  const stateRef = useValueRef(state);
  const valueRef = useValueRef(value);

  const onOk = useCallback(() => {
    onChange(stateRef.current);
  }, [onChange, stateRef]);

  const onCancel = useCallback(() => {
    onChange(valueRef.current);
  }, [onChange, valueRef]);

  return (
    <Space direction='vertical' style={{width: '100%'}}>
      <Fabric
        value={state}
        template={template.layout.body}
        onChange={setState}
        Fabric={Fabric}
      />
      <Space style={{paddingLeft: 'auto'}}>
        {template.layout.buttonOk && <Fabric
          value={state}
          template={template.layout.buttonOk}
          onChange={onOk}
          Fabric={Fabric}
        />}
        {template.layout.buttonCancel && <Fabric
          value={state}
          template={template.layout.buttonCancel}
          onChange={onCancel}
          Fabric={Fabric}
        />}
      </Space>
    </Space>
  );
}

const ConfirmBlock: Item = {
  type: 'layout',
  layout: 'confirmBlock',
  renderer: Renderer,
};

export default ConfirmBlock;
