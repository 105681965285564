function getAddItemButtonTemplate<T>(
  isLink: boolean,
  routeKey?: string,
  body?: T,
  createTitle?: string,
) {
  // Делаем кнопку создания
  const addTemplate = {
    type: 'layout',
    layout: {
      type: 'icon',
      icon: 'add',
      label: createTitle ?? 'Создать',
      buttonType: isLink ? 'link' : 'default',
      buttonSize: isLink ? 'small' : undefined,
    },
  } as const;

  if (routeKey) {
    // Делаем редирект на routeKey
    return {
      type: 'layout',
      layout: {
        type: 'routerConsumer',
        key: routeKey,
        content: addTemplate,
      },
    } as const;

  } else {
    // Оформляем как модал
    return {
      type: 'layout',
      layout: {
        type: 'dialog',
        button: addTemplate,
        header: createTitle ?? 'Создать',
        body,
      },
    } as const;
  }
}

export default getAddItemButtonTemplate;
