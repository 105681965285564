import LensComponentCreator from "../../../Base/LensComponentCreator";
import examples from "./examples";
import type { ComponentBase } from "../../../Base/types";
import { Example, getExample, getExampleLensStream, Value } from "./utils";
import { ArrayLike, toStream } from "../../../../Builder/array";
import { YamlTemplate } from "../../../../utils/yaml";

type Examples = ComponentBase<'Editor.Examples', Example<unknown>[], {}>;
type Previews = ComponentBase<'', ArrayLike<Value<Example<unknown>>>, {}>;

const ExamplesComponent = LensComponentCreator<Examples, Previews>(
  'Editor.Examples',
  () => YamlTemplate.toTemplate(`
type: array
layout:
  type: base
items:
  type: layout
  layout:
    type: preview
  `),
  value => {
    const stream = toStream(value ?? examples);
    return getExampleLensStream(stream);
  },
  (prevValue, newValue) => (newValue as Value<Example<unknown>>[]).map(getExample)
);

export default ExamplesComponent;
