import React, { useEffect, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { InputRef, Typography } from 'antd';
import { Space, Input, Tag, Tooltip, theme } from 'antd';

import { useStream, useUpdateEvent } from "../../Builder/array";
import type { ArrayItem } from './types';

type TagEditorItem = ArrayItem<'TagEditor', {}>;

const Renderer: TagEditorItem['renderer'] = ({value, onChange, template, Fabric}) => {
  const stream = useStream(value);
  const { token } = theme.useToken();
  const [tags, setTags] = useState<string[]>([]);

  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const inputRef = useRef<InputRef>(null);
  const editInputRef = useRef<InputRef>(null);
  const mr = useUpdateEvent(stream, ['create', 'delete', 'update'], 'TagEditor');

  const handleResult = (tags: unknown[] | null) => {
    if (!tags) {
      return;
    }
    setTags(tags as string[]);
    onChange(tags);
  };

  useEffect(() => {
    // Подгружаем теги
    (async () => {
      const result = await stream.getFrame();
      if (!result) {
        return null;
      }
      setTags(result as string[]);
    })();
  }, [mr, setTags, stream]);

  // Дальнейшее взято из примера https://ant.design/components/tag#examples

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  const handleClose = async (removedTag: string) => {
    const id = tags.findIndex(tag => tag === removedTag);
    if (id < 0) {
      return;
    }
    handleResult(await stream.delete(id, removedTag));
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = async () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      handleResult(await stream.create(inputValue));
    }
    setInputVisible(false);
    setInputValue('');
  };

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = async () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    handleResult(await stream.update(editInputIndex, editInputValue));
    setEditInputIndex(-1);
    setInputValue('');
  };

  const tagInputStyle: React.CSSProperties = {
    width: 78,
    verticalAlign: 'top',
  };

  const tagPlusStyle: React.CSSProperties = {
    background: token.colorBgContainer,
    borderStyle: 'dashed',
  };

  return (
    <Space size={10}>
      {template.title && <Typography.Text style={{width: '200px', display: 'inline-block'}}>{template.title}</Typography.Text>}
      <Space size={[0, 8]} wrap>
        <Space size={[0, 8]} wrap>
          {tags.map((tag, index) => {
            if (editInputIndex === index) {
              return (
                <Input
                  ref={editInputRef}
                  key={tag}
                  size="small"
                  style={tagInputStyle}
                  value={editInputValue}
                  onChange={handleEditInputChange}
                  onBlur={handleEditInputConfirm}
                  onPressEnter={handleEditInputConfirm}
                />
              );
            }
            const isLongTag = tag.length > 20;
            const tagElem = (
              <Tag
                key={tag}
                closable={true}
                style={{ userSelect: 'none' }}
                onClose={() => handleClose(tag)}
              >
                <span
                  onDoubleClick={(e) => {
                    setEditInputIndex(index);
                    setEditInputValue(tag);
                    e.preventDefault();
                  }}
                >
                  {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                </span>
              </Tag>
            );
            return isLongTag ? (
              <Tooltip title={tag} key={tag}>
                {tagElem}
              </Tooltip>
            ) : (
              tagElem
            );
          })}
        </Space>
        {inputVisible ? (
          <Input
            ref={inputRef}
            type="text"
            size="small"
            style={tagInputStyle}
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
          />
        ) : (
          <Tag style={tagPlusStyle} onClick={showInput}>
            <PlusOutlined />
          </Tag>
        )}
      </Space>
    </Space>
  );
};

const TagEditor: TagEditorItem = {
  type: 'array',
  layout: 'TagEditor',
  renderer: Renderer,
};

export default TagEditor;
