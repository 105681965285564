import { YamlTemplate } from "../../utils/yaml";

const templates = [
  {
    name: 'T1' as const,
    template: YamlTemplate.toYaml({
      'type': 'object',
      'layout': {
        'type': 'vertical',
      },
      'properties': {
        'name': {'type': 'string', title: 'Print someth', layout: {type: 'input'}},
        'merried': {'type': 'boolean', title: 'Merried', layout: {type: 'input'}},
      },
    }),
  },
  {
    name: 'T2' as const,
    template: YamlTemplate.toYaml({
      type: 'object',
      layout: {
        type: 'tabs',
        titles: ['One', 'Two'],
        order: ['one', 'two'],
      },
      properties: {
        one: {
          type: 'array',
          layout: {
            type: 'base',
          },
          items: {
            type: 'string',
            layout: {
              type: 'input',
            },
          },
        },
        two: {
          type: 'layout',
          layout: {
            type: 'button',
            buttonType: 'link',
            content: {
              type: 'string',
              layout: {
                type: 'label',
              },
            },
          },
        },
      }
    }),
  },
  {
    name: 'T3' as const,
    template: YamlTemplate.toYaml({
      type: 'array',
      layout: {
        type: 'editor',
        title: {
          type: 'object',
          layout: {
            type: 'vertical',
          },
          properties: {
            title: {
              type: 'string',
              layout: {
                type: 'label',
              },
            },
          }
        },
      },
      items: {
        type: 'object',
        layout: {
          type: 'vertical',
        },
        properties: {
          title: {
            type: 'string',
            layout: {
              type: 'input',
            }
          },
          description: {
            type: 'string',
            layout: {
              type: 'input',
            }
          }
        }
      },
    }),
  },
  {
    name: 'Refs' as const,
    template: `
type: object
layout:
  type: vertical
properties:
  one:
    type: string
    layout:
      type: input
  two:
    type: ref
    layout:
      type: stream
    ref: T1
    `.trim(),
  },
  {
    name: 'Пример представления' as const,
    position: ['Пример', 'Представление'],
    template: `
type: ref
ref: Пример формы для таблицы
layout:
  type: stream
    `.trim(),
  },
  {
    name: 'Пример представления таблицы' as const,
    template: `
type: object
layout:
  type: horizontal
properties:
  firstName:
    type: string
    layout:
      type: label
    `.trim(),
  },
  {
    name: 'Пример формы для таблицы' as const,
    template: `
type: object
layout:
  type: vertical
properties:
  firstName:
    type: string
    title: Имя
    layout:
      type: input
  lastName:
    type: string
    title: Фамилия
    layout:
      type: input
    `.trim(),
  },
];

const tables = [
  {
    name: 'Пример таблицы' as const,
    viewTemplate: 'Пример представления таблицы',
    editorTemplate: 'Пример формы для таблицы',
    stream: [
      {firstName: 'Артем', lastName: 'Иванов'},
      {firstName: 'Семен', lastName: 'Епачинцев'},
    ],
    template: `
type: object
properties:
  firstName:
    type: string
  lastName:
    type: string
    `.trim(),
  },
];

const webForms = [
  {
    name: 'Пример WEB-формы',
    table: 'Пример таблицы',
    template: `
type: object
layout:
  type: vertical
properties:
  firstNameTitle:
    type: string
    layout:
      type: label
      text: Введите ваше имя
  firstName:
    type: string
    layout:
      type: input
  lastNameTitle:
    type: string
    layout:
      type: label
      text: Введите вашу фамилию
  lastName:
    type: string
    layout:
      type: input
    `.trim(),
    route: 'form-example',
  }
]

const examples = {templates, tables, webForms};
export default examples;
