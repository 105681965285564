import Dialogs from "./Dialogs";
import EditorPageComponent from "./EditorPage";
import TokenAuthPageComponent from "./TokenAuthPage";
import WebFormPageComponent from "./WebFormPage";
import WebFormPageContentComponent from "./WebFormPage/WebFormPageContent";
import TemplatePageComponent from "./TemplatePage";
import TemplatePageContentComponent from "./TemplatePage/TemplatePageContent";
import AuthPageComponent from "./AuthPage";
import SignoutPageComponent from "./SignoutPage";
import UnconfirmPageComponent from "./UnconfirmPage";

export default function EditorComponents() {
  return Dialogs()
    .add(EditorPageComponent)
    .add(WebFormPageComponent)
    .add(WebFormPageContentComponent)
    .add(TokenAuthPageComponent)
    .add(TemplatePageComponent)
    .add(TemplatePageContentComponent)
    .add(AuthPageComponent)
    .add(SignoutPageComponent)
    .add(UnconfirmPageComponent);
}
