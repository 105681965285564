import React, {useCallback} from "react";
import { Checkbox, CheckboxProps, Space, Typography } from "antd";
import type { BuilderItem } from "../../Builder/types";

type Item = BuilderItem<boolean, 'boolean', 'input', {}>;
type OnChildChange = Exclude<CheckboxProps['onChange'], undefined>;

const Renderer: Item['renderer'] = ({value, template, onChange}) => {
  const onChildChange = useCallback<OnChildChange>(
    e => onChange(e.target.checked),
    [onChange]
  );

  return (
    <Space size={10} onClick={() => onChange(!value)} style={{cursor: 'pointer'}}>
      <Checkbox checked={value} onChange={onChildChange} />
      <Typography.Text>{template.title}</Typography.Text>
    </Space>
  );
}

const BooleanInput: Item = {
  type: 'boolean',
  layout: 'input',
  renderer: Renderer,
};

export default BooleanInput;
