import React, { CSSProperties, ReactNode, useCallback, useRef, useState } from "react";
import { Button, Empty, Input, InputProps, Space, Spin, theme, Typography } from "antd";
import { AudioOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  SpectrumVisualizer, SpectrumVisualizerTheme,
  WaveformVisualizer, WaveformVisualizerTheme,
} from "react-audio-visualizers";

import type { BuilderItem } from "../../Builder/types";
import AudioRecorder from "../../utils/AudioRecorder";

interface Layout {
  visualizer?: 'spectrum' | 'waveform';
  disabled?: boolean;
  placeholder?: string;
}

type Item = BuilderItem<string, 'string', 'AudioRec', Layout>;
type OnInputChange = Exclude<InputProps['onChange'], undefined>;

const styles: {[key in (
  | 'icon'
  | 'content'
)]: CSSProperties} = {
  icon: {
    width: '10px',
    height: '10px',
  },
  content: {
    width: '100%',
    display: 'flex',
    gap: '5px',
  },
}

const Renderer: Item['renderer'] = ({value, template, onChange, Fabric}) => {
  // AudioRecorder instance
  const audioRecorder = useRef<AudioRecorder | null>(null);
  // Styles for visualizer colors
  const token = theme.useToken();

  // Singleton style
  if (!audioRecorder.current) {
    audioRecorder.current = new AudioRecorder();
  }

  // State proxy for rerender calls
  const [state, setState] = useState(audioRecorder.current.state);

  // actions => controller state
  if (audioRecorder.current.state === 'saved' && state === 'ready') {
    audioRecorder.current.state = state;
  }

  // Store props to controller for rerender
  audioRecorder.current.hold(value, onChange);

  const updateState = useCallback(async(newState: typeof state) => {
    if (!audioRecorder.current) {
      return;
    }
    if (newState === 'recording') {
      await audioRecorder.current.connect();
      audioRecorder.current.start();
    }
    if (newState === 'uploading') {
      audioRecorder.current.stop();
    }
    setState(audioRecorder.current.state);
  }, [audioRecorder]);

  const start = useCallback(() => updateState('recording'), [updateState]);
  const stop = useCallback(() => updateState('uploading'), [updateState]);

  const remove = useCallback(() => {
    onChange('');
    setState('ready');
  }, [onChange]);

  const onInputChange = useCallback<OnInputChange>(e => {
    onChange(e.target.value);
  }, [onChange]);

  let content: ReactNode;
  const currentState = audioRecorder.current.state;

  if (currentState === 'ready') {
    content = (
      <Input
        disabled={template.layout.disabled}
        placeholder={template.layout.placeholder}
        value={value}
        onChange={onInputChange}
        addonAfter={
          <Button
            shape="round"
            size="small"
            icon={<AudioOutlined />}
            type="text"
            onMouseDown={start}
          />
        }
      />
    );
  }

  if (currentState === 'recording' || currentState === 'uploading') {
    content = (
      <Input
        disabled
        placeholder={template.layout.placeholder}
        value={value}
        onChange={onInputChange}
        addonAfter={
          <Button
            shape="round"
            size="small"
            icon={currentState === 'recording'
              ? <img alt="Recording" className="blink-record" src="/record.svg" />
              : <Spin size="small" />
            }
            type="text"
            onClick={stop}
          />
        }
      />
    );
  }

  if (currentState === 'saved' && audioRecorder.current.value) {
    let visualizer: ReactNode;

    if (template.layout.visualizer === 'waveform') {
      visualizer = (
        <WaveformVisualizer
          audio={audioRecorder.current.value}
          theme={WaveformVisualizerTheme.squaredBars}
          colors={[token.token["blue-9"], token.token["blue-9"]]}
          iconsColor={token.token["blue-9"]}
          backgroundColor="transparent"
          showMainActionIcon
          showLoaderIcon
          refreshRate={40}
        />
      );

    } else {
      visualizer = (
        <SpectrumVisualizer
          audio={audioRecorder.current.value}
          theme={SpectrumVisualizerTheme.roundBars}
          colors={[token.token["blue-9"], token.token["blue-9"]]}
          iconsColor={token.token["blue-9"]}
          backgroundColor="transparent"
          showMainActionIcon
          showLoaderIcon
        />
      );
    }

    content = (<>
      <div className="audio-visualizer">
        {visualizer}
      </div>
      <Button
        shape="round"
        icon={<DeleteOutlined />}
        type="text"
        onClick={remove}
      />
    </>);
  }

  const component = (
    <div style={styles.content}>
      {content ?? <Empty />}
    </div>
  );

  if (!template.title) {
    return component;
  }

  return (
    <Space direction="vertical" className="w-full" size={4}>
      <Typography.Text strong>{template.title}</Typography.Text>
      {component}
    </Space>
  );
}

const AudioRec: Item = {
  type: 'string',
  layout: 'AudioRec',
  renderer: Renderer,
};

export default AudioRec;
