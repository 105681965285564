import getGutter from "../../../utils/getGutter";
import type { BriefTemplate, BaseFieldTemplate, TableTemplate, ViewLayout } from "./types";

const TYPES_MAP = {
  string: {
    edit: {
      type: 'string',
      layout: 'input',
    },
    read: {
      type: 'string',
      layout: 'label',
    },
  },
  voiceString: {
    edit: {
      type: 'string',
      layout: 'AudioRec',
    },
    read: {
      type: 'string',
      layout: 'AudioRec',
    },
  },
  enum: {
    edit: {
      type: 'layout',
      layout: 'enum',
    },
    read: {
      type: 'string',
      layout: 'label',
    },
  },
  collapse: {
    edit: {
      type: 'layout',
      layout: 'Collapse',
    },
    read: {
      type: 'layout',
      layout: 'Collapse',
    },
  },
  photo: {
    edit: {
      type: 'layout',
      layout: 'Photo',
    },
    read: {
      type: 'layout',
      layout: 'Photo',
    },
  },
  bool: {
    edit: {
      type: 'boolean',
      layout: 'input',
    },
    read: {
      type: 'boolean',
      layout: 'input',
    },
  }
} as const;

function templateFromBaseFieldTemplate<T extends keyof typeof TYPES_MAP>(
  fieldTemplate: BaseFieldTemplate<T>,
  layout: ViewLayout,
) {
  if (layout.mode === 'list') {
    return null;
  }

  const types = TYPES_MAP[fieldTemplate.type]?.[layout.mode];

  if (!types) {
    return null;
  }

  const titleInfo = layout.direction === 'vertical' ? {
    title: fieldTemplate.title,
    description: fieldTemplate.description,
  } as const : {};

  return {
    type: types.type,
    ...titleInfo,
    layout: {
      type: types.layout,
    }
  } as const;
}

function getErrorTemplate<T>(field: T, error: string) {
  return {
    field,
    template: {
      type: 'string',
      layout: {
        type: 'label',
        italic: true,
        text: `${error}: ${field}`,
      },
    },
  } as const;
}

export function joinTemplates(
  briefTemplate: BriefTemplate,
  tableTemplate: TableTemplate['properties'],
  layout: ViewLayout,
) {
  if (Object.keys(briefTemplate).find(field => field === '_*')) {
    // Подгружаем один слой полей автоматически
    const fullBriefTemplate: BriefTemplate = {};

    Object.keys(tableTemplate).forEach(field => {
      fullBriefTemplate[field] = null;
    });

    const result = joinTemplates(
      fullBriefTemplate,
      tableTemplate,
      layout,
      // Тип возврата функции не может содержать себя же (type A = ... | A), поэтому нужно исключить этот вариант
    ) as never;

    return result;
  }

  const result = Object.keys(briefTemplate).map(field => {
    const nestedBriefTemplate = briefTemplate[field];

    if (field.startsWith('_')) {
      if (field === '_title') {
        // Не отображаем
        return {field, template: null} as const;
      }

      if (field.startsWith('_break')) {
        return {
          field,
          template: {
            type: 'layout',
            layout: {
              type: 'delim',
            },
          },
        } as const;
      }

      if (field.startsWith('_collapse') && nestedBriefTemplate) {
        const nestedObjectTemplate = joinTemplates(
          nestedBriefTemplate,
          tableTemplate,
          {
            mode: layout.mode,
            direction: 'vertical',
          }
        );

        return {template: {
          type: 'layout',
          layout: {
            type: 'Collapse',
            header: {
              type: 'string',
              layout: {
                type: 'label',
                // TODO: исправить
                text: (briefTemplate[field] as unknown as {_title: string})._title,
              },
            },
            content: nestedObjectTemplate,
          }
        }, field} as const;
      }

      return getErrorTemplate(field, 'Неизвестное системное поле');
    }

    const nestedTableTemplate = tableTemplate[field] ?? {};
    const baseTemplate = templateFromBaseFieldTemplate(nestedTableTemplate, layout);

    if (!baseTemplate) {
      return getErrorTemplate(field, 'Неизвестный формат поля');
    }

    const template = nestedBriefTemplate ? {
      ...baseTemplate,
      layout: {
        ...baseTemplate.layout,
        ...nestedBriefTemplate,
      }
    } as const : baseTemplate;

    switch (nestedTableTemplate.type) {
      case 'enum': {
        return {template: {
          ...template,
          layout: {
            ...template.layout,
            keys: nestedTableTemplate.source,
            content: {
              type: 'layout',
              title: nestedTableTemplate.title,
              description: nestedTableTemplate.description,
              layout: {
                type: 'selector',
                showSpin: false,
                search: 'key',
              },
            }
          }
        }, field} as const;
      }

      case 'collapse': {
        if (!nestedBriefTemplate) {
          console.warn('Укажите поля внутри коллапса');
          break;
        }

        const nestedObjectTemplate = joinTemplates(
          nestedBriefTemplate,
          nestedTableTemplate.properties,
          {
            mode: layout.mode,
            direction: 'vertical',
          }
        );

        return {template: {
          ...template,
          layout: {
            ...template.layout,
            header: {
              type: 'string',
              layout: {
                type: 'label',
                text: nestedTableTemplate.title,
              },
            },
            content: nestedObjectTemplate,
          }
        }, field} as const;
      }

      default: {
        break;
      }
    }

    return {
      template,
      field,
    } as const;
  });

  const resultTemplate: {
    [field: (typeof result)[number]['field']]: (typeof result)[number]['template'];
  } = {};

  result.forEach(
    ({field, template}) => resultTemplate[field] = template
  );

  return {
    type: 'object',
    layout: {
      type: layout.direction,
    },
    properties: resultTemplate,
  } as const;
}

export function getListTemplate(
  briefTemplate: BriefTemplate,
  tableTemplate: TableTemplate,
  tableName: string,
  aliases: {edit?: string | null, read?: string},
) {
  if (Object.keys(briefTemplate).find(field => field === '_*')) {
    // Подгружаем один слой полей автоматически
    const fullBriefTemplate: BriefTemplate = {};

    Object.keys(tableTemplate.properties).forEach(field => {
      fullBriefTemplate[field] = null;
    });

    const result = getListTemplate(
      fullBriefTemplate,
      tableTemplate,
      tableName,
      aliases,
      // Тип возврата функции не может содержать себя же (type A = ... | A), поэтому нужно исключить этот вариант
    ) as never;

    return result;
  }

  const {edit, read} = aliases;

  const lineHTemplate = joinTemplates(
    briefTemplate,
    tableTemplate.properties,
    {
      mode: 'read',
      direction: 'horizontal',
    }
  );

  const gutter = getGutter(Object.keys(briefTemplate));

  const lineTemplate = {
    ...lineHTemplate,
    layout: {
      ...lineHTemplate.layout,
      type: 'horizontalGrid',
      gutter,
    },
  } as const;

  const readTemplate = read ? {
    type: 'ref',
    ref: read,
    layout: {
      type: 'stream',
    },
  } as const : joinTemplates(
    briefTemplate,
    tableTemplate.properties,
    {
      mode: 'read',
      direction: 'vertical',
    }
  );

  const editTemplate = edit ? {
    type: 'ref',
    ref: edit,
    layout: {
      type: 'stream',
    },
  } as const : joinTemplates(
    briefTemplate,
    tableTemplate.properties,
    {
      mode: 'edit',
      direction: 'vertical',
    }
  );

  const titleTemplate = {
    type: 'layout',
    layout: {
      type: 'dialog',
      button: {
        type: 'layout',
        layout: {
          type: 'button',
          buttonType: 'none',
          width: '100%',
          content: lineTemplate,
        },
      },
      header: 'Посмотреть',
      body: readTemplate,
    },
  } as const;

  const cols = Object.keys(tableTemplate.properties);

  const arrayTemplate = {
    type: 'array',
    items: editTemplate,
    layout: {
      type: 'view',
      title: tableName,
      createTitle: edit === undefined ? undefined : 'Создать',
      row: titleTemplate,
      keys: cols,
      titles: cols.map(
        field => tableTemplate.properties[field].title ?? field
      ),
    },
  } as const;

  return {
    type: 'ref',
    ref: 'Table',
    layout: {
      type: 'native',
      table: tableTemplate.name,
      template: {
        type: 'object',
        layout: {
          type: 'horizontal',
        },
        properties: {
          stream: arrayTemplate,
        },
      },
    },
  } as const;
}
