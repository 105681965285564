import React, { useCallback, useState } from 'react';
import { Layout, Result, Space } from 'antd';

import { useStream } from '../../../Builder/array';
import Footer from '../Native/Footer';
import { YamlTemplate } from '../../../utils/yaml';
import type { ComponentBase } from '../../Base/types';
import type { ChildValue } from '../../TableRef/types';

type WebFormPageContent = ComponentBase<
  'WebFormPage.Content',
  ChildValue<unknown>,
  {template: unknown}
>;

const { Content, Footer: AntFooter } = Layout;

// Шаблон футера
const footerTemplate = YamlTemplate.toTemplate(`
type: ref
ref: NativeReactRenderer
layout:
  type: native
`);

const Renderer: WebFormPageContent['renderer'] = ({value, template, onChange: onParentChange, Fabric}) => {
  // Используем CRUD пользовательской таблицы
  const stream = useStream(value.stream);
  const [created, setCreated] = useState(false);

  // Обрабатываем добавление формы
  const onChange = useCallback(async (newValue: unknown) => {
    // Сохраняем данные в CRUD
    await stream.create(newValue);
    // Помечаем форму как готовую
    setCreated(true);
    // Отсылаем сообщение выше
    onParentChange({value: newValue, stream});
  }, [stream, onParentChange]);

  return (
    <Layout style={{height: '100%', padding: '12px 24px 24px'}}>
      <Content
        style={{
          padding: 0,
          margin: 0,
          overflow: 'auto',
        }}
      >
        <Space size={10} direction="vertical" style={{
          width: '100%',
        }}>
          {created ? (
            <Result
              status="success"
              title="Данные формы отправлены"
            />
          ) : (
            <Fabric
              // TODO: подгрузить последние данные в форму
              value={null}
              onChange={onChange}
              Fabric={Fabric}
              // Используем переданный шаблон
              template={template.layout.template}
            />
          )}
        </Space>
      </Content>
      <AntFooter style={{ padding: '12px 0 0 0' }}>
        <Fabric
          value={{renderer: Footer}}
          template={footerTemplate}
          onChange={() => void 0}
          Fabric={Fabric}
        />
      </AntFooter>
    </Layout>
    
    
  );
}

const WebFormPageContentComponent: WebFormPageContent = {
  name: 'WebFormPage.Content',
  renderer: Renderer,
};

export default WebFormPageContentComponent;
