import React, { useCallback } from "react";
import { useRouterContext } from "./RouterContext";
import useValueRef from "../../useValueRef";
import type {BaseTemplate, BuilderItem } from "../../../Builder/types";
import { Typography } from "antd";

type Layout = {
  content: unknown;
  key: string;
}

type Item = BuilderItem<unknown, 'layout', 'routerConsumer', Layout, BaseTemplate<'layout', 'routerConsumer', Layout>>;

const Renderer: Item['renderer'] = ({value, template, onChange, Fabric}) => {
  const routeFunction = useRouterContext();
  const valueRef = useValueRef(value);

  const childOnChange = useCallback(async (buttonValue: unknown) => {
    if (!routeFunction) {
      return;
    }

    const result = await routeFunction(template.layout.key, valueRef.current, true);

    onChange(result);
  }, [routeFunction, template.layout.key, valueRef, onChange]);

  const content = (
    <Fabric
      value={value}
      template={template.layout.content}
      onChange={childOnChange}
      Fabric={Fabric}
    />
  );

  if (routeFunction) {
    return content;
  }

  return <Typography.Text >Not connected {template.layout.key} to router</Typography.Text>
}

const RouterConsumer: Item = {
  type: 'layout',
  layout: 'routerConsumer',
  renderer: Renderer,
};

export default RouterConsumer;
