import Yaml from 'yaml';

type AnyTemplate = any;

export const YamlTemplate = {
  toYaml(template: AnyTemplate) {
    return Yaml.stringify(template);
  },
  toTemplate(yaml: string): AnyTemplate {
    try {
      return Yaml.parse(yaml);
    } catch (e) {
      // console.error(e);
      return {} as AnyTemplate;
    }
  },
};
