import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Spin } from "antd";
import { ArrayLike, useStream } from "../../Builder/array";
import useValueRef from "../../Elements/useValueRef";
import type { ChildValue, StreamTable, TableComponent } from "./types";
import type { Query } from "../../Builder/types";

function TableComponentFabric<TN, V>(tablesStream?: ArrayLike<StreamTable<TN, V>>) {
  const TableComponentRenderer: TableComponent<TN>['renderer'] = ({value, template, onChange, Fabric}) => {
    const streams = useStream(tablesStream);
    const [stream, setStream] = useState<ArrayLike<V> | null>(null);
    const valueRef = useValueRef(value);

    useEffect(() => {
      (async () => {
        let query: Query<StreamTable<TN, V>> | undefined;

        if (template.layout.tableId) {
          query = {filters: {id: {eq: template.layout.tableId}}};
        } else {
          query = {filters: {alias: {eq: template.layout.table}}};
        }

        if ((template.layout.table as unknown as string) === '_') {
          // Нужна таблица с таблицами
          setStream(streams as unknown as ArrayLike<V>);
          return;
        }

        const tables = await streams.find(query);

        if (!tables?.length) {
          console.warn('No table found', query.filters);
          return;
        }

        if (tables.length > 1) {
          console.warn('Many tables found', query.filters);
          return;
        }

        setStream(tables[0].stream ?? []);
      })();
    }, [streams, setStream, template.layout.table, template.layout.tableId]);

    const onChildChange = useCallback((newValue: ChildValue<unknown>) => {
      if (valueRef.current !== newValue.value) {
        onChange(newValue.value);
      }

      // NOTE: нам нужно здесь апдейтить стрим?
      // Полный объект таблицы нужен только для этого, так что пока не апдейтится
    }, [onChange, valueRef]);

    const childValue = useMemo<ChildValue<V> | null>(
      () => stream ? ({stream, value}) : null,
      [stream, value]
    );

    if (!childValue) {
      return <Spin />;
    }

    return (
      <Fabric
        value={childValue}
        template={template.layout.template}
        onChange={onChildChange}
        Fabric={Fabric}
      />
    );
  };

  const item: TableComponent<TN> = {
    name: 'Table',
    renderer: TableComponentRenderer,
  };

  return item;
}

export default TableComponentFabric;
