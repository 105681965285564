import { Menu as AntMenu, MenuProps } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { useCallback, useMemo } from "react";
import type { ComponentBase } from "../Base/types";

export interface MenuItem {
  key: string;
  isGroup?: boolean;
  isShown?: boolean;
  label?: string;
  children?: MenuItem[];
}

interface Template {
}

interface Value {
  items?: MenuItem[];
  selected?: string;
}

type Menu = ComponentBase<'Menu', Value, Template>;

function getMenuItem(item?: MenuItem): ItemType | null {
  if (!item || !(item.isShown ?? true)) {
    return null;
  }

  return {
    type: item.isGroup ? 'group' : undefined,
    key: item.key,
    label: item.label ?? item.key,
    children: item.children ? item.children.map(getMenuItem).filter(mi => mi !== null) : undefined,
  };
}

const Renderer: Menu['renderer'] = ({value, template, onChange, Fabric}) => {
  const items = useMemo(
    () => value?.items?.map(getMenuItem).filter(mi => mi !== null),
    [value?.items]
  );

  const selectedKeys = useMemo(
    () => value?.selected ? [value.selected] : undefined,
    [value?.selected]
  );

  const onClick = useCallback<
    Exclude<MenuProps['onClick'], undefined>
  >(
    e => onChange({selected: e.key}),
    [onChange]
  );

  return (
    <AntMenu
      inlineIndent={8}
      onClick={onClick}
      defaultSelectedKeys={selectedKeys}
      style={{borderRight: '0'}}
      mode='inline'
      items={items}
    />
  );
}

const MenuComponent: Menu = {
  name: 'Menu',
  renderer: Renderer,
};

export default MenuComponent;
