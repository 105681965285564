import React, { CSSProperties, FC, useCallback, useMemo } from "react";
import { Avatar, Button, Divider, Grid, Image, Popover, Space, Spin, Switch, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import type { User } from "../types";
import { NativeReactRenderer } from "../../Base/NativeReactRenderer/types";

export interface UserBlockProps {
  user: User | null;
  isDark: boolean;
  setDark: (isDark: boolean) => void;
}

const styles: {[key in (
  | 'userBlock'
  | 'popContent'
  | 'popContentFooter'
  | 'switchWidth'
  | 'switchImage'
  | 'divider'
  | 'ellipsisName'
)]: CSSProperties} = {
  userBlock: {
    margin: '15px 0 5px 15px',
    width: 'calc(100% - 15px)',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    gap: '10px',
  },
  popContent: {
    minWidth: '300px',
  },
  popContentFooter: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  switchWidth: {
    width: '53px',
  },
  switchImage: {
    marginBottom: '3px',
  },
  divider: {
    marginTop: '10px',
    marginBottom: '13px',
  },
  ellipsisName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100%',
    display: 'inline-block',
  },
}

const Switcher: FC<Omit<UserBlockProps, 'user'>> = ({isDark, setDark}) => {
  const checkedChildren = useMemo(() => (
    <Image src="/moon.svg" height={20} style={styles.switchImage} />
  ), []);

  const unCheckedChildren = useMemo(() => (
    <Image src="/sun.svg" height={20} style={styles.switchImage} />
  ), []);

  return (
    <Switch
      style={styles.switchWidth}
      checked={isDark}
      unCheckedChildren={unCheckedChildren}
      checkedChildren={checkedChildren}
      onChange={setDark}
    />
  );
}

const PopoverContent: FC<UserBlockProps> = ({user, isDark, setDark}) => {
  const navigate = useNavigate();
  const isAuth = !!user;
  
  const logout = useCallback(
    () => navigate(isAuth ? '/signout' : '/signin'),
    [navigate, isAuth]
  );

  return (
    <Space direction="vertical" size={0} style={styles.popContent}>
      <Typography.Title level={4}>{user?.name ?? 'Гость'}</Typography.Title>
      {user?.username ? (
        <Typography.Text>{user.username}</Typography.Text>
      ) : null}

      <Divider style={styles.divider} />

      <div style={styles.popContentFooter}>
        <Button type="link" size="small" onClick={logout}>{
          isAuth ? 'Выйти' : 'Войти'
        }</Button>
        <Switcher isDark={isDark} setDark={setDark} />
      </div>
    </Space>
  );
}

const UserBlock: NativeReactRenderer<UserBlockProps> = ({value: {props}}) => {
  const {user, isDark, setDark} = props ?? {user: null};
  const br = Grid.useBreakpoint();
  const userName = user?.name ?? '';

  return (
    <Popover
      placement="bottomLeft"
      content={<PopoverContent
        user={user}
        isDark={isDark}
        setDark={setDark}
      />}
    >
      <div style={styles.userBlock}>
        <Avatar shape="square" size="small">{userName.slice(0, 1)}</Avatar>
        {br.md && <Typography.Text style={styles.ellipsisName}>
          {user ? userName : <Spin />}
        </Typography.Text>}
      </div>
    </Popover>
  );
}

export default UserBlock;
