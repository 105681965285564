import React, { useMemo } from "react";
import { Empty, Tabs } from "antd";
import useObjectAsList, { ObjectItem } from "./useObjectAsList";

type Layout = {titles?: string[]};

const Renderer: ObjectItem<'tabs', Layout>['renderer'] = ({value, template, onChange, Fabric}) => {
  const properties = useObjectAsList(value, onChange, template.properties, template.layout);
  const items = useMemo(() => properties.map((property, i) => {
    const title = (template.layout.titles ?? [])[i] ?? null;

    return {
      label: title ?? property.name,
      key: property.name,
      children: (
        <Fabric
          key={property.name}
          value={property.value}
          template={property.template}
          onChange={property.onChange}
          Fabric={Fabric}
        />
      ),
    };
  }), [properties, template.layout.titles, Fabric]);

  if (properties.length === 0) {
    return <Empty />;
  }

  return (
    <Tabs defaultActiveKey={properties[0].name} items={items} />
  );
}

const TabsObject: ObjectItem<'tabs', Layout> = {
  type: 'object',
  layout: 'tabs',
  renderer: Renderer,
};

export default TabsObject;
