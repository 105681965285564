import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Layout, Space } from 'antd';

import { LensStream, useStream, useUpdateEvent } from '../../../Builder/array';
import Footer from '../Native/Footer';
import { YamlTemplate } from '../../../utils/yaml';
import type { ComponentBase } from '../../Base/types';
import type { ChildValue } from '../../TableRef/types';
import type { Entity } from '../../../utils/types';

type TemplatePageContent = ComponentBase<
  'TemplatePage.Content',
  ChildValue<Entity>,
  {template: unknown, templateRoute: string, tableId: string, itemId?: string}
>;

const { Content, Footer: AntFooter } = Layout;

// Шаблон футера
const footerTemplate = YamlTemplate.toTemplate(`
type: ref
ref: NativeReactRenderer
layout:
  type: native
`);

// Шаблон списка
const listTemplate = YamlTemplate.toTemplate(`
type: array
layout:
  type: base
items:
  type: object
  layout:
    type: horizontal
  properties:
    linkToItem:
      type: string
      layout:
        type: link
`);

const Renderer: TemplatePageContent['renderer'] = ({value, template, onChange: onParentChange, Fabric}) => {
  // Используем CRUD пользовательской таблицы
  const stream = useStream(value.stream);
  const [item, setItem] = useState<Entity | null>(null);
  const mr = useUpdateEvent(stream, ['update'], 'TemplatePageContent');

  const {templateRoute, tableId, itemId} = template.layout;

  const streamList = useMemo(() => new LensStream(
    stream,
    v => v.origin,
    (v: Entity) => ({
      origin: v,
      linkToItem: `/t/${templateRoute}/${tableId}/${v.id}`,
    }),
  ), [templateRoute, tableId, stream]);

  useEffect(() => {
    // Загружаем item
    (async () => {
      if (!itemId) {
        return;
      }

      const result = await stream.find({filters: {id: {eq: itemId}}});

      if (!result || result.length < 1) {
        return;
      }

      setItem(result[0]);
    })();
  }, [mr, stream, itemId]);

  const onChange = useCallback(async (newValue: unknown) => {
    // Отсылаем сообщение выше
    onParentChange({value: newValue, stream});
  }, [stream, onParentChange]);

  return (
    <Layout style={{height: '100%', padding: '12px 24px 24px'}}>
      <Content
        style={{
          padding: 0,
          margin: 0,
          overflow: 'auto',
        }}
      >
        <Space size={10} direction="vertical" style={{
          width: '100%',
        }}>
          <Fabric
            value={itemId ? item : streamList}
            onChange={onChange}
            Fabric={Fabric}
            // Используем переданный шаблон
            template={itemId ? template.layout.template : listTemplate}
          />
        </Space>
      </Content>
      <AntFooter style={{ padding: '12px 0 0 0' }}>
        <Fabric
          value={{renderer: Footer}}
          template={footerTemplate}
          onChange={() => void 0}
          Fabric={Fabric}
        />
      </AntFooter>
    </Layout>
    
    
  );
}

const TemplatePageContentComponent: TemplatePageContent = {
  name: 'TemplatePage.Content',
  renderer: Renderer,
};

export default TemplatePageContentComponent;
