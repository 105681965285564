const examples = [
  {
    name: 'Строка ввода',
    template: `
type: string # Тип данных -- строка
layout:
  type: input # Представление -- поле ввода
    `.trim(),
    value: '',
    description: 'Строка ввода',
  } as const,
  {
    name: 'Вывод текста',
    template: `
type: string # Тип данных -- строка
layout:
  type: label # Представление -- обычный текст
    `.trim(),
    value: 'Some text',
    description: 'Вывод текстовых данных',
  } as const,
  {
    name: 'Два элемента',
    template: `
type: object # Тип данных -- набор полей
layout:
  type: vertical # Вертикальное расположение полей
  # type: horizontal # Горизонтальное расположение полей
properties: # Поля
  first:
    type: string # Тип данных поля
    layout:
      type: label # Обычный текст
  second:
    type: string # Тип данных поля
    layout:
      type: input # Поле ввода
    `.trim(),
    value: {
      first: 'Обычный текст',
      second: 'Поле ввода',
    },
    description: 'Объединение полей',
  } as const,
  {
    name: 'Флаг',
    template: `
type: boolean # Тип данных -- true / false
title: Галочка # Надпись рядом с переключателем
layout:
  type: input # Представление -- поле ввода
  # kind: switch # Переключатель
    `.trim(),
    value: true,
    description: 'Флаг',
  } as const,
  {
    name: 'Селектор по таблице',
    template: `
type: ref
ref: Table # Подгрузка из таблицы
layout:
  type: native
  table: Пример таблицы
  template:
    type: layout
    layout:
      type: selector
      search: firstName
    `.trim(),
    value: null,
    description: 'Выбор одного варианта из другой таблицы',
  },
  {
    name: 'Вывод списка',
    template: `
type: array # Используем массив, ...
items: # ... представляя элементы массива как ...
  type: object # ... набор полей ...
  layout:
    type: horizontal # ... с горизонтальным расположением, а именно: ...
  properties:
    firstName: # ... имя ...
      type: string # ... как строку ...
      layout:
        type: label # ... текста, и ...
    lastName: # ... фамилию ...
      type: string # ... как поле ввода ...
      layout:
        type: input # ... текста
layout:
  type: base # Выводим как обычный список
    `.trim(),
    value: [
      {firstName: 'Артем', lastName: 'Иванов'},
      {firstName: 'Семен', lastName: 'Епачинцев'},
    ],
    description: `
Использование массива,
представляя элементы массива как набор полей с горизонтальным расположением,
а именно: имя как строку текста, и фамилию как поле ввода текста
    `.trim(),
  } as const,
  {
    name: 'Редактирование списка',
    template: `
type: array # Используем массив, ...
items: # ... редактируя элементы массива как ...
  type: object # ... набор полей ...
  layout:
    type: vertical # ... с горизонтальным расположением, а именно: ...
  properties:
    firstName: # ... имя ...
      type: string # ... как строку ...
      layout:
        type: input # ... текста, и ...
    lastName: # ... фамилию ...
      type: string # ... как поле ввода ...
      layout:
        type: input # ... текста
layout:
  type: editor # Выводим как редактируемый список
  title: # Как выглядит строчка
    type: object # Набор полей ...
    layout:
      type: horizontal # ... с горизонтальным расположением, а именно: ...
    properties:
      firstName: # ... имя ...
        type: string # ... как строку ...
        layout:
          type: label # ... текста, и ...
      lastName: # ... фамилию ...
        type: string # ... как поле ввода ...
        layout:
          type: label # ... текста
    `.trim(),
    value: [
      {firstName: 'Артем', lastName: 'Иванов'},
      {firstName: 'Семен', lastName: 'Епачинцев'},
    ],
    description: `
Использование массива,
представляя элементы массива как набор полей с горизонтальным расположением,
а именно: имя как строку текста, и фамилию как поле ввода текста
    `.trim(),
  } as const,
  {
    name: 'Вывод таблицы данных',
    template: `
type: ref # Подгрузка данных ...
ref: Table # ... из таблицы ...
layout:
  table: Пример таблицы # ... с именем "Пример таблицы"
  type: native # ( Подгрузка данных -- системный элемент )
  template: # Представление подгруженных данных
    type: object
    layout:
      type: vertical
    properties:
      stream: # Результат подгрузки -- поле "stream"
        type: array # Работаем как со списком
        items:
          type: object
          layout:
            type: horizontal
          properties:
            firstName:
              type: string
              layout:
                type: label
            lastName:
              type: string
              layout:
                type: input
        layout:
          type: base
    `.trim(),
    value: null,
    description: `
Подгрузка данных из таблицы с именем "Пример таблицы" в виде массива,
представляя элементы массива как набор полей с горизонтальным расположением,
а именно: имя как строку текста, и фамилию как поле ввода текста
    `,
  } as const,
  {
    name: 'Сводная таблица',
    template: `
type: ref # Подгрузка данных из таблицы
ref: Table
layout:
  table: Пример таблицы
  type: native
  template:
    type: object
    layout:
      type: vertical
    properties:
      stream: # Результат подгрузки -- поле "stream"
        type: ref
        ref: PivotTable # Сводная таблица
        layout:
          type: native
    `.trim(),
    value: null,
    description: `
Подгрузка данных из таблицы с именем "Пример таблицы" в сводную таблицу
    `,
  } as const,
  {
    name: 'Редактор тегов (универсальный)',
    template: `
type: array
layout:
  type: TagEditor
    `.trim(),
    value: ['Tag1', 'Tag2', 'Tag3'],
    description: `
Редактирование тегов как массива строк
    `,
  } as const,
  {
    name: 'Сворачиваемый блок',
    template: `
type: layout
layout:
  type: Collapse # layout/Collapse
  header:
    type: string
    layout:
      type: label
      text: Заголовок блока
  content:
    type: string
    layout:
      type: label
      text: Содержимое блока
    `.trim(),
    value: null,
    description: `
Обертка, представляющая собой сворачиваемый блок
    `,
  } as const,
  {
    name: 'Аудио-сообщение',
    template: `
type: string
layout:
  type: AudioRec
    `.trim(),
    description: `
Запись аудио
    `
  } as const,
  {
    name: 'Enum',
    template: `
type: layout
layout:
  type: enum
  keys:
    - Очередь
    - Аналитика
    - В разработке
    - Тестирование
    - Завершена
  content:
    type: layout
    title: Выберите статус задачи
    layout:
      type: selector
      showSpin: false
      search: key
    `.trim(),
    description: `
Выбор из фиксированного набора вариантов
    `,
  } as const,
  {
    name: 'Photo',
    template: `
type: layout
layout:
  type: Photo
    `.trim(),
    description: `
Фотографирование
    `,
  } as const,
  {
    name: 'Date',
    template: `
type: string
layout:
  type: datePicker
    `.trim(),
    description: `
Выбор даты
    `,
    value: '01/04/2023',
  } as const,
  {
    name: 'Date',
    template: `
type: object
layout:
  type: horizontalGrid
  gutter: [8, 16]
properties:
  firstField: 
    type: string
    layout:
      type: label
  secondField: 
    type: string
    layout:
      type: label
    `.trim(),
    description: `
табличный вид
    `,
    value: {
      secondField: 'Строка 1',
      firstField: 'Строка 2'
    },
  } as const,
];

export default examples;
