import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { tests } from './utils/bracketParser';
import LogRocket from 'logrocket';

LogRocket.init('8bmmo4/lc-find-bug');

// TODO: убрать тесты в отдельную стадию сброки
tests();

function getRoot() {
  const root = document.getElementById('root')!;
  root.style.height = '100%';
  return root;
}

const root = ReactDOM.createRoot(getRoot());

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
