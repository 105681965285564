import React, { CSSProperties, useMemo } from "react";
import useObjectAsList, { ObjectItem } from "./useObjectAsList";

interface Layout {
  widths: string[],
}

type HorizontalFixedObject = ObjectItem<'horizontalFixed', Layout>;

const contentStyles: CSSProperties = {
  display: 'flex',
  gap: '10px',
}

const Renderer: HorizontalFixedObject['renderer'] = ({value, template, onChange, Fabric}) => {
  const properties = useObjectAsList(value, onChange, template.properties, template.layout);

  const widths = useMemo(
    () => template.layout.widths?.map(
      width => width ? ({width}) : undefined
    ) ?? [],
    [template.layout.widths]
  );

  return (
    <div className="w-full" style={contentStyles}>
      {properties.map((property, id) => (
        <div
          style={widths[id]}
          key={property.name}
        >
          <Fabric
            value={property.value}
            template={property.template}
            onChange={property.onChange}
            Fabric={Fabric}
          />
        </div>
      ))}
    </div>
  )
}

const HorizontalFixed: HorizontalFixedObject = {
  type: 'object',
  layout: 'horizontalFixed',
  renderer: Renderer,
};

export default HorizontalFixed;
