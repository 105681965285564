import { useMemo } from "react";
import { Grid } from "antd";

import useValueRef from "../useValueRef";
import { Eqn, runtimeEqn } from "../../utils/runtimeEqn";
import type { BaseTemplate, BuilderItem } from "../../Builder/types";
import type { Obj } from "../../utils/types";

interface Layout {
  order?: string[];
  mobileOrder?: string[];
  eqn?: Eqn;
};

type ObjectTemplate<L, Layout, Props> = BaseTemplate<'object', L, Layout> & {
  properties: Props,
};

export type ObjectItem<L, LL = {}, Props = Obj> = BuilderItem<Props, 'object', L, Layout & LL, ObjectTemplate<L, Layout & LL, Props>>;

export default function useObjectAsList(
  value?: Obj,
  onChange?: (newValue: Obj) => void,
  properties?: Obj,
  layout?: Layout,
) {
  const {order, mobileOrder} = layout ?? {};

  // Зависит от разрешения (почти константа)
  const bp = Grid.useBreakpoint();
  // Зависит от шаблона
  const usedOrder = bp.md ? order : (mobileOrder ?? order);
  // Константа
  const valueRef = useValueRef(value);

  // Зависит от шаблона
  const keys = useMemo(
    () => usedOrder ? Array.from(usedOrder) : (properties ? Object.keys(properties) : []),
    [usedOrder, properties]
  );

  // Зависит от шаблона
  const eqn = useMemo(
    () => runtimeEqn(layout?.eqn ?? {}),
    [layout?.eqn]
  );
  
  // Зависит от калбэка и шаблона
  const childChanges = useMemo(
    () => keys.map(
      key => (
        (newValue: unknown) => {
          if (key.startsWith('_')) {
            onChange?.(newValue as Obj);
          } else {
            onChange?.({...valueRef.current, [key]: newValue});
          }
        }
      )
    ),
    [onChange, valueRef, keys]
  );

  // Зависит от всего
  return useMemo(() => {
    const eqnValue = {
      ...eqn(value ?? {}),
      ...value,
    };

    return keys.map((key, id) => ({
      name: key,
      value: key.startsWith('_') ? eqnValue : eqnValue[key],
      template: properties?.[key],
      onChange: childChanges[id],
    }));
  }, [properties, value, keys, childChanges, eqn]);
}
