import { FC } from 'react';

import Router from './Router';

import './App.css';
import 'antd/dist/reset.css';

const App: FC = () => {
  return (
    <Router />
  );
}

export default App;
