import React, { useMemo } from "react";
import { Typography } from "antd";
import { ComponentFabricBuilder } from "./builder";
import type { BaseComponents, Props } from "./types";

function NativeRefFabric<Components extends BaseComponents<any>>(components: Components) {
  const fabric = ComponentFabricBuilder(components);
  type Renderer<N extends keyof Components> = Components[N]['renderer'];

  function NativeRefRenderer<N extends keyof Components>(props: Props<Renderer<N>>) {
    const Renderer = useMemo(() => {
      const name: N = props.template.ref;

      if (!name) {
        return null;
      }

      const item = fabric.get(name);

      return item?.renderer ?? null;
    }, [props.template.ref]);

    return Renderer ? <Renderer {...props} /> : <Typography.Text>{`Unknown ${props.template.ref} native component`}</Typography.Text>;
  }

  return {
    type: 'ref' as const,
    layout: 'native' as const,
    renderer: NativeRefRenderer,
  };
}

export default NativeRefFabric;
