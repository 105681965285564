import uploadFile from "./uploadFile";

class AudioRecorder {
  mediaRecorder: MediaRecorder | null;
  mediaChunks: any[];
  value: string | null;
  onChange: (v: string | null) => void;
  state: 'text' | 'recording' | 'saved' | 'ready' | 'uploading';
  prefix = 'AUDIO{{';

  constructor() {
    this.mediaRecorder = null;
    this.mediaChunks = [];
    this.value = null;
    this.onChange = () => void 0;
    // Режим автовыбора
    this.state = 'text';
  }

  async connect() {
    const audioStream = await navigator.mediaDevices.getUserMedia({audio: true});
    this.mediaRecorder = new MediaRecorder(audioStream);
    this.mediaRecorder.addEventListener(
      'dataavailable',
      event => this.mediaChunks.push(event.data)
    );
    this.mediaRecorder.addEventListener(
      'stop',
      () => this.save()
    );
  }

  start() {
    if (this.mediaRecorder?.state !== 'inactive') {
      console.warn('Dismiss AudioRecorder.start()');
      return;
    }
    this.mediaRecorder?.start();
    this.state = 'recording';
  }

  stop() {
    if (this.mediaRecorder?.state !== 'recording') {
      console.warn('Dismiss AudioRecorder.stop()');
      return;
    }
    this.state = 'uploading';
    this.mediaRecorder?.stop();
  }

  private async save() {
    const mediaBlob = new Blob(this.mediaChunks, {type: this.mediaRecorder?.mimeType});
    const uploader = uploadFile(mediaBlob);
    // Урлу мы знаем заранее
    const url = (await uploader.next()).value;
    // Ждем загрузки
    await uploader.next();
    this.afterSave(url);
  }

  afterSave(url: string) {
    this.mediaChunks = [];
    this.mediaRecorder = null;
    this.state = 'saved';
    this.onChange?.(`${this.prefix}${url}`);
  }

  isAudioString(value?: string) {
    return !!value?.startsWith(this.prefix);
  }

  hold(value: string, onChange: (newValue: string) => void) {
    this.value = value;
    if (this.state === 'text') {
      if (this.isAudioString(value)) {
        // Режим плеера
        this.state = 'saved';
      } else {
        // Режим текста
        this.state = 'ready';
      }
    }
    if (this.state === 'ready') {
      if (this.isAudioString(value)) {
        // Очистка ссылки
        this.value = '';
      }
    }
    if (this.state === 'saved' && this.isAudioString(value)) {
      // Оставляем только ссылку
       this.value = value.split(this.prefix)[1];
    }
    this.onChange = v => v && onChange(v);
  }
}

export default AudioRecorder;
