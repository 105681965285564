import React from "react";
import { Collapse } from "antd";
import type { BuilderItem } from "../../Builder/types";

interface Template {
  header: unknown;
  content: unknown;
  isOpen?: boolean;
}

type Item = BuilderItem<unknown, 'layout', 'Collapse', Template>;

const Renderer: Item['renderer'] = (props) => {
  const {Fabric} = props;

  return (
    <Collapse defaultActiveKey={props.template.layout.isOpen ? ['single'] : []}>
      <Collapse.Panel
        header={<Fabric {...props} template={props.template.layout.header} />}
        key="single"
      >
        <Fabric {...props} template={props.template.layout.content} />
      </Collapse.Panel>
    </Collapse>
  );
}

const CollapseItem: Item = {
  type: 'layout',
  layout: 'Collapse',
  renderer: Renderer,
};

export default CollapseItem;
