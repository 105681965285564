import { YamlTemplate } from "../../../utils/yaml";
import LensComponentCreator from "../../Base/LensComponentCreator";
import type { ComponentBase } from "../../Base/types";


type YamlEditor = ComponentBase<'YamlEditor', string, {}>;

type ChildValue = {
  title: {
    label: string,
    docs: null,
  },
  editor: string,
}

const YamlEditorComponent = LensComponentCreator<YamlEditor, ComponentBase<'', ChildValue, {}>>(
  'YamlEditor',
  () => YamlTemplate.toTemplate(`
type: object
layout:
  type: vertical
properties:
  title:
    type: object
    layout:
      type: horizontal
    properties:
      label:
        type: string
        layout:
          type: label
      docs:
        type: layout
        layout:
          type: routerConsumer
          key: yamlDocumentation
          content:
            type: layout
            layout:
              type: button
              buttonType: link
              label: Документация
  editor:
    type: string
    layout:
      type: code
      width: 700px
      lang: yaml
  `),
  code => ({
    title: {
      label: 'Редактор YAML',
      docs: null,
    },
    editor: code
  }),
  (prevValue, childValue) => (childValue.editor)
);

export default YamlEditorComponent;
