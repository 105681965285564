import React, { useCallback, useMemo } from "react";
import { ArrayLike, LensStream, Stream, useStream } from "../../Builder/array";
import getAddItemButtonTemplate from "./getAddItemButtonTemplate";
import type { ArrayItem } from "./types";

type ArrayEditorItem = ArrayItem<'editor', {
  // Показывать/не показывать кнопку над списком (по умолчанию true)
  header?: boolean;
  // Шаблон для отображения строки в списке
  title: unknown;
  // Подпись на кнопке "Создать"
  createTitle?: string;
  routeKey?: string;
}>;

type Element<Title = unknown, Body = unknown> = {
  origin: unknown,
  title: Title,
  buttons: {
    edit: Body,
    delete?: {
      clicked?: boolean,
    },
  },
};

type Values<Title = unknown, Body = unknown> = {
  header: Body,
  add: Body,
  list: ArrayLike<Element<Title, Body>>,
};

function getTemplate<Title, Body>(
  header: boolean,
  title: Title,
  body: Body,
  routeKey?: string,
  createTitle?: string,
) {
  // Кнопка редактирования
  let editTemplate: unknown = {
    type: 'layout',
    layout: {
      type: 'icon',
      icon: 'edit',
      buttonType: 'link',
    },
  };

  if (routeKey) {
    // Делаем редирект на routeKey
    editTemplate = {
      type: 'layout',
      layout: {
        type: 'routerConsumer',
        key: routeKey,
        content: editTemplate,
      },
    };

  } else {
    // Оформляем как модал
    editTemplate = {
      type: 'layout',
      layout: {
        type: 'dialog',
        button: editTemplate,
        header: 'Редактировать',
        body,
      },
    };
  }

  return {
    type: 'object',
    layout: {
      type: 'vertical',
      order: ['header', 'list', 'add'],
    },
    properties: {
      header: header ? getAddItemButtonTemplate(false, routeKey, body, createTitle) : undefined,
      add: getAddItemButtonTemplate(true, routeKey, body, createTitle),
      list: {
        type: 'array',
        layout: {
          type: 'base',
        },
        items: {
          type: 'object',
          layout: {
            type: 'horizontalFixed',
            order: ['title', 'buttons'],
            widths: ['100%', '80px'],
          },
          properties: {
            title,
            buttons: {
              type: 'object',
              layout: {
                type: 'horizontal',
                order: ['edit', 'delete'],
              },
              properties: {
                edit: editTemplate,
                delete: {
                  type: 'layout',
                  layout: {
                    type: 'icon',
                    confirm: 'Удаляем?',
                    icon: 'trash',
                    buttonType: 'link',
                  },
                },
              },
            },
          },
        },
      },
    },
  };
}

const mapper = (v: Element) => {
  if (v.buttons.delete?.clicked) {
    return null;
  }

  if (v.origin !== v.buttons.edit) {
    return v.buttons.edit ?? null;
  }

  if (v.origin !== v.title) {
    return v.title ?? null;
  }

  return null;
};

const Renderer: ArrayEditorItem['renderer'] = ({value, template, onChange, Fabric}) => {
  const stream = useStream(value);

  const formStream = useMemo(
    () => new LensStream<Element, unknown>(
      stream,
      mapper,
      v => ({
        origin: v,
        title: v,
        buttons: {
          edit: v,
        }
      })
    ),
    [stream]
  );

  const formValue = useMemo<Values>(() => ({
    header: null,
    add: null,
    list: formStream,
  }), [formStream]);

  const formTemplate = useMemo(
    () => getTemplate(
      template.layout.header ?? true,
      template.layout.title,
      template.items,
      template.layout.routeKey,
      template.layout.createTitle,
    ),
    [
      template.layout.header,
      template.layout.title,
      template.items,
      template.layout.routeKey,
      template.layout.createTitle,
    ]
  );

  const onFormChange = useCallback(async (newValue: Values) => {
    const addClicked = newValue.add ?? newValue.header;
    // Если кликнули по добавлению (в хедере или под таблицей)
    if (addClicked) {
      const result = await stream.create(addClicked);
      result && onChange(result);
      return;
    }
    const list = newValue.list;
    if (!list || (list instanceof Stream)) {
      return;
    }
    const updateResult = [];
    for(let id = 0; id < list.length; id++) {
      const elementUpdated = mapper(list[id]);
      // Если уже отредактировали
      if (elementUpdated) {
        updateResult.push(elementUpdated);
        continue;
      }
      // Если кликнули на удаление
      if (list[id].buttons.delete?.clicked) {
        const result = await stream.delete(id, list[id].buttons.edit);
        result && onChange(result);
        return;
      }
      // Иначе кладем оригинал
      updateResult.push(list[id].origin);
    }
    // Зовем калбэк
    // TODO: возникает какой-то конфликт в разделе Данные
    // onChange(updateResult);
  }, [stream, onChange]);

  return (
    <Fabric
      value={formValue}
      template={formTemplate}
      onChange={onFormChange}
      Fabric={Fabric}
    />
  );
}

const ArrayEditor: ArrayEditorItem = {
  type: 'array',
  layout: 'editor',
  renderer: Renderer,
};

export default ArrayEditor;
