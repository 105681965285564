import React, { useMemo } from "react";
import { Space, Typography } from "antd";
import { isInteger } from "lodash";
import type { TitleProps } from "antd/es/typography/Title";
import type { BuilderItem } from "../../Builder/types";

interface Layout {
  text?: string;
  strong?: boolean;
  italic?: boolean;
  level?: TitleProps['level'];
}

type Item = BuilderItem<string, 'string', 'label', Layout>;

function fillBr(text: string) {
  return text.split('\n').flatMap((line, id) => {
    const lineContent = (
      <React.Fragment key={`l-${id}`}>
        {line}
      </React.Fragment>
    );

    if (id === 0) {
      return [lineContent];
    }

    return [<br key={`br-${id}`}/>, lineContent];
  });
}

const Renderer: Item['renderer'] = ({value, template, onChange}) => {
  const TextRenderer = template.layout.level === undefined
    ? Typography.Text
    : Typography.Title;
  
  const level = useMemo(() => {
    let level = template.layout.level;
    if (level === undefined) {
      return level;
    }
    if (!isInteger(level)) {
      return undefined;
    }
    if (level < 1 || level > 5) {
      return undefined;
    }
    return level;
  }, [template.layout.level]);

  const content = useMemo(
    () => fillBr(String(template.layout.text ?? value)),
    [template.layout.text, value]
  );

  const label = (
    <TextRenderer
      strong={template.layout.strong}
      italic={template.layout.italic}
      level={level}
    >{content}</TextRenderer>
  );

  if (!template.title) {
    return label;
  }

  return (
    <Space direction="vertical" className="w-full" size={4}>
      <Typography.Text strong>{template.title}</Typography.Text>
      {label}
    </Space>
  );
}

const StringLabel: Item = {
  type: 'string',
  layout: 'label',
  renderer: Renderer,
};

export default StringLabel;
