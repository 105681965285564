import type { Obj } from "./types";

export type Eqn = {[key: string]: string};
type Seqn = (item: unknown) => unknown;

const invalidSeqn: Seqn = _item => {
  throw new Error('Seqn compile error');
}

export function runtimeEqn(eqn: Eqn): (item: Obj) => Obj {
  const functions = Object.keys(eqn).map(field => {
    try {
      // eslint-disable-next-line no-new-func
      const seqn = new Function('item', `return ( ${eqn[field]} );`) as Seqn;
      return {seqn, field, orig: eqn[field]};
    } catch (error) {
      return {seqn: invalidSeqn, field, orig: eqn[field]};
    }
  });

  if (functions.length === 0) {
    return (_item: unknown) => ({});
  }

  return (item: unknown) => {
    const result: Obj = {};
    functions.forEach(({seqn, field, orig}) => {
      try {
        result[field] = seqn(item);
      } catch (error) {
        result[field] = `Ошибка в ${orig}`;
      }
    });
    return result;
  }
}
