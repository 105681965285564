import { YamlTemplate } from "../../../utils/yaml";

const EditorPageTemplate = YamlTemplate.toTemplate(`
type: ref
ref: StyleConfig
layout:
  type: native
  content:
    type: ref
    ref: LeftPanel
    layout:
      type: native
      leftPanel:
        type: ref
        ref: Menu
        layout:
          type: native
      topLeftPanel:
        type: ref
        ref: NativeReactRenderer
        layout:
          type: native
      bottomLeftPanel:
        type: ref
        ref: NativeReactRenderer
        layout:
          type: native
      content:
        type: layout
        layout:
          type: routerProvider
          content:

            yamlDocumentation:
              type: layout
              layout:
                type: confirmBlock
                buttonCancel:
                  type: layout
                  layout:
                    type: button
                    buttonType: secondary
                    label: Назад
                body:
                  type: ref
                  ref: Editor.Examples
                  layout:
                    type: native

            view:
              type: array
              layout:
                type: view
                routeKey: viewEditor
                title: Представления
                createTitle: Новое представление
                keys:
                  - name
                  - workspace
                titles:
                  - Название
                  - Компания

            rule:
              type: array
              layout:
                type: editor
                routeKey: ruleEditor
                title:
                  type: object
                  layout:
                    type: horizontal
                    size: 30
                  properties:
                    title:
                      type: string
                      layout:
                        type: label
                    workspace:
                      type: string
                      layout:
                        type: label
                    order:
                      type: string
                      layout:
                        type: label
                    roles:
                      type: array
                      layout:
                        type: base
                      items:
                        type: string
                        layout:
                          type: label
                    actions:
                      type: array
                      layout:
                        type: base
                      items:
                        type: string
                        layout:
                          type: label
                    tables:
                      type: array
                      layout:
                        type: base
                      items:
                        type: string
                        layout:
                          type: label

            user:
              type: array
              layout:
                type: view
                routeKey: userEditor
                title: Пользователи
                createTitle: Новый пользователь
                keys:
                  - name
                  - workspace
                  - roles
                titles:
                  - Имя пользователя
                  - Компания
                  - Роли

            M_webForms:
              type: array
              layout:
                type: view
                routeKey: webFormEditor
                title: Веб-формы
                createTitle: Новая форма
                keys:
                  - name
                  - workspace
                titles:
                  - Название
                  - Компания

            database:
              type: array
              layout:
                type: view
                routeKey: databaseEditor
                title: База данных
                createTitle: Создать новую базу данных
                keys:
                  - name
                  - workspace
                titles:
                  - Название
                  - Компания

            data:
              type: array
              layout:
                type: base
              items:
                type: layout
                layout:
                  type: routerConsumer
                  key: dataEditor
                  content:
                    type: object
                    layout:
                      type: vertical
                    properties:
                      name:
                        type: layout
                        layout:
                          type: button
                          buttonType: default
                          width: 100%
                          content:
                            type: string
                            layout:
                              type: label
                      
            viewEditor:
              type: layout
              layout:
                type: confirmBlock
                buttonOk:
                  type: layout
                  layout:
                    type: button
                    buttonType: primary
                    label: Сохранить
                buttonCancel:
                  type: layout
                  layout:
                    type: button
                    buttonType: secondary
                    label: Отмена
                body:
                  type: ref
                  layout:
                    type: native
                  ref: Editor.TemplateEditor

            ruleEditor:
              type: layout
              layout:
                type: confirmBlock
                buttonOk:
                  type: layout
                  layout:
                    type: button
                    buttonType: primary
                    label: Сохранить
                buttonCancel:
                  type: layout
                  layout:
                    type: button
                    buttonType: secondary
                    label: Отмена
                body:
                  type: object
                  layout:
                    type: vertical
                  properties:
                    titleTitle:
                      type: string
                      layout:
                        type: label
                        text: Название правила
                    title:
                      title: Название правила
                      type: string
                      layout:
                        type: input
                    workspaceTitle:
                      type: string
                      layout:
                        type: label
                        text: Привязка к компании
                    workspace:
                      title: Компания
                      type: string
                      layout:
                        type: input
                    enabled:
                      title: Использовать это правило
                      type: boolean
                      layout:
                        type: input
                    delim1:
                      type: layout
                      layout:
                        type: delim
                    roles:
                      title: Активирующие роли
                      type: array
                      layout:
                        type: TagEditor
                    tables:
                      title: Активирующие изменения в таблицах
                      type: array
                      layout:
                        type: TagEditor
                    actions:
                      title: Активирующие операции с таблицей (create, readMany, update, delete)
                      type: array
                      layout:
                        type: TagEditor
                    delim2:
                      type: layout
                      layout:
                        type: delim
                    orderTitle:
                      type: string
                      layout:
                        type: label
                        text: Позиция правила в порядке применения
                    order:
                      title: Целое число
                      type: number
                      layout:
                        type: input
                    condition:
                      type: layout
                      layout:
                        type: Collapse
                        isOpen: true
                        header:
                          type: string
                          layout:
                            type: label
                            text: Условие правила (true, false, null, json)
                        content:
                          type: string
                          layout:
                            type: code
                            lang: json
                            width: 100%
                            height: 200px
                    handler:
                      type: layout
                      layout:
                        type: Collapse
                        header:
                          type: string
                          layout:
                            type: label
                            text: Скрипт срабатывания
                        content:
                          type: string
                          layout:
                            type: code
                            lang: python
                            width: 100%
                            height: 200px
                            default: >
                              async def mainPostRequestExample():  # Переименовать в main() для использования
                                from aiohttp import ClientSession as Session
                                # Данные запроса
                                webhookUrl = 'https://webhook.site/4d842b4f-e151-44d6-ba85-c8aa1d1e3621'
                                webhookJson = {
                                  'test': 4,
                                }
                                # Запрос
                                async with Session() as s:
                                  async with s.post(webhookUrl, json=webhookJson) as r:
                                    status = r.status
                                    textResult = await r.text()
                                print(status, textResult)                            

            userEditor:
              type: layout
              layout:
                type: confirmBlock
                buttonOk:
                  type: layout
                  layout:
                    type: button
                    buttonType: primary
                    label: Сохранить
                buttonCancel:
                  type: layout
                  layout:
                    type: button
                    buttonType: secondary
                    label: Отмена
                body:
                  type: object
                  layout:
                    type: vertical
                  properties:
                    nameTitle:
                      type: string
                      layout:
                        type: label
                        text: Имя пользователя
                    name:
                      title: Имя пользователя
                      type: string
                      layout:
                        type: input
                    workspaceTitle:
                      type: string
                      layout:
                        type: label
                        text: Привязка к компании
                    workspace:
                      title: Компания
                      type: string
                      layout:
                        type: input
                    username:
                      title: Имя пользователя для входа
                      type: string
                      layout:
                        type: input
                    # password:
                    #   title: Пароль
                    #   type: string
                    #   layout:
                    #     type: input
                    token:
                      title: Токен идентификации
                      type: string
                      layout:
                        type: input
                    roles:
                      title: Роли пользователя
                      type: array
                      layout:
                        type: TagEditor

            databaseFormsEditor:
              type: layout
              layout:
                type: confirmBlock
                buttonOk:
                  type: layout
                  layout:
                    type: button
                    buttonType: primary
                    label: Сохранить
                buttonCancel:
                  type: layout
                  layout:
                    type: button
                    buttonType: secondary
                    label: Отмена
                body:
                  type: object
                  layout:
                    type: vertical
                  properties:
                    viewTemplateTitle:
                      type: string
                      layout:
                        type: label
                        text: "Шаблон названия: {{ item.field }} of {{ item.check + item.res }}"
                    viewTemplate:
                      type: string
                      layout:
                        type: code
                        lang: text
                        height: 300px
                    editorTemplateTitle:
                      type: string
                      layout:
                        type: label
                        text: Представление для редактирования
                    editorTemplate:
                      type: ref
                      layout:
                        type: streamComponents
                        template:
                          type: layout
                          layout:
                            search: name
                            pkey: alias
                            type: selector

            yamlEditor:
              type: layout
              layout:
                type: confirmBlock
                buttonOk:
                  type: layout
                  layout:
                    type: button
                    buttonType: primary
                    label: Сохранить
                buttonCancel:
                  type: layout
                  layout:
                    type: button
                    buttonType: secondary
                    label: Отмена
                body:
                  type: object
                  layout:
                    type: horizontal
                  properties:
                    template:
                      type: ref
                      ref: YamlEditor
                      layout:
                        type: native

            databaseEditor:
              type: layout
              layout:
                type: confirmBlock
                buttonOk:
                  type: layout
                  layout:
                    type: button
                    buttonType: primary
                    label: Сохранить
                buttonCancel:
                  type: layout
                  layout:
                    type: button
                    buttonType: secondary
                    label: Отмена
                body:
                  type: ref
                  ref: Editor.DatabaseEditor
                  layout:
                    type: native

            webFormEditor:
              type: layout
              layout:
                type: confirmBlock
                buttonOk:
                  type: layout
                  layout:
                    type: button
                    buttonType: primary
                    label: Сохранить
                buttonCancel:
                  type: layout
                  layout:
                    type: button
                    buttonType: secondary
                    label: Отмена
                body:
                  type: ref
                  ref: Editor.WebFormEditor
                  layout:
                    type: native

            dataEditor:
              type: layout
              layout:
                type: confirmBlock
                buttonOk:
                  type: layout
                  layout:
                    type: button
                    buttonType: link
                    label: Назад
                body:
                  type: ref
                  layout:
                    type: native
                  ref: Editor.TableEditor
`);

export default EditorPageTemplate;
