import React, { useCallback, useEffect, useState } from 'react';
// @ts-ignore
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import { ArrayLike, useStream, useUpdateEvent } from '../../../Builder/array';
import type { ComponentBase } from '../../Base/types';

interface Template {
  isis?: boolean;
}

type PivotTable = ComponentBase<'PivotTable', ArrayLike<unknown>, Template>;

const Renderer: PivotTable['renderer'] = ({value, template, onChange, Fabric}) => {
  const stream = useStream(value);
  const mr = useUpdateEvent(stream, ['create', 'update', 'delete'], 'PivotTable');
  const [props, setProps] = useState<object>({data: []});

  const loadArray = useCallback(
    async () => {
      const newData = await stream.getFrame();

      setProps(prev => ({
        ...prev,
        data: newData,
      }))
    },
    [setProps, stream]
  );

  useEffect(() => {
    loadArray();
  }, [loadArray, mr]);

  return (
    <div >
      <PivotTableUI
        {...props}
        onChange={setProps}
      />
    </div>
  )
}

const PivotTableComponent: PivotTable = {
  name: 'PivotTable',
  renderer: Renderer,
};

export default PivotTableComponent;
