import { YamlTemplate } from "../utils/yaml";

const routes = [
  {
    path: '',
    template: YamlTemplate.toTemplate(`
      type: ref
      ref: EditorPage
      layout:
        type: native
    `),
  },
  {
    path: 'f/:webFormRoute',
    template: YamlTemplate.toTemplate(`
      type: ref
      ref: WebFormPage
      layout:
        type: native
    `),
  },
  {
    path: 't/:templateRoute/:tableId/:itemId',
    template: YamlTemplate.toTemplate(`
      type: ref
      ref: TemplatePage
      layout:
        type: native
    `),
  },
  {
    path: 't/:templateRoute/:tableId',
    template: YamlTemplate.toTemplate(`
      type: ref
      ref: TemplatePage
      layout:
        type: native
    `),
  },
  {
    path: 'token/:token',
    template: YamlTemplate.toTemplate(`
      type: ref
      ref: TokenAuthPage
      layout:
        type: native
    `),
  },
  {
    path: 'signin',
    template: YamlTemplate.toTemplate(`
      type: ref
      ref: AuthPage
      layout:
        type: native
        strategy: signin
    `),
  },
  {
    path: 'signup',
    template: YamlTemplate.toTemplate(`
      type: ref
      ref: AuthPage
      layout:
        type: native
        strategy: signup
    `),
  },
  {
    path: 'signup/:resetToken',
    template: YamlTemplate.toTemplate(`
      type: ref
      ref: AuthPage
      layout:
        type: native
        strategy: signupConfirm
    `),
  },
  {
    path: 'changePassword',
    template: YamlTemplate.toTemplate(`
      type: ref
      ref: AuthPage
      layout:
        type: native
        strategy: changePassword
    `),
  },
  {
    path: 'changePassword/:resetToken',
    template: YamlTemplate.toTemplate(`
      type: ref
      ref: AuthPage
      layout:
        type: native
        strategy: changePassword
    `),
  },
  {
    path: 'signout',
    template: YamlTemplate.toTemplate(`
      type: ref
      ref: SignoutPage
      layout:
        type: native
    `),
  },
  {
    path: 'unconfirm',
    template: YamlTemplate.toTemplate(`
      type: ref
      ref: UnconfirmPage
      layout:
        type: native
    `),
  },
];

export default routes;
