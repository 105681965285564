import React from "react";
import { Space } from "antd";
import useObjectAsList, { ObjectItem } from "./useObjectAsList";

const Renderer: ObjectItem<'vertical'>['renderer'] = ({value, template, onChange, Fabric}) => {
  const properties = useObjectAsList(value, onChange, template.properties, template.layout);

  return (
    <Space size={10} direction="vertical" style={{width: '100%', height: '100%', minWidth: '250px'}}>
      {properties.map(property => (
        <Fabric
          key={property.name}
          value={property.value}
          template={property.template}
          onChange={property.onChange}
          Fabric={Fabric}
        />
      ))}
    </Space>
  )
}

const VerticalObject: ObjectItem<'vertical'> = {
  type: 'object',
  layout: 'vertical',
  renderer: Renderer,
};

export default VerticalObject;
