import { v4 as uuidv4 } from 'uuid';
import mime2ext from 'mime2ext';
import { getDownloadFileUrl, getUploadFileUrl } from './env';

const UPLOAD_URL = getUploadFileUrl();
const DOWNLOAD_URL = getDownloadFileUrl();

function genFileName(fileType: string) {
  const p1 = uuidv4().split('-');
  const p2 = uuidv4().split('-');
  const fileName = [p1[1], p2[2], p1.slice(-2)[0], p2.slice(-3)[0]].join('-');
  const fileExt = fileType.indexOf('audio/webm') >= 0 ? 'webm' : mime2ext(fileType);
  return `${fileName}.${fileExt}`;
}

async function* uploadFile(file: Blob) {
  const formData = new FormData();
  const fileName = genFileName(file.type);
  const url = `${DOWNLOAD_URL}${fileName}`;
  yield url;
  formData.append(fileName, file);
  await fetch(
    UPLOAD_URL,
    {
      method: 'POST',
      body: formData,
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'Content-Type': file.type,
      },
      mode: 'no-cors',
    }
  );
  return url;
}

export default uploadFile;
