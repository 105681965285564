import { YamlTemplate } from "../../../../utils/yaml";
import LensComponentCreator from "../../../Base/LensComponentCreator";
import { ComponentBase } from "../../../Base/types";
import { Template } from "../../../StreamRef/types";
import { getTableNameFromTemplate, joinTemplateWithTableName, TemplatePositionLens } from "./utils";

const template = YamlTemplate.toTemplate(`
type: object
layout:
  type: vertical
  order:
    - name
    - content
properties:
  name:
    type: string
    title: Название представления
    layout:
      type: input
  content:
    type: object
    layout:
      type: horizontal
      order:
        - template
        - buttons
      mobileOrder:
        - buttons
    properties:

      template:
        type: string
        layout:
          type: code
          width: 500px
          height: 1280px
          lang: yaml

      buttons:
        type: object
        layout:
          type: vertical
        properties:
          previewContent:
            type: layout
            layout:
              type: Collapse
              header:
                type: string
                layout:
                  type: label
                  level: 4
                  text: Предпросмотр
              content:
                type: layout
                layout:
                  type: preview
          delim1:
            type: layout
            layout:
              type: delim
          # result:
          #   type: string
          #   layout:
          #     type: code
          #     lang: json
          #     width: 300px
          #     height: 300px
          # table:
          #   type: ref
          #   ref: Table
          #   layout:
          #     table: _
          #     type: native
          #     template:
          #       type: layout
          #       title: Связанная таблица
          #       layout:
          #         search: name
          #         type: selector
          position:
            type: string
            title: Позиция в меню
            layout:
              type: input
          route:
            type: string
            title: Ссылка на представление
            layout:
              type: input
          edit:
            type: layout
            layout:
              type: routerConsumer
              key: yamlEditor
              content:
                type: layout
                layout:
                  type: button
                  label: Редактировать
`);

type TemplateEditor = ComponentBase<'Editor.TemplateEditor', Template<unknown>, {}>;

type ChildValue = {
  name: string,
  content: {
    template: string,
    buttons: {
      previewContent: {
        template: unknown,
        value: unknown,
      },
      result?: string, // NOTE: добавлять ли value?
      table: string | null,
      position: string,
      route?: string,
      edit: Template<unknown>,
    },
  },
};

const TemplateEditorComponent = LensComponentCreator<TemplateEditor, ComponentBase<'', ChildValue, {}>>(
  'Editor.TemplateEditor',
  () => template,
  template => {
    const t = YamlTemplate.toTemplate(template?.template);

    return {
      name: template?.name,
      content: {
        template: template?.template,
        buttons: {
          previewContent: {
            template: t,
            value: null,
          },
          table: getTableNameFromTemplate(t),
          position: TemplatePositionLens.toEditor(template?.position),
          route: template?.route,
          edit: template,
        },
      },
    };
  },
  (prevValue, childValue) => {
    if (prevValue?.name !== childValue.name) {
      return {...prevValue, name: childValue.name};
    }

    if (prevValue?.template !== childValue.content.template) {
      return {...prevValue, template: childValue.content.template};
    }

    if (TemplatePositionLens.toEditor(prevValue?.position) !== childValue.content.buttons.position) {
      return {...prevValue, position: TemplatePositionLens.toTemplate(childValue.content.buttons.position)};
    }

    if (prevValue?.route !== childValue.content.buttons.route) {
      return {...prevValue, route: childValue.content.buttons.route || undefined};
    }

    if (prevValue !== childValue.content.buttons.edit) {
      return childValue.content.buttons.edit;
    }

    const prevTemplate = YamlTemplate.toTemplate(prevValue.template);

    if (getTableNameFromTemplate(prevTemplate) !== childValue.content.buttons.table) {
      const newTemplate = joinTemplateWithTableName(prevTemplate, childValue.content.buttons.table);

      return {...prevValue, template: YamlTemplate.toYaml(newTemplate)};
    }

    return prevValue;
  }
);

export default TemplateEditorComponent;
