import ApiFabric from "../Api/api";
import ApiStream from "../Api/ApiStream";
import ApiTableStream from "../Api/ApiTableStream";
import AllComponents from "../Components";
import StreamRefFabric from "../Components/StreamRef";
import TableComponentFabric from "../Components/TableRef";
import NativeRefFabric from '../Components/Base/NativeRefFabric';
import AllElements from "../Elements";
import { getApiBase } from "../utils/env";
import type { Rule, User, WebFormEntity } from "../Components/Editor/types";
import type { Template } from "../Components/StreamRef/types";

function getApiStreams() {
  const api = ApiFabric(getApiBase());

  return {
    api,
    tables: new ApiTableStream(api, 'Table'),
    templates: new ApiStream<Template<any>>(api, 'Template'),
    webForms: new ApiStream<WebFormEntity>(api, 'WebFormEntity'),
    rules: new ApiStream<Rule>(api, 'Rule'),
    users: new ApiStream<User>(api, 'User'),
  };
}

function getFabric() {
  const streams = getApiStreams();

  const components = AllComponents()
    .add(TableComponentFabric(streams.tables));

  // const aa = components.get('Table');
  // const RR = aa.renderer;
  // <RR value={null} template={{
  //   type: 'ref',
  //   ref: 'Table',
  //   layout: {
  //     type: 'native',
  //     table: 'Table',
  //     template: {},
  //   }
  // }} />

  // Раскомментить и закомментить при ошибке компиляции
  // components.get('Editor.WebFormEditor');

  const streamComponents = StreamRefFabric(streams.templates);

  const builder = AllElements()
    .add(NativeRefFabric(components.getAll()))
    .add(streamComponents.StreamRef)
    .add(streamComponents.StreamComponents);

  // const b = builder.get('ref', 'stream');
  // const R = b?.renderer;
  // const c = R && <R template={{
  //   type: 'ref',
  //   layout: {
  //     type: 'stream',
  //   },
  //   ref: 'T2',
  // }} />;

  const Fabric = builder.getFabric();

  return {Fabric, streams};
}

export default getFabric;
