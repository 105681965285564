import { ProConfigProvider } from "@ant-design/pro-provider";
import { ConfigProvider, Typography } from "antd";
import ru_RU from "antd/lib/locale/ru_RU";
import { CSSProperties } from "react";
import type { ComponentBase } from "../../Base/types";
import type { PageValue } from "../types";

type UnconfirmPage = ComponentBase<'UnconfirmPage', PageValue<any, any>, {}>;

// https://procomponents.ant.design/

const contentStyle: CSSProperties = {
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const subContentStyle: CSSProperties = {
  margin: 'auto',
  width: '400px',
};

const headerStyle: CSSProperties = {
  textAlign: 'center',
};

const subHeaderStyle: CSSProperties = {
  marginBottom: '20px',
  display: 'block',
  textAlign: 'center',
};

const Renderer: UnconfirmPage['renderer'] = ({template, value}) => {
  return (
    <ConfigProvider locale={ru_RU}>
      <ProConfigProvider hashed={false}>
        <div style={contentStyle}><div style={subContentStyle}>
          <Typography.Title level={2} style={headerStyle}>
            Chekio
          </Typography.Title>

          <Typography.Text style={subHeaderStyle} type="secondary">
            Сервис для контроля и обучения линейного персонала
          </Typography.Text>

          <Typography.Text style={subHeaderStyle}>
            Ваш электронный адрес не был подтвержден
          </Typography.Text>
        </div></div>
      </ProConfigProvider>
    </ConfigProvider>
  );
}

const UnconfirmPageComponent: UnconfirmPage = {
  name: 'UnconfirmPage',
  renderer: Renderer,
};

export default UnconfirmPageComponent;
