import React, { FC, ReactElement, useMemo } from "react";
import { Collapse, QRCode, theme, Typography } from "antd";
import type { BuilderItem } from "../../Builder/types";

interface Template {
  text?: string;
  showQr?: boolean;
}

type Item = BuilderItem<string, 'string', 'link', Template>;

const { useToken } = theme;

const QrRenderer: FC<{link: string, children: ReactElement}> = ({link, children}) => {
  const { token } = useToken();

  return (
    <Collapse>
      <Collapse.Panel header={children} key="single">
        <QRCode value={link} style={{backgroundColor: token.colorWhite}} />
      </Collapse.Panel>
    </Collapse>
  );
}

const Renderer: Item['renderer'] = ({value, template}) => {
  const linkContent = useMemo(() => (
    <Typography.Link href={value} target='_blank' onClick={e => e.stopPropagation()}>
      {String(template.layout.text ?? value)}
    </Typography.Link>
  ), [template.layout.text, value]);

  if (!template.layout.showQr) {
    return linkContent;
  }

  return (
    <QrRenderer link={value}>
      {linkContent}
    </QrRenderer>
  );
}

const StringLink: Item = {
  type: 'string',
  layout: 'link',
  renderer: Renderer,
};

export default StringLink;
