import React, { useCallback } from "react";
import { Input, InputProps, Space, Typography } from "antd";
import type { BuilderItem } from "../../Builder/types";

interface Layout {
  disabled?: boolean;
  placeholder?: string;
}

type Item = BuilderItem<string, 'string', 'input', Layout>;
type OnInputChange = Exclude<InputProps['onChange'], undefined>;

const Renderer: Item['renderer'] = ({value, template, onChange}) => {
  const onInputChange = useCallback<OnInputChange>(e => {
    onChange(e.target.value);
  }, [onChange]);

  const input = (
    <Input
      disabled={template.layout.disabled}
      placeholder={template.layout.placeholder}
      value={value}
      onChange={onInputChange}
    />
  );

  if (!template.title) {
    return input;
  }

  return (
    <Space direction="vertical" className="w-full" size={4}>
      <Typography.Text strong>{template.title}</Typography.Text>
      {input}
    </Space>
  );
}

const StringInput: Item = {
  type: 'string',
  layout: 'input',
  renderer: Renderer,
};

export default StringInput;
