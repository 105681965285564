import React, { CSSProperties } from "react";
import { Space } from "antd";
import useObjectAsList, { ObjectItem } from "./useObjectAsList";

const contentStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
};

const Renderer: ObjectItem<'horizontal', {gap?: number}>['renderer'] = ({value, template, onChange, Fabric}) => {
  const properties = useObjectAsList(value, onChange, template.properties, template.layout);

  if (properties.length === 0) {
    return null;
  }

  if (properties.length === 1) {
    return (
      <Fabric
        value={properties[0].value}
        template={properties[0].template}
        onChange={properties[0].onChange}
        Fabric={Fabric}
      />
    );
  }

  return (
    <Space
      size={template.layout.gap ?? 10}
      align="start"
      direction="horizontal"
      className="w-full"
      style={contentStyle}
    >
      {properties.map(property => (
        <Fabric
          key={property.name}
          value={property.value}
          template={property.template}
          onChange={property.onChange}
          Fabric={Fabric}
        />
      ))}
    </Space>
  )
}

const HorizontalObject: ObjectItem<'horizontal'> = {
  type: 'object',
  layout: 'horizontal',
  renderer: Renderer,
};

export default HorizontalObject;
