import React, { useCallback, useMemo, useState } from "react";
import { Select, Space, Spin, Typography } from "antd";
import { useStream } from "../../Builder/array";
import type {BuilderItem } from "../../Builder/types";
import useValueRef from "../useValueRef";
import { ValueAndStream } from "../array/types";

interface Layout {
  search: string;
  pkey?: string;
  showSpin?: boolean;
  placeholder?: string;
}

type StreamElement<Search extends string, Pkey extends string | never> = {
  [key in Search | Pkey]: string;
}

type Item = BuilderItem<ValueAndStream<StreamElement<string, string>>, 'layout', 'selector', Layout>;

const contentStyles = {display: 'flex', gap: '5px'} as const;

const Renderer: Item['renderer'] = ({value, template, onChange, Fabric}) => {
  const valueRef = useValueRef(value);
  const stream = useStream(value?.stream);
  const [values, setValues] = useState<StreamElement<string, string>[]>([]);
  const [loading, setLoading] = useState(false);

  const updateValues = useCallback(async (search: string) => {
    const newValues = await stream.find({filters: {[template.layout.search]: {like: search}}});

    if (!newValues || newValues.length === 0) {
      return;
    }

    setValues(newValues);
  }, [stream, setValues, template.layout.search]);

  const handleSearch = useCallback(async (search: string) => {
    setLoading(true);

    try {
      await updateValues(search);
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  }, [setLoading, updateValues]);

  const options = useMemo(() => values.map(v => ({
    value: v?.[template.layout.pkey ?? template.layout.search],
    label: v?.[template.layout.search],
  })).filter(({value}) => !!value), [values, template.layout.pkey, template.layout.search]);


  const onChangeSelected = useCallback(
    (newValue: unknown) => onChange({...valueRef.current, value: newValue}),
    [onChange, valueRef]
  );

  const onFocus = useCallback(
    () => handleSearch(''),
    [handleSearch],
  );

  const selector = (
    <div className='w-full' style={contentStyles}>
      <div className='w-full'>
        <Select
          className='w-full'
          showSearch
          value={value?.value}
          placeholder={template.layout.placeholder}
          defaultActiveFirstOption={false}
          allowClear
          showArrow={false}
          filterOption={false}
          onSearch={handleSearch}
          onFocus={onFocus}
          onChange={onChangeSelected}
          notFoundContent={null}
          options={options}
        />
      </div>
      {(template.layout.showSpin ?? true) && <Spin style={{opacity: loading ? 1 : 0}} />}
    </div>
  );

  if (!template.title) {
    return selector;
  }

  return (
    <Space direction="vertical" className="w-full" size={4}>
      <Typography.Text strong>{template.title}</Typography.Text>
      {selector}
    </Space>
  );
}

const Selector: Item = {
  type: 'layout',
  layout: 'selector',
  renderer: Renderer,
};

export default Selector;
