import { Stream } from "../Builder/array";
import type { ResultCRUD, Query as ApiQuery } from "./api.interface";
import type { Api, Listener } from "./api";
import type { AsObj, Query } from "../Builder/types";
import type { Entity } from "../utils/types";

class ApiStream<T extends Entity> extends Stream<T> {
  holdedListener: Listener;

  constructor(
    protected readonly api: Api,
    private readonly model: string
  ) {
    super('');
    this.holdedListener = this.api.addListener(payload => {
      if (payload.action === 'create' || payload.action === 'update' || payload.action === 'delete') {
        this.rerender(payload.action);
      }
      return false;
    }, this.model);
  }

  async create(element?: T) {
    if (!element) {
      return null;
    }
    this.api.action({
      model: this.model,
      action: 'create',
      data: element,
    });
    // Апдейт без подгрузки новых данных
    return null;
  }

  private async loadStream(query: ApiQuery) {
    // TODO: использовать key/frame
    this.api.action({
      model: this.model,
      action: 'readMany',
      data: query,
    });
    const payload = await new Promise<ResultCRUD>(
      cb => this.api.addListener(payload => {
        if (payload.action !== 'readMany') {
          return false;
        }
        cb(payload);
        return true;
      }, this.model)
    );
    const streamData = payload.data;
    if (typeof streamData === 'object' && streamData !== null) {
      // NOTE: конкретизируем тип
      return streamData.data as T[];
    }
    return null;
  }

  async getData(key: string, frame: number, query: Query<AsObj<T>>) {
    return await this.loadStream(query as any);
  }

  async find(query: Query<AsObj<T>>) {
    // TODO: адаптировать тип в gateway
    return await this.loadStream(query as any);
  }

  async update(id: number, element: T) {
    if (!element) {
      return null;
    }
    this.api.action({
      model: this.model,
      action: 'update',
      data: element,
    });
    return null;
  }

  async delete(id: number, element?: T) {
    if (!element) {
      return null;
    }
    this.api.action({
      model: this.model,
      action: 'delete',
      data: element.id,
    });
    return null;
  }
}


export default ApiStream;
