import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Spin } from 'antd';
import type { ThemeConfig } from 'antd/es/config-provider/context';
import { useParams, useSearchParams } from 'react-router-dom';

import { useStream, useUpdateEvent } from '../../../Builder/array';
import { YamlTemplate } from '../../../utils/yaml';
import type { ComponentBase } from '../../Base/types';
import type { PageValue, WebFormEntity } from '../types';

type WebFormPage = ComponentBase<'WebFormPage', PageValue<any, any>, {}>;

// Шаблон дочернего представления
const template = YamlTemplate.toTemplate(`
type: ref  # Стили ант-дизайна
ref: StyleConfig
layout:
  type: native
  content:
    type: layout  # Далее берем шаблон из значения
    layout:
      type: preview
`);

type ChildValue = {
  // Стили ант-дизайна
  config?: ThemeConfig,
  isDark: boolean,
  content: {
    // Далее берем шаблон из значения
    template: unknown,
    value: unknown,
  },
};

const Renderer: WebFormPage['renderer'] = ({value: initialValue, onChange: onParentChange, Fabric}) => {
  // Используем CRUD веб-форм
  const webFormStream = useStream(initialValue.webForms);
  // Для асинхронной подгрузки
  const [webForm, setWebForm] = useState<WebFormEntity | null>(null);
  // Какая именно веб-форма
  const {webFormRoute} = useParams();

  // Переход после заполнения
  const [params] = useSearchParams();
  const nextUrl = params.get('next');

  // Меняется, когда меняются настройки веб-формы
  const mr = useUpdateEvent(webFormStream, ['update'], 'WebFormPage');

  useEffect(() => {
    // Загружаем сущность веб-формы, когда нужно
    (async () => {
      if (!webFormRoute) {
        return;
      }

      const result = await webFormStream.find({filters: {route: {eq: webFormRoute}}});

      if (!result || result.length < 1) {
        return;
      }

      setWebForm(result[0]);
    })();
  }, [mr, webFormStream, webFormRoute]);

  const value: ChildValue = useMemo(() => ({
    // Стили ант-дизайна
    config: undefined,
    isDark: true,
    content: {
      // Далее берем шаблон из значения
      template: {  // Описываем шаблон
        // Подгрузка CRUD выбранной в настройках веб-формы таблицы
        type: 'ref',
        ref: 'Table',
        layout: {
          type: 'native',
          table: webForm?.table, // Из настроек
          template: {
            type: 'ref', // Используем еще один компонент для перехвата CRUD ...
            ref: 'WebFormPage.Content',
            layout: {
              type: 'native',
              // ... и передаем в него шаблон
              template: {
                // Лэйаут с кнопками использует свое значение и для кнопок, и для тела (body)
                type: 'layout', // Лэйаут с кнопками (здесь используется одна)
                layout: {
                  type: 'confirmBlock',
                  buttonOk: YamlTemplate.toTemplate(`
                    type: layout  # Одна кнопка -- Сохранить
                    layout:
                      type: button
                      buttonType: primary
                      label: Сохранить
                  `),
                  // Шаблон из настроек веб-формы
                  body: YamlTemplate.toTemplate(webForm?.template ?? ''),
                },
              },
            },
          }
        },
      },
      // Значение этого всего не используется
      value: null,
    },
  }), [webForm?.template, webForm?.table]);

  const onChange = useCallback(async (newValue: ChildValue) => {
    // const newFormValue = newValue.content.value;
    // TODO: добавить редирект
    console.log(nextUrl);
  }, [nextUrl]);

  if (!webForm) {
    return <Spin />;
  }

  return (
    <Fabric
      value={value}
      onChange={onChange}
      Fabric={Fabric}
      template={template}
    />
  );
}

const WebFormPageComponent: WebFormPage = {
  name: 'WebFormPage',
  renderer: Renderer,
};

export default WebFormPageComponent;
